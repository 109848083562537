import {createStyles, FormControlLabel, InputAdornment, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, MouseEvent, ReactNode} from 'react';
import {AssignableRole, Board, BoardMember} from '../../redux/boards/types';
import {addBoardMember, removeBoardMember, updateBoardMember} from '../../redux/boards/actions';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../redux';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {sortBoardMembers} from '../../utils/board';
import UserAvatar from '../avatar/UserAvatar';
import Avatar from '@material-ui/core/Avatar';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import {Field, Form} from 'react-final-form';
import {FinalFormRadio, FinalFormTextField} from '../inputs/final-form-wrappers';
import {keepEmpty, trim} from '../../utils/form';
import Button from '@material-ui/core/Button';
import {ValidationErrors} from 'final-form';
import RequestArtConsultant from './RequestArtConsultant';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    membersWrap: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
    },
    members: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'flex-end',
    },
    popoverContent: {
        padding: theme.spacing(2),
    },
    shareTitle: {
        marginBottom: theme.spacing(2),
    },
    roleSelect: {
        marginTop: theme.spacing(2),
    },
    requestArtConsultantButton: {
        marginTop: theme.spacing(2),
    },
    updateButton: {
        marginTop: theme.spacing(2),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
    className? : string;
}

interface DispatchProps
{
    addBoardMember : (boardId : string, emailAddress : string, role : AssignableRole) => void;
    updateBoardMember : (boardId : string, userId : string, role : AssignableRole) => void;
    removeBoardMember : (boardId : string, userId : string) => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    anchorElement : HTMLElement | null;
    openMember : BoardMember | null;
    processing : boolean;
}

const validate = (values : any) => {
    const errors : ValidationErrors = {};

    if (!values.emailAddress || !values.emailAddress.trim()) {
        errors.name = 'Required';
    }

    return errors;
};

class EditableBoardMembers extends Component<Props, State>
{
    public readonly state : State = {
        anchorElement: null,
        openMember: null,
        processing: false,
    };

    public render() : ReactNode
    {
        const {classes, className, board} = this.props;
        const {anchorElement, openMember} = this.state;

        const canAdd = board._user.permissions.addMembers;
        const canUpdate = board._user.permissions.updateMembers;
        const canRemove = board._user.permissions.removeMembers;

        const phoneNumber = openMember && openMember.user.profile.phoneNumber
            ? parsePhoneNumberFromString(openMember.user.profile.phoneNumber)
            : undefined;

        return (
            <div className={classNames(classes.root, className)}>
                <div className={classes.membersWrap}>
                    <div className={classes.members}>
                        {sortBoardMembers(board._members).map(member => (
                            <IconButton
                                key={member.user.id}
                                edge="end"
                                onClick={this.createAvatarClickHandler(member)}
                            >
                                <UserAvatar user={member.user} size="medium"/>
                            </IconButton>
                        ))}
                    </div>

                    {canAdd && (
                        <IconButton edge="end" onClick={this.handleAddClick}>
                            <Avatar>
                                <AddIcon/>
                            </Avatar>
                        </IconButton>
                    )}

                    <Popover
                        anchorEl={anchorElement}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        open={Boolean(anchorElement)}
                        onClose={this.handleClose}
                    >
                        <div className={classes.popoverContent}>
                            {openMember ? (
                                <React.Fragment>
                                    <Typography variant="body1">
                                        {openMember.user.profile.firstName} {openMember.user.profile.lastName}
                                    </Typography>
                                    {openMember.user.profile.company && (
                                        <Typography variant="body1">
                                            {openMember.user.profile.company}
                                        </Typography>
                                    )}
                                    <Link href={`mailto:${openMember.user.emailAddress}`} underline="always">
                                        {openMember.user.emailAddress}
                                    </Link>
                                    {phoneNumber && (
                                        <Typography variant="body1">
                                            {phoneNumber.formatInternational()}
                                        </Typography>
                                    )}

                                    {openMember.role !== 'owner' && (
                                        <React.Fragment>
                                            {canUpdate && (
                                                <Button
                                                    variant="contained"
                                                    onClick={this.createUpdateHandler(openMember)}
                                                    className={classes.updateButton}
                                                    fullWidth
                                                >
                                                    {
                                                        openMember.role === 'collaborator'
                                                            ? 'Change to Viewer'
                                                            : 'Change to Collaborator'
                                                    }
                                                </Button>
                                            )}
                                            {canRemove && (
                                                <Button
                                                    variant="contained"
                                                    onClick={this.createRemoveHandler(openMember)}
                                                    className={classes.updateButton}
                                                    fullWidth
                                                >
                                                    Remove from Board
                                                </Button>
                                            )}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Typography variant="h3" className={classes.shareTitle}>Share Board</Typography>

                                    <Form
                                        onSubmit={this.handleShare}
                                        initialValues={{emailAddress: '', role: 'collaborator'}}
                                        validate={validate}
                                        render={({handleSubmit, submitting, pristine, invalid}) => {
                                            return (
                                                <form onSubmit={handleSubmit}>
                                                    <Field
                                                        name="emailAddress"
                                                        label="Email Address"
                                                        required
                                                        component={FinalFormTextField}
                                                        type="email"
                                                        format={trim}
                                                        formatOnBlur
                                                        parse={keepEmpty}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Button
                                                                        variant="contained"
                                                                        type="submit"
                                                                        disabled={pristine || invalid || submitting}
                                                                    >
                                                                        Share
                                                                    </Button>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                    <div className={classes.roleSelect}>
                                                        <FormControlLabel
                                                            label="Collaborator"
                                                            control={
                                                                <Field
                                                                    name="role"
                                                                    type="radio"
                                                                    value="collaborator"
                                                                    component={FinalFormRadio}
                                                                />
                                                            }
                                                        />
                                                        <FormControlLabel
                                                            label="Viewer"
                                                            control={
                                                                <Field
                                                                    name="role"
                                                                    type="radio"
                                                                    value="viewer"
                                                                    component={FinalFormRadio}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </form>
                                            );
                                        }}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    </Popover>
                </div>

                <RequestArtConsultant board={board} className={classes.requestArtConsultantButton}/>
            </div>
        );
    }

    private createAvatarClickHandler = (member : BoardMember) => (event : MouseEvent<HTMLElement>) : void => {
        this.setState({
            anchorElement: event.currentTarget,
            openMember: member,
        });
    };

    private handleAddClick = (event : MouseEvent<HTMLElement>) : void => {
        this.setState({
            anchorElement: event.currentTarget,
            openMember: null,
        });
    };

    private handleClose = () : void => {
        this.setState({anchorElement: null});
    };

    private handleShare = async (values : any) : Promise<void> => {
        this.setState({anchorElement: null});
        this.props.addBoardMember(this.props.board.id, values.emailAddress, values.role);
    };

    private createUpdateHandler = (member : BoardMember) => () : void => {
        this.setState({anchorElement: null});
        this.props.updateBoardMember(
            this.props.board.id,
            member.user.id,
            member.role === 'collaborator' ? 'viewer' : 'collaborator'
        );
    };

    private createRemoveHandler = (member : BoardMember) => () : void => {
        this.setState({anchorElement: null});
        this.props.removeBoardMember(this.props.board.id, member.user.id);
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    addBoardMember: (boardId : string, emailAddress : string, role : AssignableRole) => dispatch(
        addBoardMember(boardId, emailAddress, role)
    ),
    updateBoardMember: (boardId : string, userId : string, role : AssignableRole) => dispatch(
        updateBoardMember(boardId, userId, role)
    ),
    removeBoardMember: (boardId : string, userId : string) => dispatch(
        removeBoardMember(boardId, userId)
    ),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        EditableBoardMembers
    )
);
