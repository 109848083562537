import {Image} from '../../../redux/image-search/types';

export class Layout
{
    public readonly width : number;
    public readonly height : number;
    public readonly margin : number;
    public readonly layoutImages : LayoutImage[];
    private readonly offsetMap : Map<number, number>;

    constructor(
        width : number,
        height : number,
        margin : number,
        layoutImages : LayoutImage[],
        offsetMap : Map<number, number>
    )
    {
        this.width = width;
        this.height = height;
        this.margin = margin;
        this.layoutImages = layoutImages;
        this.offsetMap = offsetMap;
    }

    public getOffsetAfterImage(afterIndex : number) : number
    {
        return this.layoutImages[afterIndex].y + this.layoutImages[afterIndex].height + this.margin;
    }

    public withGap(offset : number, height : number) : Layout
    {
        if (offset >= this.height) {
            return new Layout(
                this.width,
                offset + height,
                this.margin,
                this.layoutImages,
                this.offsetMap
            );
        }

        const firstChangedIndex = this.offsetMap.get(offset);

        if (!firstChangedIndex) {
            throw new Error(`Invalid offset supplied: ${offset.toString()}`);
        }

        const newLayoutImages = this.layoutImages.slice(0, firstChangedIndex).concat(
            this.layoutImages.slice(firstChangedIndex).map(layoutImage =>
                ({...layoutImage, y: (layoutImage.y + height + this.margin)})
            )
        );

        const newOffsetMap = new Map();
        this.offsetMap.forEach((value, key) : void => {
            newOffsetMap.set(key, key < offset ? value : value + height + this.margin);
        });

        return new Layout(
            this.width,
            this.height + height + this.margin,
            this.margin,
            newLayoutImages,
            newOffsetMap
        );
    }
}

export interface LayoutImage
{
    x : number;
    y : number;
    width : number;
    height : number;
    image : Image;
}

export interface LayoutConfig
{
    containerWidth : number;
    maxRowHeight : number;
    margin : number;
    includeLastPartialRow : boolean;
    stopAfterFirstRow? : boolean;
}

const flushRow = (
    layoutImages : LayoutImage[],
    row : LayoutImage[],
    rowHeight : number,
    isPartialRow : boolean,
    config : LayoutConfig
) : number => {
    let x = 0;
    const lastLayoutImage = row[row.length - 1];

    for (const layoutImage of row) {
        const actualWidth = rowHeight * layoutImage.image.metadata.aspectRatio;

        layoutImage.x = Math.round(x);
        layoutImage.width = Math.round(actualWidth);
        layoutImage.height = rowHeight;
        layoutImages.push(layoutImage);

        if (!isPartialRow
            && layoutImage === lastLayoutImage
            && layoutImage.x + layoutImage.width !== config.containerWidth
        ) {
            layoutImage.width = config.containerWidth - layoutImage.x;
        }

        x += actualWidth + config.margin;
    }

    return rowHeight + config.margin;
};

export default (images : Image[], config : LayoutConfig) : Layout => {
    let row : LayoutImage[] = [];
    let rowAspectRatio = 0;
    let availableWidth = config.containerWidth;
    let y = 0;
    let height = 0;
    const layoutImages : LayoutImage[] = [];
    const offsetMap = new Map();

    images.some((image, index) => {
        if (row.length === 0) {
            offsetMap.set(y, index);
        }

        rowAspectRatio += image.metadata.aspectRatio;
        const layoutImage = {
            x: 0,
            y,
            width: 0,
            height: 0,
            image,
        };
        row.push(layoutImage);

        if (row.length > 1) {
            availableWidth -= config.margin;
        }

        const rowHeight = Math.round(availableWidth / rowAspectRatio);

        if (rowHeight < config.maxRowHeight) {
            height += flushRow(layoutImages, row, rowHeight, false, config);
            row = [];
            rowAspectRatio = 0;
            availableWidth = config.containerWidth;
            y += rowHeight + config.margin;

            if (config.stopAfterFirstRow) {
                return true;
            }
        }

        return false;
    });

    if (config.includeLastPartialRow && row.length > 0 && !(config.stopAfterFirstRow && layoutImages.length > 0)) {
        height += flushRow(layoutImages, row, config.maxRowHeight, true, config);
    }

    height = Math.max(0, height - config.margin);

    return new Layout(
        config.containerWidth,
        height,
        config.margin,
        layoutImages,
        offsetMap
    );
};
