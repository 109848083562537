import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import kLogo from '../assets/k-logo.png';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'inline-block',
        position: 'relative',
        width: 64,
        height: 64,
        '& div': {
            boxSizing: 'border-box',
            display: 'block',
            position: 'absolute',
            width: 51,
            height: 51,
            margin: 6,
            border: '6px solid #000',
            borderRadius: '50%',
            animation: '$circular-loading-indicator 1.2s cubic-bezier(.5, 0, .5, 1) infinite',
            borderColor: '#000 transparent transparent transparent',
        },
        '& div:nth-child(1)': {
            animationDelay: '-0.45s',
        },
        '& div:nth-child(2)': {
            animationDelay: '-0.3s',
        },
        '& div:nth-child(3)': {
            animationDelay: '-0.15s',
        },
        '& img': {
            display: 'block',
            marginLeft: 22,
            marginTop: 21,
            width: 20,
        },
    },
    '@keyframes circular-loading-indicator': {
        from: {
            transform: 'rotate(0deg)',
        },
        to: {
            transform: 'rotate(360deg)',
        },
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

type Props = OwnProps;

class CircularLoadingIndicator extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <img src={kLogo} alt="K"/>
            </div>
        );
    }
}

export default withStyles(styles)(
    CircularLoadingIndicator
);
