import {push} from 'connected-react-router';
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {apiEndpoint, apiFetch} from '../../utils/api';
import {AppState} from '../index';
import {enqueueSnackbar} from '../notifier/actions';
import {SET_USER, UNSET_USER, User, UserActionTypes, UserProfile} from './types';

const setUser = (user : User) : UserActionTypes => {
    return {
        type: SET_USER,
        payload: {user},
    };
};

export const unsetUser = () : UserActionTypes => {
    return {
        type: UNSET_USER,
    };
};

export const loadUser = () : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const userResult = await apiFetch(new URL(`${apiEndpoint}/user`).href);

    if (!userResult.ok) {
        dispatch(push('/complete-registration'));
        return;
    }

    const user = await userResult.json();
    dispatch(setUser(user));
};

export const updateProfile = (
    profile : UserProfile
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const result = await apiFetch(new URL(`${apiEndpoint}/user`).href, {
        method: 'PUT',
        body: JSON.stringify(profile),
    });

    if (!result.ok) {
        dispatch(enqueueSnackbar('An error occurred while updating your profile.', {variant: 'error'}));
        return;
    }

    const user = await result.json();
    dispatch(setUser(user));
    dispatch(enqueueSnackbar('Your profile has been updated.', {variant: 'success'}));
};
