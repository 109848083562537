import {Checkbox as MuiCheckbox} from '@material-ui/core';
import {CheckboxProps} from '@material-ui/core/Checkbox';
import {createStyles, StyleRules, Theme, withStyles} from '@material-ui/core/styles';
import {CheckBoxOutlineBlankSharp, CheckBoxOutlined, IndeterminateCheckBoxSharp} from '@material-ui/icons';
import React, {Component, ReactNode} from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        color: 'black',
    },
});

type Props = CheckboxProps;

class Checkbox extends Component<Props>
{
    public render() : ReactNode
    {
        return (
            <MuiCheckbox
                checkedIcon={<CheckBoxOutlined/>}
                icon={<CheckBoxOutlineBlankSharp/>}
                indeterminateIcon={<IndeterminateCheckBoxSharp/>}
                {...this.props}
            />
        );
    }
}

export default withStyles(styles)(
    Checkbox
);
