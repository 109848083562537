import {Typography} from '@material-ui/core';
import React, {Component, ReactNode} from 'react';

export default class extends Component
{
    public render() : ReactNode
    {
        return (
            <React.Fragment>
                <Typography variant="h1">File not found</Typography>
                <Typography variant="body1">
                    The requested file could not be found.
                </Typography>
            </React.Fragment>
        );
    }
}
