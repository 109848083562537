import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import {hasConsultant} from '../../utils/board';
import Button from '@material-ui/core/Button';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        whiteSpace: 'nowrap',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
    className? : string;
}

type Props = OwnProps;

class RequestArtConsultant extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, className, board} = this.props;

        const canRequestConsultant = board._user.permissions.requestConsultant
            && !board._user.archived
            && !board.consultantRequested
            && !hasConsultant(board);

        return (
            <React.Fragment>
                {canRequestConsultant && (
                    <Button
                        variant="contained"
                        onClick={this.handleRequestClick}
                        className={classNames(classes.root, className)}
                    >
                        Request Art Consultant
                    </Button>
                )}
            </React.Fragment>
        );
    }

    private handleRequestClick = async () : Promise<void> => {
        window.location.href = 'mailto:helpdirectme@kalisher.com?subject=New Project Request Art Lab';
    };
}

export default withStyles(styles)(
    RequestArtConsultant
);
