import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import CircularLoadingIndicator from './CircularLoadingIndicator';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

type Props = OwnProps;

class FullPageLoadingIndicator extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes} = this.props;

        return (
            <div className={classes.root}><CircularLoadingIndicator/></div>
        );
    }
}

export default withStyles(styles)(
    FullPageLoadingIndicator
);
