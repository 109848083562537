import {HIDE_MODAL, SHOW_MODAL, ShowActionTypes} from './types';

export const showNewBoardModal = () : ShowActionTypes => {
    return {
        type: SHOW_MODAL,
    };
};

export const hideNewBoardModal = () : ShowActionTypes => {
    return {
        type: HIDE_MODAL,
    };
};
