import React, {Component, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import BoardTile from './BoardTile';
import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ReactResizeDetector from 'react-resize-detector';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        position: 'relative',
        width: '100%',
        overflow: 'hidden',
    },
    slider: {
        position: 'relative',
        display: 'flex',
        flexWrap: 'nowrap',
        marginLeft: -30,
        marginTop: -30,
        transition: 'left 0.5s',
    },
    slideLeft: {
        zIndex: 4,
        position: 'absolute',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingRight: 20,
        backgroundImage: 'linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #fff 35%, #fff 100%)',
    },
    slideRight: {
        zIndex: 4,
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        paddingLeft: 20,
        backgroundImage: 'linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 35%, #fff 100%)',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    className? : string;
    boards : Board[];
    renderAdditionalInfo? : (board : Board) => void;
}

type Props = OwnProps;

interface State
{
    width : number;
    offset : number;
}

class BoardListSlider extends Component<Props, State>
{
    public readonly state : State = {
        width: 0,
        offset: 0,
    };

    constructor(props : Readonly<Props>)
    {
        super(props);
        this.handleResize = this.handleResize.bind(this);
    }

    public render() : ReactNode
    {
        const {classes, className, boards, renderAdditionalInfo} = this.props;
        const {offset} = this.state;

        return (
            <ReactResizeDetector
                handleWidth
                refreshMode="debounce"
                refreshRate={400}
                onResize={this.handleResize}
            >
                <div className={classNames(classes.root, className)}>
                    <div className={classes.slider} style={{
                        left: `${offset}px`,
                        width: `${this.getTotalWidth()}px`,
                    }}>
                        {boards.map(board => (
                            <BoardTile
                                key={board.id}
                                board={board}
                                renderAdditionalInfo={renderAdditionalInfo}
                            />
                        ))}
                    </div>

                    {this.canSlideLeft() && (
                        <div className={classes.slideLeft} onClick={() => this.handleSlideLeft()}>
                            <IconButton>
                                <ChevronLeftIcon/>
                            </IconButton>
                        </div>
                    )}

                    {this.canSlideRight() && (
                        <div className={classes.slideRight} onClick={() => this.handleSlideRight()}>
                            <IconButton>
                                <ChevronRightIcon/>
                            </IconButton>
                        </div>
                    )}
                </div>
            </ReactResizeDetector>
        );
    }

    private getTotalWidth() : number
    {
        return (this.props.boards.length * (250 + 30)) - 30;
    }

    private canSlideLeft() : boolean
    {
        return this.state.offset < 0;
    }

    private canSlideRight() : boolean
    {
        return this.state.width > 0 && (-this.state.offset + this.state.width) < this.getTotalWidth();
    }

    private handleResize(width : number) : void
    {
        this.setState({width});
    }

    private handleSlideLeft() : void
    {
        this.setState({offset: Math.min(0, this.state.offset + (280 * 2))});
    }

    private handleSlideRight() : void
    {
        this.setState({offset: Math.max(
            this.state.offset - (280 * 2),
            -(this.getTotalWidth() - this.state.width)
        )});
    }
}

export default withStyles(styles)(
    BoardListSlider
);
