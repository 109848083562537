import {createStyles, Theme, Typography, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    guide: {
        display: 'inline-block',
        marginRight: 20,
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
});

interface GuidesProps extends WithStyles<typeof styles>
{
    guides : string[];
    onGuideSelect : (guide : string) => void;
    className? : string;
}

export default withStyles(styles)(
    class extends Component<GuidesProps>
    {
        public render() : ReactNode
        {
            const {classes, className, guides} = this.props;

            return (
                <div className={className}>
                    {guides.map(guide => (
                        <Typography
                            key={guide}
                            variant="body1"
                            className={classes.guide}
                            onClick={this.createGuideSelectHandler(guide)}
                        >{guide}</Typography>
                    ))}
                </div>
            );
        }

        private createGuideSelectHandler = (guide : string) => () : void => {
            this.props.onGuideSelect(guide);
        };
    }
);
