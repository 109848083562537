import React, {Component, ReactNode} from 'react';
import MyWork from './Tabs/MyWork';
import FabContainer from '../../../components/FabContainer';

class CustomerDashboard extends Component
{
    public render() : ReactNode
    {
        return (
            <React.Fragment>
                <MyWork filter=""/>
                <FabContainer/>
            </React.Fragment>
        );
    }
}

export default CustomerDashboard;
