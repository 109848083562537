import {DESELECT_IMAGE, ImageSelectionActionTypes, QUICK_ADD_IMAGE, RESET_QUICK_ADD_IMAGE, SELECT_IMAGE} from './types';

export const selectImage = (imageId : string) : ImageSelectionActionTypes => {
    return {
        type: SELECT_IMAGE,
        payload: {imageId},
    };
};

export const deselectImage = (imageId : string) : ImageSelectionActionTypes => {
    return {
        type: DESELECT_IMAGE,
        payload: {imageId},
    };
};

export const quickAddImage = (imageId : string) : ImageSelectionActionTypes => {
    return {
        type: QUICK_ADD_IMAGE,
        payload: {imageId},
    };
};

export const resetQuickAddImage = () : ImageSelectionActionTypes => {
    return {
        type: RESET_QUICK_ADD_IMAGE,
    };
};
