import {TextField} from '@material-ui/core';
import React, {ChangeEvent, Component, FormEvent, ReactNode} from 'react';
import Dialog from './Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import {apiEndpoint} from '../utils/api';
import {OptionsObject} from 'notistack';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../redux';
import {AnyAction} from 'redux';
import {enqueueSnackbar, hideProcessingOverlay, showProcessingOverlay} from '../redux/notifier/actions';
import {connect} from 'react-redux';
import {validate as validateEmailAddress} from 'email-validator';

interface OwnProps
{
    open : boolean;
    onClose : () => void;
}

interface DispatchProps
{
    enqueueSnackbar : (message : string | React.ReactNode, options? : OptionsObject) => void;
    showProcessingOverlay : () => void;
    hideProcessingOverlay : () => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    emailAddress : string;
    error : string | null,
}

class ResendVerificationDialog extends Component<Props, State>
{
    public readonly state : State = {
        emailAddress: '',
        error: null,
    };

    public render() : ReactNode
    {
        const {open, onClose} = this.props;
        const {emailAddress, error} = this.state;

        return (
            <Dialog open={open} onClose={onClose} title="Resend verification email" maxWidth="sm" fullWidth={true}>
                <form onSubmit={this.handleSubmit}>
                    <DialogContent>
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="emailAddress"
                            label="Email Address"
                            value={emailAddress}
                            onChange={this.handleEmailAddressChange}
                            error={Boolean(error)}
                            helperText={error}
                            autoFocus
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={onClose} color="primary">
                            Cancel
                        </Button>
                        <Button type="submit" color="primary">
                            Confirm
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        );
    }

    private handleEmailAddressChange = (event : ChangeEvent<HTMLInputElement>) : void => {
        this.setState({emailAddress: event.target.value});
    };

    private handleSubmit = async (event : FormEvent) : Promise<void> => {
        event.preventDefault();

        const {onClose, enqueueSnackbar, showProcessingOverlay, hideProcessingOverlay} = this.props;
        const {emailAddress} = this.state;

        if (!validateEmailAddress(emailAddress)) {
            this.setState({error: 'Valid email address required'});
            return;
        }

        showProcessingOverlay();

        const response = await fetch(`${apiEndpoint}/user/resend-verification-email`, {
            method: 'POST',
            body: JSON.stringify({emailAddress: emailAddress}),
        });

        hideProcessingOverlay();

        if (!response.ok) {
            this.setState({error: 'Email address not found'});
            return;
        }

        enqueueSnackbar('A verification email has been resend to you.', {variant: 'success'});
        onClose();
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    enqueueSnackbar: (message : string | React.ReactNode, options? : OptionsObject) => dispatch(
        enqueueSnackbar(message, options)
    ),
    showProcessingOverlay: () => dispatch(showProcessingOverlay()),
    hideProcessingOverlay: () => dispatch(hideProcessingOverlay()),
});

export default connect<null, DispatchProps, OwnProps, AppState>(
    null,
    mapDispatchToProps
)(
    ResendVerificationDialog
);
