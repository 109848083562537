import {Auth0ActionTypes, Auth0State, SET_USER, UNSET_USER} from './types';

const initialState : Auth0State = {
    authenticated: false,
    user: null,
};

export const auth0Reducer = (
    state = initialState,
    action : Auth0ActionTypes
) : Auth0State => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                authenticated: true,
                user: action.payload.user,
            };

        case UNSET_USER:
            return initialState;

        default:
            return state;
    }
};
