import {connectRouter, routerMiddleware} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {auth0Reducer} from './auth0/reducers';
import {boardsReducer} from './boards/reducers';
import {imageSearchReducer} from './image-search/reducers';
import {notifierReducer} from './notifier/reducers';
import {userReducer} from './user/reducers';
import {imageSelectionReducer} from './image-selection/reducers';
import {showBoardReducer} from './show-new-board/reducers';

export const history = createBrowserHistory();

const rootReducer = combineReducers({
    imageSearch: imageSearchReducer,
    imageSelection: imageSelectionReducer,
    boards: boardsReducer,
    user: userReducer,
    notifier: notifierReducer,
    auth0: auth0Reducer,
    showBoard: showBoardReducer,
    router: connectRouter(history),
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
    rootReducer,
    compose(
        applyMiddleware(
            routerMiddleware(history),
            thunk
        )
    )
);
