import {
    CLEAR_QUERY,
    FETCH_BEGIN,
    FETCH_END,
    ImageSearchActionTypes,
    ImageSearchMode,
    ImageSearchState,
    RESET_RESULTS,
    UPDATE_ENTERED_QUERY,
    UPDATE_GUIDES,
    UPDATE_PROCESSED_ORDER,
    UPDATE_PROCESSED_QUERY,
    UPDATE_PROCESSED_TYPE,
    UPDATE_SELECTED_ORDER,
    UPDATE_SELECTED_TYPE,
} from './types';

const initialState : ImageSearchState = {
    images: {
        [ImageSearchMode.Primary]: [],
        [ImageSearchMode.Secondary]: [],
        [ImageSearchMode.Tertiary]: [],
    },
    mode: ImageSearchMode.Primary,
    guides: [],
    enteredQuery: '',
    processedQuery: '',
    selectedType: 'all',
    processedType: 'all',
    selectedOrder: 'newest-first',
    processedOrder: 'newest-first',
    loading: false,
    nextResultsUrl: null,
};

export const imageSearchReducer = (
    state = initialState,
    action : ImageSearchActionTypes
) : ImageSearchState => {
    switch (action.type) {
        case RESET_RESULTS:
            return {
                ...state,
                mode: ImageSearchMode.Primary,
                images: initialState.images,
                nextResultsUrl: null,
            };

        case FETCH_BEGIN:
            return {
                ...state,
                loading: true,
            };

        case FETCH_END:
            return {
                ...state,
                loading: false,
                images: {
                    ...state.images,
                    [state.mode]: state.images[state.mode].concat(action.payload.images),
                },
                nextResultsUrl: action.payload.nextResultsUrl,
                mode: action.payload.newMode,
            };

        case CLEAR_QUERY:
            return {
                ...state,
                enteredQuery: '',
            };

        case UPDATE_ENTERED_QUERY:
            return {
                ...state,
                enteredQuery: action.payload.query,
            };

        case UPDATE_PROCESSED_QUERY:
            return {
                ...state,
                enteredQuery: action.payload.query,
                processedQuery: action.payload.query,
            };

        case UPDATE_SELECTED_TYPE:
            return {
                ...state,
                selectedType: action.payload.type,
            };

        case UPDATE_PROCESSED_TYPE:
            return {
                ...state,
                selectedType: action.payload.type,
                processedType: action.payload.type,
            };

        case UPDATE_SELECTED_ORDER:
            return {
                ...state,
                selectedOrder: action.payload.order,
            };

        case UPDATE_PROCESSED_ORDER:
            return {
                ...state,
                selectedOrder: action.payload.order,
                processedOrder: action.payload.order,
            };

        case UPDATE_GUIDES:
            return {
                ...state,
                guides: action.payload.guides,
            };

        default:
            return state;
    }
};
