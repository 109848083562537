import {createStyles, Theme, Typography, WithStyles, withStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps} from 'react-router';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import AddToBoard from '../../components/AddToBoard';
import ImageGrid from '../../components/imageGrid/ImageGrid';
import {AppState} from '../../redux';
import {changeImagePosition, loadCurrentBoard, loadSpecOptions} from '../../redux/boards/actions';
import {Board, BoardImage, ChangePosition, SpecOptions} from '../../redux/boards/types';
import EditableBoardName from '../../components/board/EditableBoardName';
import EditableBoardDescription from '../../components/board/EditableBoardDescription';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import {hideProcessingOverlay, showProcessingOverlay} from '../../redux/notifier/actions';
import EditableBoardMembers from '../../components/board/EditableBoardMembers';
import ArchiveBoard from '../../components/board/ArchiveBoard';
import {DropPosition} from '../../components/imageGrid/ImageGridLayout';
import {LayoutImage} from '../../components/imageGrid/utils/computeLayout';
import UnderlinedButton from '../../components/UnderlinedButton';
import SwitchBoardType from '../../components/board/SwitchBoardType';
import {Auth0User} from '../../redux/auth0/types';
import FabContainer from '../../components/FabContainer';
import dateFormat from 'dateformat';

const BoardPdfRenderer = React.lazy(() => import('../../components/pdf/BoardPdfRenderer'));

const styles = (theme : Theme) : StyleRules => createStyles({
    headline: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        alignItems: 'flex-start',
    },
    spacer: {
        flexGrow: 1,
    },
    description: {
        marginBottom: theme.spacing(2),
    },
    specControls: {
        marginBottom: theme.spacing(2),
    },
    boardMembers: {
        marginLeft: theme.spacing(2),
    },
});

interface MatchParams
{
    boardId : string;
}

interface OwnProps extends WithStyles<typeof styles>, RouteComponentProps<MatchParams>
{
}

interface StateProps
{
    board : Board | null;
    boardImages : BoardImage[];
    loading : boolean;
    auth0User : Auth0User;
    specOptions : SpecOptions | null;
}

interface DispatchProps
{
    loadCurrentBoard : (boardId : string) => void;
    showProcessingOverlay : () => void;
    hideProcessingOverlay : () => void;
    changeImagePosition : (
        boardId : string,
        imageId : string,
        targetId : string,
        position : ChangePosition
    ) => void;
    loadSpecOptions : () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State
{
    showListViewTooltip : boolean;
    downloadBoardPdf : boolean;
    downloadBoardImages : boolean;
}

class BoardOverview extends Component<Props, State>
{
    public readonly state : State = {
        showListViewTooltip: false,
        downloadBoardPdf: false,
        downloadBoardImages: false,
    };

    public render() : ReactNode
    {
        const {classes, board, boardImages, loading} = this.props;
        const {downloadBoardPdf} = this.state;

        if (board === null) {
            return <InlineLoadingIndicator/>;
        }

        const images = boardImages.map(boardImage => boardImage.image);
        let content;

        if (loading) {
            content = <InlineLoadingIndicator/>;
        } else if (boardImages.length === 0) {
            content = <Typography variant="body1">The board contains no images.</Typography>;
        } else {
            content = (
                <ImageGrid
                    images={images}
                    includeLastPartialRow={true}
                    onImageDrop={board._user.permissions.resortImages ? this.handleDrop : undefined}
                    allowSelection={true}
                    allowDetails={true}
                />
            );
        }

        return (
            <React.Fragment>
                <div className={classes.headline}>
                    <EditableBoardName board={board}/>
                    <div><ArchiveBoard board={board}/></div>
                    <div><SwitchBoardType board={board}/></div>

                    {board.type === 'regular' && (
                        <React.Fragment>
                            <div>
                                <UnderlinedButton onClick={this.handleBoardPdfStart}>Download PDF</UnderlinedButton>
                            </div>
                            <div>
                                <UnderlinedButton onClick={this.handleBoardImagesDownload}>
                                    Download Images
                                </UnderlinedButton>
                            </div>
                            <div className={classes.spacer}/>
                            <EditableBoardMembers board={board} className={classes.boardMembers}/>
                        </React.Fragment>
                    )}
                </div>

                <EditableBoardDescription board={board} className={classes.description}/>

                {content}

                <FabContainer>
                    <AddToBoard currentBoard={board}/>
                </FabContainer>

                {downloadBoardPdf && (
                    <React.Suspense fallback={<div/>}>
                        <BoardPdfRenderer board={board} images={images} onFinish={this.handleBoardPdfEnd}/>
                    </React.Suspense>
                )}

            </React.Fragment>
        );
    }

    public componentDidUpdate(prevProps : Readonly<Props>) : void
    {
        if (prevProps.match.params.boardId !== this.props.match.params.boardId) {
            this.props.loadCurrentBoard(
                this.props.match.params.boardId
            );
        }
    }

    public componentDidMount() : void
    {
        if (!this.props.specOptions) {
            this.props.loadSpecOptions();
        }

        this.props.loadCurrentBoard(
            this.props.match.params.boardId
        );
    }

    private handleDrop = (dropPosition : DropPosition, layoutImage : LayoutImage) : void => {
        if (!this.props.board) {
            return;
        }

        this.props.changeImagePosition(
            this.props.board.id,
            layoutImage.image.id,
            dropPosition.layoutImage.image.id,
            dropPosition.position
        );
    };

    private handleBoardPdfStart = () : void => {
        this.setState({downloadBoardPdf: true});
        this.props.showProcessingOverlay();
    };

    private handleBoardPdfEnd = () : void => {
        this.setState({downloadBoardPdf: false});
        this.props.hideProcessingOverlay();
    };

    private handleBoardImagesDownload = async () : Promise<void> => {
        const {board, boardImages} = this.props;

        if (!board) {
            return;
        }

        this.setState({downloadBoardImages: true});
        this.props.showProcessingOverlay();

        const {default: JSZip} = await import('jszip');
        const {saveAs} = await import('file-saver');
        const zip = new JSZip();

        for (const boardImage of boardImages) {
            const imageResponse = await fetch(boardImage.image.imageUri);

            if (!imageResponse.ok) {
                continue;
            }

            zip.file(`${boardImage.image.imageKey}.jpg`, await imageResponse.blob());
        }

        const content = await zip.generateAsync({type: 'blob', compression: 'STORE'});
        saveAs(content, `${board.name}-KAL-${dateFormat(new Date(), 'mm-dd-yy')}.zip`)

        this.props.hideProcessingOverlay();
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    board: state.boards.currentBoard.board,
    boardImages: state.boards.currentBoard.boardImages,
    loading: state.boards.currentBoard.loading,
    auth0User: state.auth0.user!,
    specOptions: state.boards.specOptions,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    loadCurrentBoard: (boardId : string) => dispatch(loadCurrentBoard(boardId)),
    showProcessingOverlay: () => dispatch(showProcessingOverlay()),
    hideProcessingOverlay: () => dispatch(hideProcessingOverlay()),
    changeImagePosition: (
        boardId : string,
        imageId : string,
        targetId : string,
        position : ChangePosition
    ) => dispatch(changeImagePosition(boardId, imageId, targetId, position)),
    loadSpecOptions: () => dispatch(
        loadSpecOptions()
    ),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        BoardOverview
    )
);
