import {
    CLOSE_SNACKBAR,
    ENQUEUE_SNACKBAR,
    NotifierActionTypes,
    NotifierState,
    OVERLAY_PROCESSING,
    REMOVE_SNACKBAR,
} from './types';

const initialState : NotifierState = {
    notifications: [],
    processing: false,
};

export const notifierReducer = (
    state = initialState,
    action : NotifierActionTypes
) : NotifierState => {
    switch (action.type) {
        case ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.payload.notification.key,
                        ...action.payload.notification,
                    },
                ],
            };

        case CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map(notification => (
                    (action.payload.dismissAll || notification.key === action.payload.key)
                        ? {...notification, dismissed: true}
                        : {...notification}
                )),
            };

        case REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.key !== action.payload.key),
            };

        case OVERLAY_PROCESSING:
            return {
                ...state,
                processing: action.payload.show,
            };

        default:
            return state;
    }
};
