import {push} from 'connected-react-router';
import {Action, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {apiEndpoint, apiFetch} from '../../utils/api';
import {AppState} from '../index';
import {
    CLEAR_QUERY,
    FETCH_BEGIN,
    FETCH_END,
    Image,
    ImageSearchActionTypes,
    ImageSearchMode,
    ImageSearchOrder,
    ImageSearchType,
    RESET_RESULTS,
    UPDATE_ENTERED_QUERY,
    UPDATE_GUIDES,
    UPDATE_PROCESSED_ORDER,
    UPDATE_PROCESSED_QUERY,
    UPDATE_PROCESSED_TYPE,
    UPDATE_SELECTED_ORDER,
    UPDATE_SELECTED_TYPE,
} from './types';

export const updateEnteredQuery = (query : string) : ImageSearchActionTypes => {
    return {
        type: UPDATE_ENTERED_QUERY,
        payload: {query},
    };
};

export const updateSelectedType = (type : ImageSearchType) : ImageSearchActionTypes => {
    return {
        type: UPDATE_SELECTED_TYPE,
        payload: {type},
    };
};

export const updateSelectedOrder = (order : ImageSearchOrder) : ImageSearchActionTypes => {
    return {
        type: UPDATE_SELECTED_ORDER,
        payload: {order},
    };
};

const updateProcessedQuery = (query : string) : ImageSearchActionTypes => {
    return {
        type: UPDATE_PROCESSED_QUERY,
        payload: {query},
    };
};

const updateProcessedType = (type : ImageSearchType) : ImageSearchActionTypes => {
    return {
        type: UPDATE_PROCESSED_TYPE,
        payload: {type},
    };
};

export const updateProcessedOrder = (order : ImageSearchOrder) : ImageSearchActionTypes => {
    return {
        type: UPDATE_PROCESSED_ORDER,
        payload: {order},
    };
};

const fetchBegin = () : ImageSearchActionTypes => {
    return {
        type: FETCH_BEGIN,
    };
};

const fetchEnd = (
    images : Image[],
    nextResultsUrl : URL | null,
    newMode : ImageSearchMode
) : ImageSearchActionTypes => {
    return {
        type: FETCH_END,
        payload: {
            images,
            nextResultsUrl,
            newMode,
        },
    };
};

type FetchRequest = {canceled : boolean};

const fetchQueue = new class {
    private requests : FetchRequest[] = [];

    public create() : FetchRequest
    {
        const request = {canceled: false};
        this.requests.push(request);
        return request;
    }

    public cancelAll() : void
    {
        for (const request of this.requests) {
            request.canceled = true;
        }
        this.requests = [];
    }

    public remove(request : FetchRequest) : void
    {
        const index = this.requests.indexOf(request);

        if (index > -1) {
            this.requests.splice(index, 1);
        }
    }
}();

export const performSearch = (
    query : string,
    type? : ImageSearchType,
    order? : ImageSearchOrder
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    if (typeof type === 'undefined') {
        type = getState().imageSearch.selectedType;
    }

    if (typeof order === 'undefined') {
        order = getState().imageSearch.selectedOrder;
    }

    const searchParams = new URLSearchParams({query, type, order});
    const url = new URL(`${apiEndpoint}/images`);
    url.searchParams.set('query', query);
    url.searchParams.set('type', type);
    url.searchParams.set('order', order);

    fetchQueue.cancelAll();
    dispatch(updateProcessedQuery(query));
    dispatch(updateProcessedType(type));
    dispatch(updateProcessedOrder(order));
    dispatch({type: RESET_RESULTS});

    const currentSearchParams = new URLSearchParams(getState().router.location.search);

    if (currentSearchParams.get('query') !== query
        || currentSearchParams.get('type') !== type
        || currentSearchParams.get('order') !== order
    ) {
        dispatch(push(`/search?${searchParams.toString()}`));
    }

    const guidesUrl = new URL(url.href);
    guidesUrl.pathname += '/guides';

    const [, guideResponse] = await Promise.all([
        loadResults(dispatch, getState, url),
        apiFetch(guidesUrl.href),
    ]);

    if (!guideResponse.ok) {
        return;
    }

    const data = await guideResponse.json();
    dispatch({type: UPDATE_GUIDES, payload: {guides: data.items}});
};

export const clearQuery = () : ThunkAction<void, AppState, null, Action<string>> => dispatch => {
    dispatch({type: CLEAR_QUERY});
};

export const addGuide = (
    guide : string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    await dispatch(performSearch(`${getState().imageSearch.enteredQuery} ${guide}`));
};

export const loadNextResults = (
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const {nextResultsUrl} = getState().imageSearch;

    if (nextResultsUrl === null) {
        return;
    }

    await loadResults(dispatch, getState, nextResultsUrl);
};

const loadResults = async (dispatch : Dispatch, getState : () => AppState, url : URL) => {
    dispatch(fetchBegin());

    const request = fetchQueue.create();
    const result = await apiFetch(url.href);

    if (request.canceled || !result.ok) {
        return;
    }

    fetchQueue.remove(request);
    const data = await result.json();

    const state = getState().imageSearch;
    let nextResultsUrl : URL | null = null;
    let mode = state.mode;
    let items : Image[] = data.items;

    switch (mode) {
        case ImageSearchMode.Tertiary:
            const secondaryIds = new Set(state.images[ImageSearchMode.Secondary].map(image => image.id));
            items = items.filter(item => !secondaryIds.has(item.id));
            // falls through

        case ImageSearchMode.Secondary:
            const primaryIds = new Set(state.images[ImageSearchMode.Primary].map(image => image.id));
            items = items.filter(item => !primaryIds.has(item.id));
            // falls through

        default:
    }

    if (data.links.next) {
        nextResultsUrl = new URL(data.links.next.href);
    } else if (mode === ImageSearchMode.Primary) {
        const randomTag = getRandomTag(
            state.processedQuery,
            state.images[ImageSearchMode.Primary].concat(data.items)
        );

        nextResultsUrl = new URL(`${apiEndpoint}/images`);

        if (randomTag !== null) {
            nextResultsUrl.searchParams.set('query', randomTag);
            mode = ImageSearchMode.Secondary;
        } else {
            mode = ImageSearchMode.Tertiary;
        }
    } else if (mode === ImageSearchMode.Secondary) {
        nextResultsUrl = new URL(`${apiEndpoint}/images`);
        mode = ImageSearchMode.Tertiary;
    }

    dispatch(fetchEnd(items, nextResultsUrl, mode));
};

const getRandomTag = (currentQuery : string, images : Image[]) : string | null => {
    if (images.length === 0) {
        return null;
    }

    const tags = images
        .slice(0, 100)
        .reduce<string[]>((tags, image) => tags.concat(image.tags), [])
        .filter(tag => currentQuery.indexOf(tag) === -1);

    if (tags.length === 0) {
        return null;
    }

    return tags[Math.floor(Math.random() * tags.length)];
};
