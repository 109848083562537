import {getAccessToken} from './auth0';

export const apiEndpoint = process.env.REACT_APP_API_ENDPOINT!;

export const apiFetch = async (url : string, init? : RequestInit) : Promise<Response> => {
    const accessToken = await getAccessToken();

    if (!init) {
        init = {};
    }

    init.headers = init.headers instanceof Headers ? init.headers : new Headers(init.headers);
    init.headers.append('Authorization', `Bearer ${accessToken}`);

    return fetch(url, init);
};
