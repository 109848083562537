import React, {Component, ReactNode} from 'react';
import {Image as BoardImage} from '../../redux/image-search/types';
import {thumbnailUri} from '../../utils/image';

interface OwnProps
{
    className? : string;
    images : BoardImage[];
    width : number;
    height : number;
    onClick? : () => void;
}

type Props = OwnProps;

interface ImageLoader
{
    loaded : boolean;
    element : HTMLImageElement;
}

export const maxBoardTileImages = 3;

class BoardTileThumbnail extends Component<Props>
{
    private imageLoaders : ImageLoader[] = [];
    private canvas = React.createRef<HTMLCanvasElement>();

    public render() : ReactNode
    {
        const {className, width, height, onClick} = this.props;

        return (
            <canvas
                className={className}
                ref={this.canvas}
                width={width}
                height={height}
                onClick={onClick}
            />
        );
    }

    public async componentDidMount() : Promise<void>
    {
        this.loadImages(this.props.images);
    }

    public componentDidUpdate(prevProps : Readonly<OwnProps>) : void
    {
        if (prevProps.images !== this.props.images) {
            this.loadImages(this.props.images);
        }
    }

    private loadImages(images : BoardImage[]) : void
    {
        if (images.length > maxBoardTileImages) {
            console.debug('More than three images were provided. Consider querying for less.');
        }

        const {width, height} = this.props;
        const context = this.canvas.current!.getContext('2d')!;
        context.fillStyle = '#ccc';
        context.fillRect(0, 0, width, height);

        for (const image of images.slice(0, 3)) {
            const loader = {
                loaded: false,
                element: new Image(),
            };
            loader.element.src = thumbnailUri(image.imageUri, 'h400');
            loader.element.addEventListener('load', () => {
                loader.loaded = true;

                if (this.imageLoaders.filter(loader => !loader.loaded).length === 0) {
                    this.buildThumbnail(context);
                }
            });
            this.imageLoaders.push(loader);
        }
    }

    private buildThumbnail(context : CanvasRenderingContext2D) : void
    {
        const {width, height} = this.props;
        const loaders = this.imageLoaders;

        if (loaders.length === 0) {
            return;
        }

        if (loaders.length === 1) {
            BoardTileThumbnail.placeImage(context, loaders[0].element, 0, 0, width, height);
            return;
        }

        BoardTileThumbnail.placeImage(context, loaders[0].element, 0, 0, width / 2, height);

        if (loaders.length === 2) {
            BoardTileThumbnail.placeImage(context, loaders[1].element, width / 2, 0, width / 2, height);
            return;
        }

        BoardTileThumbnail.placeImage(context, loaders[1].element, width / 2, 0, width / 2, height / 2);
        BoardTileThumbnail.placeImage(context, loaders[2].element, width / 2, height / 2, width / 2, height / 2);
    }

    private static placeImage(
        context : CanvasRenderingContext2D,
        image : HTMLImageElement,
        x : number,
        y : number,
        width : number,
        height : number
    ) : void
    {
        const imageRatio = image.naturalWidth / image.naturalHeight;
        const destinationRatio = width / height;

        const sourceCoordinates = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        };

        if (imageRatio > destinationRatio) {
            const ratio = image.naturalHeight / height;

            sourceCoordinates.height = image.naturalHeight;
            sourceCoordinates.x = (image.naturalWidth / 2) - ((width / 2) * ratio);
            sourceCoordinates.width = width * ratio;
        } else {
            const ratio = image.naturalWidth / width;

            sourceCoordinates.width = image.naturalWidth;
            sourceCoordinates.y = (image.naturalHeight / 2) - ((height / 2) * ratio);
            sourceCoordinates.height = height * ratio;
        }

        context.drawImage(
            image,
            // Source
            sourceCoordinates.x,
            sourceCoordinates.y,
            sourceCoordinates.width,
            sourceCoordinates.height,
            // Destination
            x,
            y,
            width,
            height
        );
    }
}

export default BoardTileThumbnail;
