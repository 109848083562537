import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';
import {push} from 'connected-react-router';
import {store} from '../redux';
import {absoluteUrl} from './uri';

let auth0Client : Auth0Client;
let runningAuth0Promise : Promise<Auth0Client> | null;

export const getAuth0Client = async () : Promise<Auth0Client> => {
    if (auth0Client) {
        return auth0Client;
    }

    if (runningAuth0Promise) {
        return runningAuth0Promise;
    }

    runningAuth0Promise = createAuth0Client({
        domain: process.env.REACT_APP_AUTH0_DOMAIN!,
        client_id: process.env.REACT_APP_AUTH0_CLIENT_ID!,
        audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
        redirect_uri: absoluteUrl('/sign-in/callback').href,
    });

    auth0Client = await runningAuth0Promise;
    runningAuth0Promise = null;
    return auth0Client;
};

let runningTokenPromise : Promise<string> | null;

export const getAccessToken = async () : Promise<string> => {
    const auth0Client = await getAuth0Client();

    try {
        if (runningTokenPromise) {
            return await runningTokenPromise;
        }

        runningTokenPromise = auth0Client.getTokenSilently();
        const token = await runningTokenPromise;
        runningTokenPromise = null;
        return token;
    } catch (e) {
        store.dispatch(push('/sign-in'));
        throw e;
    }
};
