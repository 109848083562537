import {Button, createStyles, TextField, Theme, WithStyles} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import withStyles, {StyleRules} from '@material-ui/core/styles/withStyles';
import React, {ChangeEvent, Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../redux';
import {createEmptyBoard} from '../redux/boards/actions';
import Dialog from './Dialog';
import ThreeDotLoadingIndicator from './ThreeDotLoadingIndicator';

const styles = (theme: Theme): StyleRules => createStyles({
    dialogContent: {
        minWidth: 400,
        paddingBottom: theme.spacing(2),
    },
    loading: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    nameStyle: {
    },
    descriptionStyle: {
        marginTop: '10px',
    },
    createButton: {
        marginTop: theme.spacing(2),
    },
});

interface OwnProps extends WithStyles<typeof styles> {
    dialogOpen: boolean;
    handleNewBoardDialogClose : () => void;
}

interface StateProps { }

interface DispatchProps
{
    createEmptyBoard : (name : string, description : string) => Promise<void>;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
    processing: boolean;
    nameError: boolean;
    name: string;
    description: string;
}

class NewBoard extends Component<Props, State>
{
    public readonly state = {
        processing: false,
        nameError: false,
        name: '',
        description: '',
    };

    public render(): ReactNode {
        const {dialogOpen, classes} = this.props;
        const {processing, name, nameError, description} = this.state;

        let dialogContent: ReactNode;

        if (processing) {
            dialogContent = (
                <div className={classes.loading}><ThreeDotLoadingIndicator /></div>
            );
        } else {
            dialogContent = (
                <React.Fragment>
                    <TextField
                        autoFocus
                        fullWidth
                        variant="outlined"
                        placeholder="Name *"
                        onChange={this.handleNameChange}
                        className={classes.nameStyle}
                        value={name}
                        error={nameError}
                    />
                    <TextField
                        placeholder="Description"
                        variant="outlined"
                        onChange={this.handleDescriptionChange}
                        fullWidth
                        multiline
                        value={description}
                        className={classes.descriptionStyle}
                    />
                    <Button
                        className={classes.createButton}
                        variant="contained"
                        onClick={this.createBoardClickHandler()}
                        disabled={name.length === 0}
                    >
                        Create
                    </Button>
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <Dialog onClose={this.closeDialog} open={dialogOpen} title="Create Board">
                    <DialogContent className={classes.dialogContent}>
                        {dialogContent}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    private createBoardClickHandler = () => async () : Promise<void> => {
        const {handleNewBoardDialogClose} = this.props;
        const {name, description} = this.state;

        if (name.length) {
            this.setState({processing: true});
            await this.props.createEmptyBoard(name, description);
            handleNewBoardDialogClose();
            this.setState({processing: false, name: '', description: '', nameError: false});
        } else {
            this.setState({nameError: true});
        }
    };

    private handleNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
        this.setState({name: event.target.value});
    };

    private handleDescriptionChange = (event: ChangeEvent<HTMLTextAreaElement>): void => {
        this.setState({description: event.target.value});
    };

    public componentDidUpdate(prevProps: Readonly<Props>): void { }

    private closeDialog = (): void => {
        const {handleNewBoardDialogClose} = this.props;
        handleNewBoardDialogClose();
        this.setState({processing: false, name: '', description: '', nameError: false});
    };
}

const mapStateToProps = (state: AppState): StateProps => ({
});

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, any, AnyAction>): DispatchProps => ({
    createEmptyBoard: (name : string, description : string) => dispatch(
        createEmptyBoard(name, description)
    ),
});

export default withStyles(styles)(connect<StateProps, DispatchProps, OwnProps, AppState>(mapStateToProps, mapDispatchToProps)(NewBoard));