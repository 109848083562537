import {createMuiTheme} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider} from '@material-ui/core/styles';
import {ConnectedRouter} from 'connected-react-router';
import {SnackbarProvider} from 'notistack';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import App from './App';
import './index.css';
import {history, store} from './redux';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#999',
        },
        secondary: {
            main: '#000',
        },
        background: {
            default: '#fff',
        },
    },
    typography: {
        fontFamily: 'AvenirNextLTW01-Regular, sans-serif',
        h1: {
            fontFamily: 'AvenirNextLTW01-BoldCn, sans-serif',
            textTransform: 'uppercase',
            fontSize: 53,
            marginBottom: 20,
        },
        h2: {
            fontFamily: 'AvenirNextLTW01-BoldCn, sans-serif',
            fontSize: 32,
            textTransform: 'uppercase',
        },
        h3: {
            fontFamily: 'AvenirNextLTW01-BoldCn, sans-serif',
            fontSize: 24,
            textTransform: 'uppercase',
        },
        h4: {
            fontFamily: 'AvenirNextLTW01-Thin, sans-serif',
            textTransform: 'uppercase',
            fontSize: 18,
        },
        subtitle1: {
            fontFamily: 'AvenirNextLTW01-Medium, sans-serif',
            fontSize: 18,
        },
    },
    overrides: {
        MuiButton: {
            contained: {
                backgroundColor: '#000',
                color: '#fff',
                borderRadius: 0,
                '&:hover': {
                    backgroundColor: '#555',
                },
            }
        },
        MuiFab: {
            root: {
                borderRadius: 0,
                backgroundColor: '#fff',
                border: '1px solid #000',
            },
        },
        MuiOutlinedInput: {
            root: {
                alignItems: 'stretch',
            },
            notchedOutline: {
                borderRadius: 0,
            },
            adornedEnd: {
                paddingRight: 0,
            },
        },
        MuiInputAdornment: {
            root: {
                maxHeight: 'none',
                height: 'auto',
                alignItems: 'stretch',
            },
        },
    },
});

ReactDOM.render(
    <MuiThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3}>
            <Provider store={store}>
                <ConnectedRouter history={history}>
                    <CssBaseline/>
                    <App/>
                </ConnectedRouter>
            </Provider>
        </SnackbarProvider>
    </MuiThemeProvider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
