import {createStyles, Theme, WithStyles, withStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, DragEvent, ReactNode} from 'react';
import SmoothScroll from 'smooth-scroll';
import {Image} from '../../redux/image-search/types';
import ImageDetail from './ImageDetail';
import ImageTile from './ImageTile';
import {Layout, LayoutImage} from './utils/computeLayout';
import throttle from 'lodash.throttle';
import {ChangePosition} from '../../redux/boards/types';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        position: 'relative',
        userSelect: 'none',
    },
    ghostLine: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: 1,
        overflow: 'hidden',
        backgroundColor: 'rgba(0, 0, 0, .5)',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    layout : Layout;
    onImageClick? : (layoutImage : LayoutImage) => void;
    onImageDrop? : (dropPosition : DropPosition, layoutImage : LayoutImage) => void;
    allowSelection : boolean;
    allowDetails : boolean;
}

type Props = OwnProps;

type GhostLinePosition = {
    x : number;
    y : number;
    height : number;
};

export type DropPosition = {
    layoutImage : LayoutImage;
    position : ChangePosition;
};

interface State
{
    openImageIndex : number | null;
    openImage : Image | null;
    detailHeight : number | null;
    ghostLine : GhostLinePosition | null;
}

class ImageGridLayout extends Component<Props, State>
{
    public readonly state : State = {
        openImageIndex: null,
        openImage: null,
        detailHeight: null,
        ghostLine: null,
    };
    private readonly container = React.createRef<HTMLDivElement>();

    public render() : ReactNode
    {
        const {classes, allowSelection, allowDetails, onImageClick, onImageDrop} = this.props;
        let {layout} = this.props;
        const {openImageIndex, detailHeight, ghostLine} = this.state;

        if (openImageIndex !== null && detailHeight !== null) {
            const detailOffset = layout.getOffsetAfterImage(openImageIndex);
            layout = layout.withGap(detailOffset, detailHeight);
        }

        let dragOverHandler : ((event : DragEvent<HTMLDivElement>) => void) | undefined;

        if (onImageDrop) {
            const throttledDragOverHandler = throttle(this.handleDragOver, 250);
            dragOverHandler = (event : DragEvent<HTMLDivElement>) : void => {
                event.preventDefault();
                throttledDragOverHandler(event.pageX, event.pageY);
            };
        }

        return (
            <div
                className={classes.root}
                style={{
                    height: `${layout.height}px`,
                    '--margin': `${layout.margin}px`,
                }}
                ref={this.container}
                onDragOver={dragOverHandler}
                onDragExit={this.handleDragExit}
                onDrop={this.handleDrop}
            >
                {layout.layoutImages.map((layoutImage, index) => (
                    <ImageTile
                        key={layoutImage.image.id}
                        layoutImage={layoutImage}
                        onImageClick={onImageClick}
                        onDetailClick={allowDetails ? this.createDetailsHandler(index) : undefined}
                        isActive={index === openImageIndex}
                        allowSelection={allowSelection}
                        allowDrag={Boolean(onImageDrop)}
                    />
                ))}

                {openImageIndex !== null && (
                    <ImageDetail
                        width={layout.width}
                        image={layout.layoutImages[openImageIndex].image}
                        visible={detailHeight !== null}
                        yOffset={
                            layout.layoutImages[openImageIndex].y +
                            layout.layoutImages[openImageIndex].height +
                            layout.margin
                        }
                        handleHeightChange={this.handleDetailHeightChange}
                        onClose={this.handleDetailClose}
                    />
                )}

                {ghostLine && (
                    <div className={classes.ghostLine} style={{
                        height: ghostLine.height,
                        transform: `translate3d(${ghostLine.x}px, ${ghostLine.y}px, 0)`,
                    }}/>
                )}
            </div>
        );
    }

    public componentWillReceiveProps(nextProps : Readonly<Props>, nextContext : any) : void
    {
        const {openImageIndex, openImage} = this.state;

        if (openImageIndex === null || openImage === null) {
            return;
        }

        const newLayoutImages = nextProps.layout.layoutImages;

        if (!newLayoutImages[openImageIndex] || newLayoutImages[openImageIndex].image !== openImage) {
            this.setState({
                openImageIndex: null,
                openImage: null,
            });
        }
    }

    private handleDetailHeightChange = (height : number) : void => {
        this.setState({
            detailHeight: height,
        });
    };

    private handleDragOver = (pageX : number, pageY : number) : void => {
        const dropPosition = this.determineDropPosition(pageX, pageY);

        if (!dropPosition) {
            this.setState({ghostLine: null});
            return;
        }

        if (dropPosition.position === 'before') {
            this.setState({
                ghostLine: {
                    x: dropPosition.layoutImage.x - this.props.layout.margin / 2,
                    y: dropPosition.layoutImage.y,
                    height: dropPosition.layoutImage.height,
                },
            });
        } else {
            this.setState({
                ghostLine: {
                    x: dropPosition.layoutImage.x + dropPosition.layoutImage.width + this.props.layout.margin / 2,
                    y: dropPosition.layoutImage.y,
                    height: dropPosition.layoutImage.height,
                },
            });
        }
    };

    private handleDrop = (event : DragEvent<HTMLDivElement>) : void => {
        event.preventDefault();

        const dropPosition = this.determineDropPosition(event.pageX, event.pageY);
        const imageId = event.dataTransfer.getData('imageId');
        const {onImageDrop} = this.props;
        this.setState({ghostLine: null});

        if (!imageId || !dropPosition || !onImageDrop) {
            return;
        }

        const layoutImage = this.props.layout.layoutImages.find(layoutImage => layoutImage.image.id === imageId);

        if (!layoutImage) {
            return;
        }

        onImageDrop(dropPosition, layoutImage);
    };

    private determineDropPosition = (pageX : number, pageY : number) : DropPosition | null => {
        const rect = this.container.current!.getBoundingClientRect();
        const left = pageX - (rect.left + window.scrollX);
        const top = pageY - (rect.top + window.scrollY);
        const halfMargin = this.props.layout.margin / 2;

        for (const layoutImage of this.props.layout.layoutImages) {
            if (layoutImage.y + layoutImage.height < top) {
                continue;
            }

            if (layoutImage.y > top) {
                break;
            }

            if (left >= layoutImage.x - halfMargin && left < layoutImage.x + layoutImage.width / 2) {
                return {layoutImage, position: 'before'};
            }

            if (left >= layoutImage.x + layoutImage.width / 2
                && left <= layoutImage.x + layoutImage.width + halfMargin
            ) {
                return {layoutImage, position: 'after'};
            }
        }

        return null;
    };

    private handleDragExit = () : void => {
        this.setState({ghostLine: null});
    };

    private createDetailsHandler = (index : number) => (layoutImage : LayoutImage) : void => {
        if (index === this.state.openImageIndex) {
            this.setState({
                openImageIndex: null,
                openImage: null,
            });
            return;
        }

        this.setState({
            openImageIndex: index,
            openImage: this.props.layout.layoutImages[index].image,
            detailHeight: null,
        });

        if (!this.container.current) {
            return;
        }

        const containerOffset = this.container.current.getBoundingClientRect().top + window.scrollY;
        const detailOffset = this.props.layout.getOffsetAfterImage(index);
        const scrollTo = containerOffset + detailOffset - 40 - (layoutImage.height / 2);

        if (scrollTo > window.scrollY - 20 && scrollTo < window.scrollY + 20) {
            return;
        }

        new SmoothScroll().animateScroll(scrollTo, null, {speed: 1000});
    };

    private handleDetailClose = () => {
        this.setState({
            openImageIndex: null,
            openImage: null,
        });
    };
}

export default withStyles(styles)(ImageGridLayout);
