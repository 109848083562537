export interface Image
{
    id : string;
    imageKey : string;
    imageUri : string;
    tags : string[];
    metadata : {
        checksum : string;
        aspectRatio : number;
        width : number;
        height : number;
    };
    internal : boolean;
}

export enum ImageSearchMode
{
    Primary,
    Secondary,
    Tertiary,
}

export interface ImageSearchImages
{
    [ImageSearchMode.Primary] : Image[];
    [ImageSearchMode.Secondary] : Image[];
    [ImageSearchMode.Tertiary] : Image[];
}

export type ImageSearchType = 'all' | 'accessible-boards';

export type ImageSearchOrder = 'random' | 'newest-first';

export interface ImageSearchState
{
    images : ImageSearchImages;
    mode : ImageSearchMode;
    guides : string[];
    enteredQuery : string;
    processedQuery : string;
    selectedType : ImageSearchType;
    processedType : ImageSearchType;
    selectedOrder : ImageSearchOrder;
    processedOrder : ImageSearchOrder;
    loading : boolean;
    nextResultsUrl : URL | null;
}

export const RESET_RESULTS = 'IMAGE_SEARCH_RESET_RESULTS';
export const FETCH_BEGIN = 'IMAGE_SEARCH_FETCH_BEGIN';
export const FETCH_END = 'IMAGE_SEARCH_FETCH_END';
export const CLEAR_QUERY = 'IMAGE_SEARCH_CLEAR_QUERY';
export const UPDATE_ENTERED_QUERY = 'IMAGE_SEARCH_UPDATE_ENTERED_QUERY';
export const UPDATE_PROCESSED_QUERY = 'IMAGE_SEARCH_UPDATE_PROCESSED_QUERY';
export const UPDATE_SELECTED_TYPE = 'IMAGE_SEARCH_UPDATE_SELECTED_TYPE';
export const UPDATE_PROCESSED_TYPE = 'IMAGE_SEARCH_UPDATE_PROCESSED_TYPE';
export const UPDATE_SELECTED_ORDER = 'IMAGE_SEARCH_UPDATE_SELECTED_ORDER';
export const UPDATE_PROCESSED_ORDER = 'IMAGE_SEARCH_UPDATE_PROCESSED_ORDER';
export const UPDATE_GUIDES = 'IMAGE_SEARCH_UPDATE_GUIDES';

interface ResetResultsAction
{
    type : typeof RESET_RESULTS;
}

interface FetchBeginAction
{
    type : typeof FETCH_BEGIN;
}

interface FetchEndAction
{
    type : typeof FETCH_END;
    payload : {
        nextResultsUrl : URL | null;
        images : Image[];
        newMode : ImageSearchMode;
    };
}

interface ClearQueryAction
{
    type : typeof CLEAR_QUERY;
}

interface UpdateEnteredQueryAction
{
    type : typeof UPDATE_ENTERED_QUERY;
    payload : { query : string };
}

interface UpdateProcessedQueryAction
{
    type : typeof UPDATE_PROCESSED_QUERY;
    payload : { query : string };
}

interface UpdateSelectedTypeAction
{
    type : typeof UPDATE_SELECTED_TYPE;
    payload : { type : ImageSearchType };
}

interface UpdateProcessedTypeAction
{
    type : typeof UPDATE_PROCESSED_TYPE;
    payload : { type : ImageSearchType };
}

interface UpdateSelectedOrderAction
{
    type : typeof UPDATE_SELECTED_ORDER;
    payload : { order : ImageSearchOrder };
}

interface UpdateProcessedOrderAction
{
    type : typeof UPDATE_PROCESSED_ORDER;
    payload : { order : ImageSearchOrder };
}

interface UpdateGuidesAction
{
    type : typeof UPDATE_GUIDES;
    payload : { guides : string[] };
}

export type ImageSearchActionTypes =
    ResetResultsAction
    | FetchBeginAction
    | FetchEndAction
    | ClearQueryAction
    | UpdateEnteredQueryAction
    | UpdateProcessedQueryAction
    | UpdateSelectedTypeAction
    | UpdateProcessedTypeAction
    | UpdateSelectedOrderAction
    | UpdateProcessedOrderAction
    | UpdateGuidesAction;
