import {createStyles, Theme, WithStyles} from '@material-ui/core';
import withStyles, {StyleRules} from '@material-ui/core/styles/withStyles';
import React, {Component, CSSProperties, ReactNode} from 'react';
import {Image} from '../../redux/image-search/types';
import classNames from 'classnames';
import {thumbnailUri} from '../../utils/image';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        transformOrigin: 'center center',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
    },
    round: {
        borderRadius: '50%',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    image : Image;
    width : number;
    height : number;
    rotation? : number;
    x : number;
    y : number;
    round? : boolean;
}

type Props = OwnProps;

class CroppedTile extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, image, width, height, x, y, rotation, round} = this.props;

        const styles : CSSProperties = {
            backgroundImage: `url('${thumbnailUri(image.imageUri, 'h400')}')`,
            transform: `
                translate(calc(-50% + ${x / 2}vw), calc(-50% + ${y / 2}vw))
                rotate(${rotation || 0}deg)
            `,
            width: `${width / 2}vw`,
            height: `${height / 2}vw`,
        };

        return (
            <div className={classNames(classes.root, round ? classes.round : undefined)} style={styles}/>
        );
    }
}

export default withStyles(styles)(
    CroppedTile
);
