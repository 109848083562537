import {createStyles, Theme, Typography, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {AppState} from '../../../../redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {Board} from '../../../../redux/boards/types';
import BoardList from '../../../../components/board/BoardList';
import InlineLoadingIndicator from '../../../../components/InlineLoadingIndicator';
import {apiEndpoint, apiFetch} from '../../../../utils/api';
import {enqueueSnackbar} from '../../../../redux/notifier/actions';
import {OptionsObject} from 'notistack';
import {maxBoardTileImages} from '../../../../components/board/BoardTileThumbnail';
import dateFormat from 'dateformat';

const styles = (theme : Theme) : StyleRules => createStyles({
    tabs: {
        marginBottom: theme.spacing(4),
    },
    listHeading: {
        marginBottom: theme.spacing(2),
    },
    boardList: {
        marginBottom: theme.spacing(4),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    filter : string;
}

interface DispatchProps
{
    enqueueSnackbar : (message : string | React.ReactNode, options? : OptionsObject) => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    boards : Board[] | null;
}

class MyWork extends Component<Props, State>
{
    public readonly state : State = {
        boards: null,
    };

    public render() : ReactNode
    {
        const {classes, filter} = this.props;
        const {boards} = this.state;

        if (boards === null) {
            return <InlineLoadingIndicator/>;
        }

        const myBoards = boards
            .filter(board => board._user.role === 'owner' && !board._user.archived)
            .sort((a, b) => Date.parse(b.lastModifiedAt) - Date.parse(a.lastModifiedAt));

        const sharedWithMe = boards
            .filter(board => board._user.role !== 'owner' && !board._user.archived)
            .sort((a, b) => Date.parse(b.lastModifiedAt) - Date.parse(a.lastModifiedAt));

        const archivedBoards = boards
            .filter(board => board._user.archived)
            .sort((a, b) => Date.parse(b.lastModifiedAt) - Date.parse(a.lastModifiedAt));

        return (
            <React.Fragment>
                {boards.length === 0 && (
                    <Typography variant="h2">
                        {filter ? 'No board matched the filter' : 'There are no assignments'}
                    </Typography>
                )}

                {myBoards.length > 0 && (
                    <React.Fragment>
                        <Typography
                            variant="h2"
                            className={classes.listHeading}
                            id="my-boards"
                        >My Boards</Typography>
                        <BoardList boards={myBoards} className={classes.boardList}/>
                    </React.Fragment>
                )}

                {sharedWithMe.length > 0 && (
                    <React.Fragment>
                        <Typography
                            variant="h2"
                            className={classes.listHeading}
                            id="shared-with-me"
                        >Shared with me</Typography>
                        <BoardList
                            boards={sharedWithMe}
                            className={classes.boardList}
                            renderAdditionalInfo={board => (
                                <Typography variant="body1">
                                    {dateFormat(board.lastModifiedAt)}
                                </Typography>
                            )}
                        />
                    </React.Fragment>
                )}

                {archivedBoards.length > 0 && (
                    <React.Fragment>
                        <Typography
                            variant="h2"
                            className={classes.listHeading}
                            id="archived"
                        >Archived</Typography>
                        <BoardList
                            boards={archivedBoards}
                            className={classes.boardList}
                            renderAdditionalInfo={board => (
                                <Typography variant="body1">
                                    {board._user.role === 'owner' ? 'Owner' : (
                                        board._user.role === 'collaborator' ? 'Collaborator' : 'Viewer'
                                    )}
                                </Typography>
                            )}
                        />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        this.handleScrollTo();
        await this.loadBoards();
    }

    public async componentDidUpdate(prevProps : Readonly<Props>) : Promise<void>
    {
        if (prevProps.filter !== this.props.filter) {
            await this.loadBoards();
        }

        if (this.state.boards) {
            this.handleScrollTo();
        }
    }

    private handleScrollTo() : void
    {
        const hash = window.location.hash;

        if (!hash) {
            return;
        }

        const element = document.querySelector(hash);

        if (element) {
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - 80;
            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            })
        }
    }

    private async loadBoards() : Promise<void>
    {
        const {filter} = this.props;
        this.setState({boards: null});

        const url = new URL(`${apiEndpoint}/boards`);
        url.searchParams.set('includeRecentImages', maxBoardTileImages.toString());

        if (filter.length >= 3) {
            url.searchParams.set('query', filter);
        }

        const result = await apiFetch(url.href);

        if (!result.ok) {
            this.props.enqueueSnackbar('An error occurred while loading the boards.', {variant: 'error'});
            return;
        }

        const data = await result.json();
        this.setState({boards: data.items});
    }
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    enqueueSnackbar: (message : string | React.ReactNode, options? : OptionsObject) => dispatch(
        enqueueSnackbar(message, options)
    ),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        MyWork
    )
);
