import {SET_USER, UNSET_USER, UPDATE_PROFILE, UserActionTypes, UserState} from './types';

const initialState : UserState = {
    user: null,
};

export const userReducer = (
    state = initialState,
    action : UserActionTypes
) : UserState => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload.user,
            };

        case UNSET_USER:
            return initialState;

        case UPDATE_PROFILE:
            if (!state.user) {
                console.error('Trying to update user profile without user being loaded');
                return state;
            }

            return {
                ...state,
                user: {
                    ...state.user,
                    profile: action.payload.profile,
                },
            };

        default:
            return state;
    }
};
