export interface ShowBoardState
{
    showNewBoard : boolean;
}

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';

interface SetShowAction
{
    type : typeof SHOW_MODAL;
}

interface SetHideAction
{
    type : typeof HIDE_MODAL;
}

export type ShowActionTypes = SetShowAction | SetHideAction;