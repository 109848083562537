import {OptionsObject} from 'notistack';
import * as React from 'react';

export type Key = string | number;

export type Notification = {
    message : string | React.ReactNode;
    options? : OptionsObject;
    key : Key;
    dismissed? : boolean;
};

export interface NotifierState
{
    notifications : Notification[];
    processing : boolean;
}

export const ENQUEUE_SNACKBAR = 'NOTIFIER_ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'NOTIFIER_CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'NOTIFIER_REMOVE_SNACKBAR';
export const OVERLAY_PROCESSING = 'NOTIFIER_OVERLAY_PROCESSING';

interface EnqueueSnackbarAction
{
    type : typeof ENQUEUE_SNACKBAR;
    payload : {
        notification : Notification;
    };
}

interface CloseSnackbarAction
{
    type : typeof CLOSE_SNACKBAR;
    payload : {
        dismissAll : boolean;
        key? : Key;
    };
}

interface RemoveSnackbarAction
{
    type : typeof REMOVE_SNACKBAR;
    payload : {
        key : Key;
    };
}

interface OverlayProcessingAction
{
    type : typeof OVERLAY_PROCESSING;
    payload : {
        show : boolean;
    };
}

export type NotifierActionTypes =
    EnqueueSnackbarAction
    | CloseSnackbarAction
    | RemoveSnackbarAction
    | OverlayProcessingAction;
