import {createStyles, Theme, Typography, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {AppState} from '../../../../redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {Board} from '../../../../redux/boards/types';
import BoardList from '../../../../components/board/BoardList';
import InlineLoadingIndicator from '../../../../components/InlineLoadingIndicator';
import {apiEndpoint, apiFetch} from '../../../../utils/api';
import {enqueueSnackbar} from '../../../../redux/notifier/actions';
import {OptionsObject} from 'notistack';
import WindowInfiniteScroll from '../../../../components/WindowInfiniteScroll';
import {maxBoardTileImages} from '../../../../components/board/BoardTileThumbnail';
import dateFormat from 'dateformat';

const styles = (theme : Theme) : StyleRules => createStyles({
    tabs: {
        marginBottom: theme.spacing(4),
    },
    listHeading: {
        marginBottom: theme.spacing(2),
    },
    boardList: {
        marginBottom: theme.spacing(4),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    filter : string;
}

interface DispatchProps
{
    enqueueSnackbar : (message : string | React.ReactNode, options? : OptionsObject) => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    boards : Board[];
    nextResultsUrl : URL | null;
    loading : boolean;
}

class AllArtBoards extends Component<Props, State>
{
    public readonly state : State = {
        boards: [],
        nextResultsUrl: null,
        loading: false,
    };

    public render() : ReactNode
    {
        const {classes, filter} = this.props;
        const {boards, loading, nextResultsUrl} = this.state;

        return (
            <React.Fragment>
                <Typography variant="h2" className={classes.listHeading}>All Art Boards</Typography>

                {(!loading && boards.length === 0) ? (
                    <Typography variant="body1">
                        {filter ? 'No board matched the filter' : 'No draft boards at this time'}
                    </Typography>
                ) : (
                    <React.Fragment>
                        {boards.length > 0 && (
                            <BoardList
                                boards={boards}
                                className={classes.boardList}
                                renderAdditionalInfo={board => (
                                    <Typography variant="body1">
                                        {dateFormat(board.lastModifiedAt)}
                                    </Typography>
                                )}
                            />
                        )}
                        {loading && (
                            <InlineLoadingIndicator/>
                        )}
                    </React.Fragment>
                )}

                <WindowInfiniteScroll
                    loading={loading}
                    hasNextResults={nextResultsUrl !== null}
                    loadNextResults={this.handleLoadNextResults}
                />
            </React.Fragment>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        await this.loadBoards();
    }

    public async componentDidUpdate(prevProps : Readonly<Props>) : Promise<void>
    {
        if (prevProps.filter !== this.props.filter) {
            await this.loadBoards();
        }
    }

    private async loadBoards() : Promise<void>
    {
        const {filter} = this.props;

        this.setState({boards: [], loading: true});
        const url = new URL(`${apiEndpoint}/boards`);
        url.searchParams.set('archived', 'false');
        url.searchParams.set('unrestricted', 'true');
        url.searchParams.set('consultantRequested', 'false');
        url.searchParams.set('consultantAssigned', 'false');
        url.searchParams.set('includeRecentImages', maxBoardTileImages.toString());
        url.searchParams.set('limit', '100');

        if (filter.length >= 3) {
            url.searchParams.set('query', filter);
        }

        await this.loadResults(url);
    }

    private async loadResults(url : URL) : Promise<void>
    {
        const result = await apiFetch(url.href);

        if (!result.ok) {
            this.props.enqueueSnackbar('An error occurred while loading the boards.', {variant: 'error'});
            return;
        }

        const data = await result.json();
        let nextResultsUrl : URL | null = null;

        if (data.links.next) {
            nextResultsUrl = new URL(data.links.next.href);
        }

        this.setState({boards: this.state.boards.concat(data.items), loading: false, nextResultsUrl});
    }

    private handleLoadNextResults = async () : Promise<void> => {
        const {nextResultsUrl} = this.state;

        if (nextResultsUrl === null) {
            return;
        }

        this.setState({loading: true});
        await this.loadResults(nextResultsUrl);
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    enqueueSnackbar: (message : string | React.ReactNode, options? : OptionsObject) => dispatch(
        enqueueSnackbar(message, options)
    ),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        AllArtBoards
    )
);
