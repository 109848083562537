import {createStyles, InputAdornment, Theme, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {ChangeEvent, Component, ReactNode} from 'react';
import {AppState} from '../../../redux';
import {connect} from 'react-redux';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Route, Switch} from 'react-router';
import MyWork from './Tabs/MyWork';
import TextField from '@material-ui/core/TextField';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {push as pushLocation} from 'connected-react-router';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import debounce from 'lodash.debounce';
import FabContainer from '../../../components/FabContainer';
import AllArtBoards from './Tabs/AllArtBoards';

const styles = (theme : Theme) : StyleRules => createStyles({
    header: {
        display: 'flex',
        marginBottom: theme.spacing(4),
    },
    spacer: {
        flexGrow: 1,
    },
    filter: {
        width: 300,
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface StateProps
{
    currentPath : string;
}

interface DispatchProps
{
    pushLocation : (path : string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State
{
    enteredFilter : string;
    processedFilter : string;
}

class ConsultantDashboard extends Component<Props, State>
{
    public readonly state : State = {
        enteredFilter: '',
        processedFilter: '',
    };

    private debouncedFilterUpdate = debounce((filter : string) => {
        this.setState({
            processedFilter: filter,
        });
    },                                       500);

    public render() : ReactNode
    {
        const {classes, currentPath} = this.props;
        const {enteredFilter, processedFilter} = this.state;

        return (
            <React.Fragment>
                <div className={classes.header}>
                    <Tabs value={currentPath} className={classes.tabs} onChange={this.handleTabChange}>
                        <Tab value="/dashboard" label="My Work"/>
                        <Tab value="/dashboard/all-art-boards" label="All Art Boards"/>
                    </Tabs>
                    <div className={classes.spacer}/>
                    <TextField
                        className={classes.filter}
                        placeholder="Filter by owner or board"
                        variant="outlined"
                        value={enteredFilter}
                        onChange={this.handleFilterChange}
                        InputProps={{
                            endAdornment: enteredFilter ? (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={this.handleFilterClear}>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined,
                        }}
                    />
                </div>

                <Switch>
                    <Route path="/dashboard" exact render={routeProps => (
                        <MyWork {...routeProps} filter={processedFilter}/>
                    )}/>
                    <Route path="/dashboard/all-art-boards" exact render={routeProps => (
                        <AllArtBoards {...routeProps} filter={processedFilter}/>
                    )}/>
                </Switch>

                <FabContainer/>
            </React.Fragment>
        );
    }

    private handleTabChange = (event : ChangeEvent<{}>, value : any) : void => {
        this.props.pushLocation(value);
    };

    private handleFilterChange = (event : ChangeEvent<HTMLInputElement>) : void => {
        this.setState({
            enteredFilter: event.target.value,
        });
        this.debouncedFilterUpdate(event.target.value);
    };

    private handleFilterClear = () : void => {
        this.debouncedFilterUpdate.cancel();
        this.setState({
            enteredFilter: '',
            processedFilter: '',
        });
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    currentPath: state.router.location.pathname,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        ConsultantDashboard
    )
);
