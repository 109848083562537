import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'inline-block',
        position: 'relative',
        width: 64,
        height: 64,
        '& div': {
            position: 'absolute',
            top: 27,
            width: 11,
            height: 11,
            borderRadius: '50%',
            backgroundColor: '#aaa',
            animationTimingFunction: 'cubic-bezier(0, 1, 1, 0)',
        },
        '& div:nth-child(1)': {
            left: 6,
            animation: '$three-dot-loading-indicator-1 .6s infinite',
        },
        '& div:nth-child(2)': {
            left: 6,
            animation: '$three-dot-loading-indicator-2 .6s infinite',
        },
        '& div:nth-child(3)': {
            left: 26,
            animation: '$three-dot-loading-indicator-2 .6s infinite',
        },
        '& div:nth-child(4)': {
            left: 45,
            animation: '$three-dot-loading-indicator-3 .6s infinite',
        },
    },
    '@keyframes three-dot-loading-indicator-1': {
        from: {
            transform: 'scale(0)',
        },
        to: {
            transform: 'scale(1)',
        },
    },
    '@keyframes three-dot-loading-indicator-2': {
        from: {
            transform: 'translate(0, 0)',
        },
        to: {
            transform: 'translate(19px, 0)',
        },
    },
    '@keyframes three-dot-loading-indicator-3': {
        from: {
            transform: 'scale(1)',
        },
        to: {
            transform: 'scale(0)',
        },
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

type Props = OwnProps;

class ThreeDotLoadingIndicator extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes} = this.props;

        return (
            <div className={classes.root}>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        );
    }
}

export default withStyles(styles)(
    ThreeDotLoadingIndicator
);
