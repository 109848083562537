import {Button, createStyles, Grid, TextField, Theme, withStyles, WithStyles,} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import {getData as getCountryData} from 'country-list';
import {ValidationErrors} from 'final-form';
import {parsePhoneNumberFromString} from 'libphonenumber-js';
import React, {Component, ReactNode} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {AppState} from '../../redux';
import {Auth0User} from '../../redux/auth0/types';
import {UserProfile} from '../../redux/user/types';
import {FinalFormReactSelect, FinalFormTextField} from '../inputs/final-form-wrappers';

const styles = (theme : Theme) : StyleRules => createStyles({
    button: {
        marginTop: theme.spacing(3),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    userProfile? : UserProfile;
    handleSubmit : (profile : UserProfile) => Promise<void>;
}

interface StateProps
{
    auth0User : Auth0User | null;
}

type Props = OwnProps & StateProps;

const validate = (values : any) => {
    const errors : ValidationErrors = {};

    if (!values.firstName || !values.firstName.trim()) {
        errors.firstName = 'Required';
    }

    if (!values.lastName || !values.lastName.trim()) {
        errors.lastName = 'Required';
    }

    if (!values.company || !values.company.trim()) {
        errors.company = 'Required';
    }

    if (values.phoneNumber && !parsePhoneNumberFromString(values.phoneNumber, 'US')) {
        errors.phoneNumber = 'Invalid phone number';
    }

    return errors;
};

const trim = (value : any) => value ? value.trim() : '';
const keepEmpty = (value : any) => value ? value : '';

class UserProfileForm extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, userProfile, auth0User} = this.props;

        let formData : any = {
            firstName: '',
            lastName: '',
            title: '',
            company: '',
            addressLineOne: '',
            addressLineTwo: '',
            city: '',
            state: '',
            postalCode: '',
            countryCode: '',
            phoneNumber: '',
        };

        if (userProfile) {
            formData = {...userProfile};
        }

        return (
            <Form
                onSubmit={this.handleSubmit}
                initialValues={formData}
                validate={validate}
                render={({handleSubmit, submitting, pristine, invalid}) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="firstName"
                                        label="First Name"
                                        required
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        name="lastName"
                                        label="Last Name"
                                        required
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        label="Email"
                                        disabled
                                        fullWidth
                                        variant="outlined"
                                        value={auth0User ? auth0User.emailAddress : ''}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="title"
                                        label="Title"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="company"
                                        label="Company"
                                        required
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="addressLineOne"
                                        label="Address"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="addressLineTwo"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <Field
                                        name="city"
                                        label="City"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <Field
                                        name="state"
                                        label="State"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="postalCode"
                                        label="Zip / Postal Code"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="countryCode"
                                        label="Country"
                                        select
                                        component={FinalFormReactSelect}
                                        options={getCountryData().map(
                                            country => ({value: country.code, label: country.name})
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Field
                                        name="phoneNumber"
                                        label="Phone"
                                        component={FinalFormTextField}
                                        format={trim}
                                        formatOnBlur
                                        parse={keepEmpty}
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                className={classes.button}
                                variant="contained"
                                type="submit"
                                disabled={pristine || invalid || submitting}
                            >
                                Proceed
                            </Button>
                        </form>
                    );
                }}
            />
        );
    }

    private handleSubmit = async (values : any) => {
        let phoneNumber = '';

        if (values.phoneNumber) {
            const parsedPhoneNumber = parsePhoneNumberFromString(values.phoneNumber, 'US');
            phoneNumber = parsedPhoneNumber!.format('E.164');
        }

        await this.props.handleSubmit({
            firstName: values.firstName,
            lastName: values.lastName,
            title: values.title || '',
            company: values.company,
            addressLineOne: values.addressLineOne || '',
            addressLineTwo: values.addressLineTwo || '',
            city: values.city || '',
            state: values.state || '',
            postalCode: values.postalCode || '',
            countryCode: values.countryCode || '',
            phoneNumber,
        });
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    auth0User: state.auth0.user,
});

export default withStyles(styles)(
    connect<StateProps, null, OwnProps, AppState>(
        mapStateToProps
    )(
        UserProfileForm
    )
);
