import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import UserAvatarInfoButton from './UserAvatarInfoButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/Avatar';
import {BoardMember} from '../../redux/boards/types';
import {sortBoardMembers} from '../../utils/board';
import classNames from 'classnames';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        '& > *': {
            marginLeft: theme.spacing(.5),
        },
        '& > *:first-child': {
            marginLeft: 0,
        },
    },
    overflow: {
        width: 24,
        height: 24,
        fontSize: '.5rem',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    className? : string;
    members : BoardMember[];
    maximum? : number;
}

type Props = OwnProps;

class BoardMemberList extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, className, members, maximum} = this.props;

        let overflow : ReactNode = null;

        if (maximum !== undefined && members.length > maximum) {
            overflow = (
                <Avatar className={classes.overflow}>+{members.length - maximum}</Avatar>
            );
        }

        const membersToShow = sortBoardMembers(members).slice(0, maximum);

        return (
            <div className={classNames([classes.root, className])}>
                {membersToShow.map(member => (
                    <UserAvatarInfoButton
                        key={member.user.id}
                        user={member.user}
                        size="small"
                    />
                ))}
                {overflow}
            </div>
        );
    }
}

export default withStyles(styles)(BoardMemberList);
