import {createStyles, IconButton, InputBase, Theme, WithStyles, Grid} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import classNames from 'classnames';
import React, {ChangeEvent, Component, FormEvent, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../redux';
import {
    clearQuery,
    performSearch,
    updateEnteredQuery,
    updateSelectedOrder,
    updateSelectedType
} from '../redux/image-search/actions';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {ImageSearchOrder, ImageSearchType} from '../redux/image-search/types';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import poweredByLogo from '../assets/powered-by.png';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        paddingRight: theme.spacing(4),
    },
    inputContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #000',
        flexGrow: 1,
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        maxWidth: '500px',
    },
    input: {
        flexGrow: 1,
    },
    clear: {
        padding: 2,
    },
    button: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#000',
        border: '1px solid #000',
        color: '#fff',
        margin: '0 0 0 -1px',
        cursor: 'pointer',
        padding: '.375rem .25rem',
        transition: 'all .25s ease',
        transitionProperty: 'background-color, color',
        '&:hover, &:focus': {
            backgroundColor: '#fff',
            color: '#000',
        },
    },
    selectIcon: {
        color: '#fff',
    },
    switch: {
        marginLeft: theme.spacing(2),
    },
    spacer: {
        flexGrow: 1,
    },
    poweredBy: {
        cursor: 'pointer',
        alignSelf: 'center',
    },
});

const TypeInput = withStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#000',
        width: 120,
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        width: 'auto',
        backgroundColor: '#000',
        color: '#fff',
        paddingLeft: theme.spacing(1),
        '&:focus': {
            backgroundColor: '#000',
            color: '#fff',
        },
    },
}))(InputBase);

const BlackSwitch = withStyles(theme => ({
    root: {
        margin: '0 -12px',
    },
    switchBase: {
        color: '#000',
        '&$checked': {
            color: '#000',
            '& + $track': {
                opacity: 1,
                border: `1px solid ${theme.palette.common.black}`,
                backgroundColor: theme.palette.common.white,
            },
        },
    },
    thumb: {
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.common.black}`,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
}))(Switch);

interface OwnProps extends WithStyles<typeof styles>
{
    className? : string;
}

interface StateProps
{
    enteredQuery : string;
    selectedType : ImageSearchType;
    selectedOrder : ImageSearchOrder;
}

interface DispatchProps
{
    updateEnteredQuery : (query : string) => void;
    updateSelectedType : (type : ImageSearchType) => void;
    updateSelectedOrder : (order : ImageSearchOrder) => void;
    performSearch : (query : string, type : ImageSearchType, order : ImageSearchOrder) => void;
    clearQuery : () => void;
}

type Props = StateProps & DispatchProps & OwnProps;

class SearchBar extends Component<Props>
{
    private readonly input = React.createRef<HTMLInputElement>();

    public render() : ReactNode
    {
        const {classes, className, enteredQuery, selectedType, selectedOrder} = this.props;

        return (
            <form className={classNames(classes.root, className)} onSubmit={this.handleSubmit}>
                <div className={classes.inputContainer}>
                    <InputBase
                        value={enteredQuery}
                        className={classes.input}
                        onChange={this.handleQueryChange}
                        inputRef={this.input}
                        placeholder="Type Keyword or Image Number to Search"
                    />

                    {enteredQuery !== '' && (
                        <IconButton className={classes.clear} onClick={this.handleClear}>
                            <ClearIcon/>
                        </IconButton>
                    )}
                </div>
                <Select input={<TypeInput/>} value={selectedType} onChange={this.handleTypeChange} classes={{
                    root: classes.selectRoot,
                    icon: classes.selectIcon,
                }}>
                    <MenuItem value="all">all images</MenuItem>
                    <MenuItem value="accessible-boards">my images</MenuItem>
                </Select>
                <button type="submit" className={classes.button}>
                    <SearchIcon/>
                </button>

                <Typography component="div" className={classes.switch}>
                    <Grid component="label" container alignItems="center" spacing={1}>
                        <Grid item>Random</Grid>
                        <Grid item>
                            <BlackSwitch
                                checked={selectedOrder === 'newest-first'}
                                value="newest-first"
                                onChange={this.handleOrderChange}
                            />
                        </Grid>
                        <Grid item>Chronological</Grid>
                    </Grid>
                </Typography>

                <div className={classes.spacer}/>

                <img
                    src={poweredByLogo}
                    height={27}
                    className={classes.poweredBy}
                    alt="Powered by Kalisher"
                    onClick={() => window.open('https://kalisher.com')}
                />
            </form>
        );
    }

    private handleClear = () : void => {
        this.props.clearQuery();

        if (this.input.current) {
            this.input.current.focus();
        }
    };

    private handleQueryChange = (event : ChangeEvent<HTMLInputElement>) : void => {
        this.props.updateEnteredQuery(event.target.value);
    };

    private handleTypeChange = (event : ChangeEvent<{value : unknown}>) : void => {
        this.props.updateSelectedType(event.target.value as ImageSearchType);
    };

    private handleOrderChange = (event : ChangeEvent<{checked : boolean}>) : void => {
        const order = event.target.checked ? 'newest-first' : 'random';

        if (this.props.enteredQuery.trim() === '') {
            this.props.updateSelectedOrder(order);
            return;
        }

        this.props.performSearch(
            this.props.enteredQuery.trim(),
            this.props.selectedType,
            order
        );
    };

    private handleSubmit = (event : FormEvent<HTMLFormElement>) : void => {
        event.preventDefault();

        if (this.props.enteredQuery.trim() === '') {
            return;
        }

        this.props.performSearch(
            this.props.enteredQuery.trim(),
            this.props.selectedType,
            this.props.selectedOrder
        );
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    enteredQuery: state.imageSearch.enteredQuery,
    selectedType: state.imageSearch.selectedType,
    selectedOrder: state.imageSearch.selectedOrder,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    updateEnteredQuery: (query : string) => dispatch(updateEnteredQuery(query)),
    updateSelectedType: (type : ImageSearchType) => dispatch(updateSelectedType(type)),
    updateSelectedOrder: (order : ImageSearchOrder) => dispatch(updateSelectedOrder(order)),
    performSearch: (
        query : string,
        type : ImageSearchType,
        order : ImageSearchOrder
    ) => dispatch(performSearch(query, type, order)),
    clearQuery: () => dispatch(clearQuery()),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        SearchBar
    )
);
