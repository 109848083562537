import {HIDE_MODAL, SHOW_MODAL, ShowActionTypes, ShowBoardState} from './types';

const initialState : ShowBoardState = {
    showNewBoard : false,
};

export const showBoardReducer = (
    state = initialState,
    action : ShowActionTypes
) : ShowBoardState => {
    switch (action.type) {
        case SHOW_MODAL:
            return {
                ...state,
                showNewBoard: true,
            };

        case HIDE_MODAL:
            return {
                ...state,
                showNewBoard: false,
            };

        default:
            return state;
    }
};
