import React, {Component, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import BoardTile from './BoardTile';
import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

const styles = (theme : Theme) : StyleRules => createStyles({
    root : {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: -30,
        marginTop: -30,
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    className? : string;
    boards : Board[];
    renderAdditionalInfo? : (board : Board) => void;
}

type Props = OwnProps;

class BoardList extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, className, boards, renderAdditionalInfo} = this.props;

        return (
            <div className={classNames(classes.root, className)}>
                {boards.map(board => (
                    <BoardTile
                        key={board.id}
                        board={board}
                        renderAdditionalInfo={renderAdditionalInfo}
                    />
                ))}
            </div>
        );
    }
}

export default withStyles(styles)(
    BoardList
);
