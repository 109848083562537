import {Component} from 'react';

interface OwnProps
{
    loading : boolean;
    hasNextResults : boolean;
    loadNextResults : () => void;
}

type Props = OwnProps;

class WindowInfiniteScroll extends Component<Props> {
    public render() : null
    {
        return null;
    }

    public componentDidMount() : void
    {
        window.addEventListener('scroll', this.handleScroll);
    }

    public componentWillUnmount() : void
    {
        window.removeEventListener('scroll', this.handleScroll);
    }

    public componentDidUpdate(prevProps : Readonly<Props>) : void
    {
        if (prevProps.loading && !this.props.loading && WindowInfiniteScroll.inLoadingDistance()) {
            this.props.loadNextResults();
        }
    }

    private handleScroll = () : void => {
        const {loading, hasNextResults} = this.props;

        if (loading || !hasNextResults || !WindowInfiniteScroll.inLoadingDistance()) {
            return;
        }

        this.props.loadNextResults();
    };

    private static inLoadingDistance() : boolean
    {
        return document.body.offsetHeight - 20 <= window.scrollY + window.innerHeight;
    }
}

export default WindowInfiniteScroll;
