import {Image} from '../image-search/types';
import {MinimumUser} from '../user/types';

export type BoardPermissions = {
    viewBoard : boolean;
    updateBoard : boolean;
    archiveBoard : boolean;
    addImages : boolean;
    removeImages : boolean;
    resortImages : boolean;
    requestConsultant : boolean;
    addMembers : boolean;
    updateMembers : boolean;
    removeMembers : boolean;
    switchType : boolean;
    updateSpecs : boolean;
};

export type BoardRole = 'owner' | 'collaborator' | 'viewer';
export type BoardType = 'regular' | 'social';
export type AssignableRole = 'collaborator' | 'viewer';

export interface BoardMember
{
    role : BoardRole;
    user : MinimumUser;
}

export interface Specs
{
    itemNumber : string;
    item : string | null;
    quantity : number | null;
    width : string | null;
    height : string | null;
    matSize : string | null;
    frame : string | null;
    complete : boolean;
}

export interface BoardImage
{
    position : number;
    image : Image;
    specs : Specs;
}

export interface Board
{
    id : string;
    createdAt : string;
    lastModifiedAt : string;
    owner : MinimumUser;
    name : string;
    description : string;
    consultantRequested : boolean;
    type : BoardType;
    readyForSpecs : boolean;
    specsComplete : boolean;
    _members : BoardMember[];
    _user : {
        role : BoardRole | null;
        permissions : BoardPermissions;
        archived : boolean;
    };
    _recentImages? : Image[];
}

export type FramePosition = 'over' | 'under';

export type FrameOptions = {
    name : string;
    type : string;
    label : string;
    finish : string;
    color : string;
    width : string;
    overlap : string;
    position : FramePosition;
    feetPerBox : number;
    colorCode : string;
};

export type MatOptions = {
    color : string;
    colorCode : string;
};

export type ItemOptions = {
    label : string;
    substrate : string;
    hardware : string;
    backer : string;
    glass : string | null;
    mat : MatOptions | null;
    frame : string | null;
};

export type ValidationOptions = {
    minSize: string;
    maxSize: string;
    minMatSize : string;
    maxMatSize : string;
    areaLimit : string;
};

export type SpecOptions = {
    validation: ValidationOptions;
    frames: {[key : string] : {[key : string] : FrameOptions}};
    items: {[key : string] : ItemOptions};
};

export interface BoardsState
{
    currentBoard : {
        board : Board | null;
        boardImages : BoardImage[];
        loading : boolean;
    };
    accessibleBoards : Board[] | null;
    specOptions : SpecOptions | null;
}

export type ChangePosition = 'before' | 'after';

export const SET_CURRENT_BOARD = 'BOARDS_SET_CURRENT_BOARD';
export const UNSET_CURRENT_BOARD = 'BOARDS_UNSET_CURRENT_BOARD';
export const SET_ACCESSIBLE_BOARDS = 'BOARDS_SET_ACCESSIBLE_BOARDS';
export const UNSET_ACCESSIBLE_BOARDS = 'BOARDS_UNSET_ACCESSIBLE_BOARDS';
export const FETCH_IMAGES_BEGIN = 'BOARDS_FETCH_IMAGES_BEGIN';
export const FETCH_IMAGES_END = 'BOARDS_FETCH_IMAGES_END';
export const UPDATE_BOARD_NAME = 'BOARDS_UPDATE_BOARD_NAME';
export const UPDATE_BOARD_DESCRIPTION = 'BOARDS_UPDATE_BOARD_DESCRIPTION';
export const UPDATE_BOARD_ARCHIVE_STATUS = 'BOARDS_UPDATE_BOARD_ARCHIVE_STATUS';
export const SWITCH_BOARD_TYPE = 'BOARDS_SWITCH_BOARD_TYPE';
export const REMOVE_IMAGES = 'BOARDS_REMOVE_IMAGES';
export const ADD_MEMBER = 'BOARDS_ADD_MEMBER';
export const UPDATE_MEMBER = 'BOARDS_UPDATE_MEMBER';
export const REMOVE_MEMBER = 'BOARDS_REMOVE_MEMBER';
export const REQUEST_CONSULTANT = 'BOARDS_REQUEST_CONSULTANT';
export const REMOVE_CONSULTANT_REQUEST = 'BOARDS_REMOVE_CONSULTANT_REQUEST';
export const CHANGE_IMAGE_POSITION = 'BOARDS_CHANGE_IMAGE_POSITION';
export const SWITCH_READY_FOR_SPECS = 'BOARDS_SWITCH_READY_FOR_SPECS';
export const SET_SPEC_OPTIONS = 'BOARDS_SET_SPEC_OPTIONS';
export const UPDATE_SPECS = 'BOARDS_UPDATE_SPECS';

interface SetCurrentBoardAction
{
    type : typeof SET_CURRENT_BOARD;
    payload : {
        board : Board;
    };
}

interface UnsetCurrentBoardAction
{
    type : typeof UNSET_CURRENT_BOARD;
}

interface SetAccessibleBoardsAction
{
    type : typeof SET_ACCESSIBLE_BOARDS;
    payload : {
        boards : Board[];
    };
}

interface UnsetAccessibleBoardsAction
{
    type : typeof UNSET_ACCESSIBLE_BOARDS;
}

interface FetchImagesBeginAction
{
    type : typeof FETCH_IMAGES_BEGIN;
}

interface FetchImagesEndAction
{
    type : typeof FETCH_IMAGES_END;
    payload : {
        boardImages : BoardImage[];
    };
}

interface UpdateBoardNameAction
{
    type : typeof UPDATE_BOARD_NAME;
    payload : {
        boardId : string;
        name : string;
    };
}

interface UpdateBoardDescriptionAction
{
    type : typeof UPDATE_BOARD_DESCRIPTION;
    payload : {
        boardId : string;
        description : string;
    };
}

interface UpdateBoardArchiveStatusAction
{
    type : typeof UPDATE_BOARD_ARCHIVE_STATUS;
    payload : {
        boardId : string;
        archived : boolean;
        unshare : boolean;
    };
}

interface SwitchBoardTypeStatusAction
{
    type : typeof SWITCH_BOARD_TYPE;
    payload : {
        boardId : string;
        type : BoardType;
    };
}

interface RemoveImagesAction
{
    type : typeof REMOVE_IMAGES;
    payload : {
        boardId : string;
        imageIds : string[];
    };
}

interface AddMemberAction
{
    type : typeof ADD_MEMBER;
    payload : {
        boardId : string;
        member : BoardMember;
    };
}

interface UpdateMemberAction
{
    type : typeof UPDATE_MEMBER;
    payload : {
        boardId : string;
        userId : string;
        role : AssignableRole;
    };
}

interface RemoveMemberAction
{
    type : typeof REMOVE_MEMBER;
    payload : {
        boardId : string;
        userId : string;
    };
}

interface RequestConsultantAction
{
    type : typeof REQUEST_CONSULTANT;
    payload : {
        boardId : string;
    };
}

interface RemoveConsultantRequestAction
{
    type : typeof REMOVE_CONSULTANT_REQUEST;
    payload : {
        boardId : string;
    };
}

interface ChangeImagePositionAction
{
    type : typeof CHANGE_IMAGE_POSITION;
    payload : {
        boardId : string;
        imageId : string;
        targetId : string;
        position : ChangePosition;
    };
}

interface SwitchReadyForSpecsAction
{
    type : typeof SWITCH_READY_FOR_SPECS;
    payload : {
        boardId : string;
        readyForSpecs : boolean;
    };
}

interface SetSpecOptionsAction
{
    type : typeof SET_SPEC_OPTIONS;
    payload : {
        specOptions : SpecOptions;
    };
}

interface UpdateSpecsAction
{
    type : typeof UPDATE_SPECS;
    payload : {
        boardId : string;
        imageId : string;
        specs : Specs;
    };
}

export type BoardsActionTypes =
    SetCurrentBoardAction
    | UnsetCurrentBoardAction
    | SetAccessibleBoardsAction
    | UnsetAccessibleBoardsAction
    | FetchImagesBeginAction
    | FetchImagesEndAction
    | UpdateBoardNameAction
    | UpdateBoardDescriptionAction
    | UpdateBoardArchiveStatusAction
    | SwitchBoardTypeStatusAction
    | RemoveImagesAction
    | AddMemberAction
    | UpdateMemberAction
    | RemoveMemberAction
    | RequestConsultantAction
    | RemoveConsultantRequestAction
    | ChangeImagePositionAction
    | SwitchReadyForSpecsAction
    | SetSpecOptionsAction
    | UpdateSpecsAction;
