import {createStyles, Theme, Typography, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {ChangeEvent, Component, FocusEvent, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import {updateBoardName} from '../../redux/boards/actions';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../redux';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import UnderlinedButton from '../UnderlinedButton';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    text: {
        marginRight: theme.spacing(2),
    },
    dialogActionsDivider: {
        flexGrow: 1,
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
    className? : string;
}

interface DispatchProps
{
    updateBoardName : (boardId : string, name : string) => Promise<void>;
}

type Props = OwnProps & DispatchProps;

interface State
{
    editing : boolean;
    name : string;
    error : boolean;
}

class EditableBoardName extends Component<Props, State>
{
    public readonly state = {
        editing : false,
        name : '',
        error: false,
    };

    public render() : ReactNode
    {
        const {classes, className, board} = this.props;
        const {editing, name, error} = this.state;

        const canEdit = board._user.permissions.updateBoard;

        return (
            <div className={classNames(classes.root, className)}>
                {!editing && (
                    <Typography variant="h2" className={classes.text}>{board.name}</Typography>
                )}

                {editing && (
                    <TextField
                        variant="standard"
                        onBlur={this.handleFieldBlur}
                        onChange={this.handleChange}
                        autoFocus
                        fullWidth
                        value={name}
                        error={error}
                    />
                )}

                {!editing && canEdit && (
                    <UnderlinedButton onClick={this.handleEditClick}>
                        Edit
                    </UnderlinedButton>
                )}
            </div>
        );
    }

    private handleEditClick = () : void => {
        this.setState({editing: true, name: this.props.board.name});
    };

    private handleChange = (event : ChangeEvent<HTMLInputElement>) : void => {
        this.setState({
            name: event.target.value,
            error: !event.target.value.trim(),
        });
    };

    private handleFieldBlur = async (event : FocusEvent<HTMLInputElement>) : Promise<void> => {
        const name = event.target.value.trim();

        if (!name) {
            event.preventDefault();
            return;
        }

        await this.props.updateBoardName(this.props.board.id, name);
        this.setState({editing: false});
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    updateBoardName: (boardId : string, name : string) => dispatch(updateBoardName(boardId, name)),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        EditableBoardName
    )
);
