import React, {Component, ReactNode} from 'react';
import {Board, BoardType} from '../../redux/boards/types';
import {switchType} from '../../redux/boards/actions';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../redux';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import Dialog from '../Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import UnderlinedButton from '../UnderlinedButton';

interface OwnProps
{
    board : Board;
}

interface DispatchProps
{
    switchType : (boardId : string, type : BoardType) => Promise<void>;
}

type Props = OwnProps & DispatchProps;

interface State
{
    showSwitchConfirmation : boolean;
}

class SwitchBoardType extends Component<Props, State>
{
    public readonly state = {
        showSwitchConfirmation: false,
    };

    public render() : ReactNode
    {
        const {board} = this.props;
        const {showSwitchConfirmation} = this.state;

        const canSwitchType = board._user.permissions.switchType;

        return (
            <React.Fragment>
                {canSwitchType && board.type === 'regular' && (
                    <UnderlinedButton onClick={this.handleSwitchClick}>
                        Switch to social
                    </UnderlinedButton>
                )}

                <Dialog
                    open={showSwitchConfirmation}
                    title="Confirm switch"
                    onClose={this.handleSwitchConfirmationClose}
                >
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to make this a social board? This will remove all specific users from
                            sharing, and make the board visible to everyone.
                        </DialogContentText>
                        <DialogActions>
                            <Button onClick={this.handleSwitchConfirmationClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleSwitchClick} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    private handleSwitchClick = async () : Promise<void> => {
        if (this.state.showSwitchConfirmation) {
            this.setState({showSwitchConfirmation: false});
            return this.props.switchType(this.props.board.id, 'social');
        }

        this.setState({
            showSwitchConfirmation: true,
        });
    };

    private handleSwitchConfirmationClose = () : void => {
        this.setState({showSwitchConfirmation: false});
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    switchType: (boardId : string, type : BoardType) => dispatch(
        switchType(boardId, type)
    ),
});

export default connect<null, DispatchProps, OwnProps, AppState>(
    null,
    mapDispatchToProps
)(
    SwitchBoardType
);
