import React, {Component, ReactNode} from 'react';
import {Redirect} from 'react-router';

class SignOutCallback extends Component
{
    public render() : ReactNode
    {
        return <Redirect to="/sign-in"/>;
    }
}

export default SignOutCallback;
