import {
    AppBar,
    createStyles,
    IconButton,
    Menu,
    MenuItem,
    Theme,
    Toolbar,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import {push as pushLocation} from 'connected-react-router';
import React, {Component, MouseEvent, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import artLabLogo from '../assets/art-lab.png';
import SearchBar from '../components/SearchBar';
import {AppState} from '../redux';
import {User} from '../redux/user/types';
import {hideNewBoardModal, showNewBoardModal} from '../redux/show-new-board/actions';
import UserAvatar from '../components/avatar/UserAvatar';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import FabContainer from './FabContainer';
import NewBoard from './NewBoard';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        top: -72,
        backgroundColor: 'white',
        zIndex: 1000,
    },
    appBarSpacer: {
        marginLeft: 100,
    },
    appBar: {
        position: 'relative',
        backgroundColor: 'white',
    },
    appBarHidden: {
        boxShadow: 'none',
    },
    logo: {
        alignSelf: 'stretch',
        height: '72px',
        marginLeft: -theme.spacing(3),
        '&:hover': {
            opacity: .5,
            transition: 'opacity .5s ease',
        },
        cursor: 'pointer',
    },
    menuButton: {
        textTransform: 'none',
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    searchBar: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    spacer: {
        flexGrow: 1,
    },
    content: {
        padding: theme.spacing(4, 8),
    },
    header: {
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(3),
        borderBottom: '1px solid #000',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    user : User;
}

interface StateProps
{
    newBoardDialogOpen : boolean;
}

interface DispatchProps
{
    pushLocation : (path : string) => void;
    showNewBoardModal : () => void;
    hideNewBoardModal : () => void;
}

type Props = StateProps & OwnProps & DispatchProps;

interface State
{
    accountMenuAnchorElement : HTMLElement | null;
    collapseAppBar : boolean;
}

class GlobalAppBar extends Component<Props, State>
{
    public readonly state = {
        accountMenuAnchorElement: null,
        collapseAppBar: false,
    };

    public render() : ReactNode
    {
        const {classes, user} = this.props;
        const {accountMenuAnchorElement, collapseAppBar} = this.state;

        return (
            <AppBar position="sticky" color="default" className={classes.root}>
                <div className={classes.appBarSpacer}>
                    <AppBar
                        position="static"
                        className={classNames(classes.appBar, collapseAppBar && classes.appBarHidden)}
                    >
                        <Toolbar>
                            <img
                                src={artLabLogo}
                                alt="Art Lab"
                                className={classes.logo}
                                onClick={this.handleNavigateToHome}
                            />
                            <div className={classes.spacer}/>

                            <Button
                                color="inherit"
                                onClick={this.props.showNewBoardModal}
                                className={classes.menuButton}
                                size="large"
                            >
                                New Board
                            </Button>
                            <Button
                                color="inherit"
                                onClick={this.handleNavigateToDashboard}
                                className={classes.menuButton}
                                size="large"
                            >
                                My Work
                            </Button>
                            <Button
                                color="inherit"
                                onClick={() => {
                                    window.open('https://kalisher.com/wp-content/uploads/2022/07/art-lab-help.pdf')
                                }}
                                className={classes.menuButton}
                                size="large"
                            >
                                Help
                            </Button>
                            <IconButton
                                aria-owns={accountMenuAnchorElement ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleAccountMenuOpen}
                            >
                                <UserAvatar user={user}/>
                            </IconButton>
                            <Menu
                                id="account-menu"
                                anchorEl={accountMenuAnchorElement}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(accountMenuAnchorElement)}
                                onClose={this.handleAccountMenuClose}
                            >
                                <MenuItem onClick={this.handleEditProfile}>Edit profile</MenuItem>
                                <MenuItem onClick={this.handleSignOut}>Sign out</MenuItem>
                            </Menu>
                        </Toolbar>
                    </AppBar>

                    <SearchBar className={classes.searchBar}/>
                </div>
                <FabContainer>
                    <NewBoard dialogOpen={this.props.newBoardDialogOpen}
                              handleNewBoardDialogClose={this.props.hideNewBoardModal}/>
                </FabContainer>
            </AppBar>
        );
    }

    public componentDidMount() : void
    {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    public componentWillUnmount() : void
    {
        window.removeEventListener('scroll', this.handleScroll);
    }

    private handleScroll = () : void => {
        const collapseAppBar = window.scrollY >= 72;

        if (collapseAppBar !== this.state.collapseAppBar) {
            this.setState({collapseAppBar});
        }
    };

    private handleAccountMenuOpen = (event : MouseEvent<HTMLElement>) => {
        this.setState({accountMenuAnchorElement: event.currentTarget});
    };

    private handleAccountMenuClose = () => {
        this.setState({accountMenuAnchorElement: null});
    };

    private handleEditProfile = () => {
        this.handleAccountMenuClose();
        this.props.pushLocation('/edit-profile');
    };

    private handleSignOut = () => {
        this.handleAccountMenuClose();
        this.props.pushLocation('/sign-out');
    };

    private handleNavigateToHome = () => {
        this.handleAccountMenuClose();
        this.props.pushLocation('/');
    };

    private handleNavigateToDashboard = () => {
        this.handleAccountMenuClose();
        this.props.pushLocation('/dashboard');
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    newBoardDialogOpen: state.showBoard.showNewBoard,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    pushLocation: (path : string) => dispatch(pushLocation(path)),
    showNewBoardModal: () => dispatch(showNewBoardModal()),
    hideNewBoardModal: () => dispatch(hideNewBoardModal()),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        GlobalAppBar
    )
);
