import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import CircularLoadingIndicator from './CircularLoadingIndicator';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        margin: theme.spacing(2, 0),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

type Props = OwnProps;

class InlineLoadingIndicator extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes} = this.props;

        return (
            <div className={classes.root}><CircularLoadingIndicator/></div>
        );
    }
}

export default withStyles(styles)(
    InlineLoadingIndicator
);
