import {createStyles, IconButton, Theme, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, MouseEvent, ReactNode} from 'react';
import {MinimumUser, User} from '../../redux/user/types';
import UserAvatar from './UserAvatar';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import {parsePhoneNumberFromString} from 'libphonenumber-js';

const styles = (theme : Theme) : StyleRules => createStyles({
    iconButton: {
        padding: 0,
    },
    popoverContent: {
        padding: theme.spacing(2),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    user : User | MinimumUser;
    size? : 'small' | 'medium' | 'large';
}

type Props = OwnProps;

interface State
{
    anchorElement : HTMLElement | null;
}

class UserAvatarInfoButton extends Component<Props, State>
{
    public readonly state = {
        anchorElement: null,
    };

    public render() : ReactNode
    {
        const {classes, user, size} = this.props;
        const {anchorElement} = this.state;

        const phoneNumber = user.profile.phoneNumber ? parsePhoneNumberFromString(user.profile.phoneNumber) : undefined;

        return (
            <React.Fragment>
                <IconButton onClick={this.handleClick} className={classes.iconButton}>
                    <UserAvatar user={user} size={size}/>
                </IconButton>
                <Popover
                    anchorEl={anchorElement}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={Boolean(anchorElement)}
                    onClose={this.handleClose}
                >
                    <div className={classes.popoverContent}>
                        <Typography variant="body1">
                            {user.profile.firstName} {user.profile.lastName}
                        </Typography>
                        {user.profile.company && (
                            <Typography variant="body1">
                                {user.profile.company}
                            </Typography>
                        )}
                        <Link href={`mailto:${user.emailAddress}`} underline="always">{user.emailAddress}</Link>
                        {phoneNumber && (
                            <Typography variant="body1">
                                {phoneNumber.formatInternational()}
                            </Typography>
                        )}
                    </div>
                </Popover>
            </React.Fragment>
        );
    }

    private handleClick = (event : MouseEvent<HTMLElement>) : void => {
        this.setState({anchorElement: event.currentTarget});
    };

    private handleClose = () : void => {
        this.setState({anchorElement: null});
    };
}

export default withStyles(styles)(UserAvatarInfoButton);
