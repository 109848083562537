import React, {Component, ReactNode} from 'react';
import FullPageLoadingIndicator from '../components/FullPageLoadingIndicator';
import {getAuth0Client} from '../utils/auth0';
import {absoluteUrl} from '../utils/uri';
import {AppState} from '../redux';
import {connect} from 'react-redux';

interface StateProps
{
    returnTo : string | null;
}

type Props = StateProps;

class SignIn extends Component<Props>
{
    public render() : ReactNode
    {
        return <FullPageLoadingIndicator/>;
    }

    public async componentDidMount() : Promise<void>
    {
        const auth0Client = await getAuth0Client();
        await auth0Client.loginWithRedirect({
            appState: {
                returnTo: this.props.returnTo,
            },
            redirect_uri: absoluteUrl('/sign-in/callback').href,
        });
    }
}

const mapStateToProps = (state : AppState) : StateProps => ({
    returnTo: new URLSearchParams(state.router.location.search).get('returnTo'),
});

export default connect<StateProps, null, null, AppState>(
    mapStateToProps
)(
    SignIn
);
