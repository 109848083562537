import {
    Button,
    Card,
    CardActions,
    CardContent,
    createStyles,
    Theme,
    Typography,
    withStyles,
    WithStyles,
} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import {push as pushLocation} from 'connected-react-router';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import FullPageLoadingIndicator from '../components/FullPageLoadingIndicator';
import {AppState} from '../redux';
import {handleSignInCallback} from '../redux/auth0/actions';
import {getAuth0Client} from '../utils/auth0';
import {absoluteUrl} from '../utils/uri';
import ResendVerificationDialog from '../components/ResendVerificationDialog';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        width: '100%',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface StateProps
{
    search : URLSearchParams;
}

interface DispatchProps
{
    handleSignInCallback : () => void;
    pushLocation : (path : string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State
{
    showResendDialog : boolean;
}

class SignInCallback extends Component<Props, State>
{
    public readonly state : State = {
        showResendDialog: false,
    };

    public render() : ReactNode
    {
        if (!this.props.search.has('error')) {
            return <FullPageLoadingIndicator/>;
        }

        const {classes, search} = this.props;
        const {showResendDialog} = this.state;
        let errorMessage = search.get('error_description');

        if (!errorMessage) {
            errorMessage = 'An unknown error occurred.';
        }

        if (errorMessage === '__email_verification__') {
            return (
                <div className={classes.root}>
                    <Card>
                        <CardContent>
                            <Typography variant="h2" gutterBottom={true}>Verify your account</Typography>
                            <Typography variant="body1">
                                A verification email has been sent to the address you provided us.
                                Please open that email and follow the link to get started!
                            </Typography>
                        </CardContent>
                        <CardActions>
                            <Button size="small" color="secondary" onClick={this.returnToSignIn}>
                                Log into another account
                            </Button>
                            <Button size="small" color="secondary" onClick={this.showResendDialog} disableFocusRipple>
                                Resend verification email
                            </Button>
                        </CardActions>
                    </Card>

                    <ResendVerificationDialog open={showResendDialog} onClose={this.hideResendDialog}/>
                </div>
            );
        }

        return (
            <div className={classes.root}>
                <Card>
                    <CardContent>
                        <Typography variant="h2" gutterBottom={true}>Sign in failed</Typography>
                        <Typography variant="body1">{errorMessage}</Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color="secondary" onClick={this.returnToSignIn}>
                            Log into another account
                        </Button>
                    </CardActions>
                </Card>
            </div>
        );
    }

    public componentDidMount() : void
    {
        if (!this.props.search.has('error')) {
            this.props.handleSignInCallback();
        }
    }

    private returnToSignIn = async () : Promise<void> => {
        const auth0Client = await getAuth0Client();
        auth0Client.logout({
            returnTo: absoluteUrl('/sign-out/callback').href,
        });
    };

    private showResendDialog = () : void => {
        this.setState({showResendDialog: true});
    };

    private hideResendDialog = () : void => {
        this.setState({showResendDialog: false});
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    search: new URLSearchParams(state.router.location.search),
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    handleSignInCallback: () => dispatch(handleSignInCallback()),
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        SignInCallback
    )
);
