import React, {Component, ReactNode} from 'react';
import {Route, Switch} from 'react-router-dom';
import Notifier from './components/Notifier';
import CompleteRegistration from './pages/CompleteRegistration';
import Portal from './pages/Portal';
import SignIn from './pages/SignIn';
import SignInCallback from './pages/SignInCallback';
import SignOut from './pages/SignOut';
import SignOutCallback from './pages/SignOutCallback';

class App extends Component
{
    public render() : ReactNode
    {
        return (
            <React.Fragment>
                <Notifier/>

                <Switch>
                    <Route path="/sign-in" exact component={SignIn}/>
                    <Route path="/sign-in/callback" exact component={SignInCallback}/>
                    <Route path="/sign-out" exact component={SignOut}/>
                    <Route path="/sign-out/callback" exact component={SignOutCallback}/>
                    <Route path="/complete-registration" exact component={CompleteRegistration}/>
                    <Route path="*" component={Portal}/>
                </Switch>
            </React.Fragment>
        );
    }
}

export default App;
