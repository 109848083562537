import React, {Component, ReactNode} from 'react';
import ReactResizeDetector from 'react-resize-detector';
import {Image} from '../../redux/image-search/types';
import ImageGridLayout, {DropPosition} from './ImageGridLayout';
import computeLayout, {LayoutImage} from './utils/computeLayout';

const DEFAULT_MARGIN = 40;
const DEFAULT_MAX_ROW_HEIGHT = 400;

interface OwnProps
{
    images : Image[];
    maxRowHeight? : number;
    margin? : number;
    includeLastPartialRow : boolean;
    stopAfterFirstRow? : boolean;
    onLoadStart? : () => void;
    onLoadEnd? : () => void;
    onImageClick? : (layoutImage : LayoutImage) => void;
    onImageDrop? : (dropPosition : DropPosition, layoutImage : LayoutImage) => void;
    allowSelection : boolean;
    allowDetails : boolean;
}

type Props = OwnProps;

class ImageGrid extends Component<Props>
{
    private onLoadEndFired = false;

    public render() : ReactNode
    {
        const {
            images,
            margin,
            maxRowHeight,
            includeLastPartialRow,
            stopAfterFirstRow,
            onLoadEnd,
            onImageClick,
            onImageDrop,
            allowSelection,
            allowDetails,
        } = this.props;

        return (
            <ReactResizeDetector handleWidth refreshMode="debounce" refreshRate={400}>
                {(resizeProps : {width : number | undefined}) => {
                    if (resizeProps.width === undefined) {
                        return <div/>;
                    }

                    const layout = computeLayout(images, {
                        margin: margin || DEFAULT_MARGIN,
                        maxRowHeight: maxRowHeight || DEFAULT_MAX_ROW_HEIGHT,
                        containerWidth: resizeProps.width,
                        includeLastPartialRow,
                        stopAfterFirstRow,
                    });

                    window.requestAnimationFrame(() => {
                        if (!this.onLoadEndFired && onLoadEnd) {
                            this.onLoadEndFired = true;
                            onLoadEnd();
                        }
                    });

                    return (
                        <ImageGridLayout
                            layout={layout}
                            onImageClick={onImageClick}
                            onImageDrop={onImageDrop}
                            allowSelection={allowSelection}
                            allowDetails={allowDetails}
                        />
                    );
                }}
            </ReactResizeDetector>
        );
    }

    public componentDidMount() : void
    {
        if (this.props.onLoadStart) {
            this.props.onLoadStart();
        }
    }
}

export default ImageGrid;
