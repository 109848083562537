import {push} from 'connected-react-router';
import {Action, Dispatch} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {apiEndpoint, apiFetch} from '../../utils/api';
import {AppState} from '../index';
import {enqueueSnackbar, hideProcessingOverlay, showProcessingOverlay} from '../notifier/actions';
import {
    ADD_MEMBER,
    AssignableRole,
    Board,
    BoardMember,
    BoardsActionTypes,
    BoardType,
    CHANGE_IMAGE_POSITION,
    FETCH_IMAGES_BEGIN,
    FETCH_IMAGES_END,
    REMOVE_CONSULTANT_REQUEST,
    REMOVE_IMAGES,
    REMOVE_MEMBER,
    REQUEST_CONSULTANT,
    SET_ACCESSIBLE_BOARDS,
    SET_CURRENT_BOARD,
    SET_SPEC_OPTIONS, Specs,
    SWITCH_BOARD_TYPE,
    SWITCH_READY_FOR_SPECS,
    UNSET_ACCESSIBLE_BOARDS,
    UNSET_CURRENT_BOARD,
    UPDATE_BOARD_ARCHIVE_STATUS,
    UPDATE_BOARD_DESCRIPTION,
    UPDATE_BOARD_NAME,
    UPDATE_MEMBER, UPDATE_SPECS,
} from './types';
import {resetQuickAddImage} from '../image-selection/actions';

export const loadAccessibleBoards = (
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: UNSET_ACCESSIBLE_BOARDS,
    });

    const url = new URL(`${apiEndpoint}/boards`);
    url.searchParams.set('archived', 'false');

    const boardsResult = await apiFetch(url.href);

    if (!boardsResult.ok) {
        dispatch(enqueueSnackbar('An error occurred while loading the boards.', {variant: 'error'}));
        return;
    }

    const data = await boardsResult.json();

    dispatch({
        type: SET_ACCESSIBLE_BOARDS,
        payload: {
            boards: data.items,
        },
    });
};

export const setCurrentBoard = (board : Board) : BoardsActionTypes => ({
    type: SET_CURRENT_BOARD,
    payload: {
        board,
    },
});

export const unsetCurrentBoard = () : BoardsActionTypes => ({
    type: UNSET_CURRENT_BOARD,
});

export const loadCurrentBoard = (
    boardId : string,
    reload = false
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    const {currentBoard} = getState().boards;

    if (reload || currentBoard.board === null || currentBoard.board.id !== boardId) {
        dispatch({
            type: UNSET_CURRENT_BOARD,
        });

        const boardResult = await apiFetch(`${apiEndpoint}/boards/${boardId}`);

        if (!boardResult.ok) {
            dispatch(enqueueSnackbar('An error occurred while loading the board.', {variant: 'error'}));
            return;
        }

        const board = await boardResult.json();

        dispatch(setCurrentBoard(board));
    }

    await loadImageResults(dispatch, boardId);
};

const loadImageResults = async (dispatch : Dispatch, boardId : string) => {
    dispatch({type: FETCH_IMAGES_BEGIN});

    const result = await apiFetch(`${apiEndpoint}/boards/${boardId}/images`);

    if (!result.ok) {
        dispatch(enqueueSnackbar('An error occurred while loading the images.', {variant: 'error'}));
        return;
    }

    const data = await result.json();

    dispatch({
        type: FETCH_IMAGES_END,
        payload: {
            boardImages: data.items,
        },
    });
};

export const createBoard = (
    name : string,
    imageIds : string[],
    isQuickAdd : boolean
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const boardCreateResult = await apiFetch(new URL(`${apiEndpoint}/boards`).href, {
        method: 'POST',
        body: JSON.stringify({name}),
    });

    if (!boardCreateResult.ok) {
        dispatch(enqueueSnackbar('An error occurred while creating the board.', {variant: 'error'}));
        return;
    }

    const board = await boardCreateResult.json();
    await dispatch(addImagesToBoard(board, imageIds, isQuickAdd));
};

export const createEmptyBoard = (
    name : string,
    description : string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const boardCreateResult = await apiFetch(new URL(`${apiEndpoint}/boards`).href, {
        method: 'POST',
        body: JSON.stringify({name}),
    });

    if (!boardCreateResult.ok) {
        dispatch(enqueueSnackbar('An error occurred while creating the board.', {variant: 'error'}));
        return;
    }

    const board = await boardCreateResult.json();

    const boardUpdateResult = await apiFetch(new URL(`${apiEndpoint}/boards/${board.id}`).href, {
        method: 'PATCH',
        body: JSON.stringify({description}),
    });

    if (!boardUpdateResult.ok) {
        dispatch(enqueueSnackbar('An error occurred while updating the board.', {variant: 'error'}));
        return;
    }

    dispatch(push(`/boards/${board.id}`));
};

export const addImagesToBoard = (
    board : Board,
    imageIds : string[],
    isQuickAdd : boolean
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const addImagesResult = await apiFetch(new URL(`${apiEndpoint}/boards/${board.id}/images/batch-add`).href, {
        method: 'PATCH',
        body: JSON.stringify(imageIds.map(imageId => ({id: imageId}))),
    });

    if (!addImagesResult.ok) {
        dispatch(enqueueSnackbar('An error occurred while adding the images.', {variant: 'error'}));
        return;
    }

    if (isQuickAdd) {
        dispatch(resetQuickAddImage());
        dispatch(enqueueSnackbar('Image has been added to board.', {variant: 'success'}));
        return;
    }

    dispatch(setCurrentBoard(board));
    dispatch(push(`/boards/${board.id}`));
    dispatch(enqueueSnackbar('Images have been added to board.', {variant: 'success'}));
};

export const removeImagesFromBoard = (
    boardId : string,
    imageIds : string[]
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const removeImagesResult = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}/images/batch-remove`).href, {
        method: 'PATCH',
        body: JSON.stringify(imageIds.map(imageId => ({id: imageId}))),
    });

    if (!removeImagesResult.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while removing the images.', {variant: 'error'}));
        return;
    }

    dispatch({type: REMOVE_IMAGES, payload: {boardId, imageIds}});
    dispatch(enqueueSnackbar('Images have been removed from board.', {variant: 'success'}));
    dispatch(hideProcessingOverlay());
};

export const updateBoardName = (
    boardId : string,
    name : string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const boardUpdateResult = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({name}),
    });

    if (!boardUpdateResult.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while updating the board.', {variant: 'error'}));
        return;
    }

    dispatch({type: UPDATE_BOARD_NAME, payload: {boardId, name}});
    dispatch(hideProcessingOverlay());
};

export const updateBoardDescription = (
    boardId : string,
    description : string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const boardUpdateResult = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({description}),
    });

    if (!boardUpdateResult.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while updating the board.', {variant: 'error'}));
        return;
    }

    dispatch({type: UPDATE_BOARD_DESCRIPTION, payload: {boardId, description}});
    dispatch(hideProcessingOverlay());
};

export const changeArchiveStatus = (
    boardId : string,
    archived : boolean,
    unshare = false
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const boardUpdateResult = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}/archive`).href, {
        method: 'PATCH',
        body: JSON.stringify({
            restore: !archived,
            unshare,
        }),
    });

    if (!boardUpdateResult.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while updating the board.', {variant: 'error'}));
        return;
    }

    dispatch({type: UPDATE_BOARD_ARCHIVE_STATUS, payload: {boardId, archived, unshare}});
    dispatch(hideProcessingOverlay());

    if (archived) {
        if (unshare) {
            dispatch(enqueueSnackbar('Board has been archived and unshared.', {variant: 'success'}));
        } else {
            dispatch(enqueueSnackbar('Board has been archived.', {variant: 'success'}));
        }
    } else {
        dispatch(enqueueSnackbar('Board has been restored from archive.', {variant: 'success'}));
    }
};

export const switchType = (
    boardId : string,
    type : BoardType
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const boardUpdateResult = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({type: type}),
    });

    if (!boardUpdateResult.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while updating the board.', {variant: 'error'}));
        return;
    }

    dispatch({type: SWITCH_BOARD_TYPE, payload: {boardId, type}});
    dispatch(hideProcessingOverlay());
    dispatch(enqueueSnackbar('Board type has been switched.', {variant: 'success'}));
};

export const addBoardMember = (
    boardId : string,
    emailAddress : string,
    role : AssignableRole
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(showProcessingOverlay());
    const result = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}/members`).href, {
        method: 'POST',
        body: JSON.stringify({
            emailAddress,
            role,
        }),
    });

    if (!result.ok) {
        dispatch(hideProcessingOverlay());

        switch (result.status) {
            case 409:
                dispatch(enqueueSnackbar('User is already a member of this board.', {variant: 'error'}));
                break;

            case 404:
                dispatch(enqueueSnackbar('User not found.', {variant: 'error'}));
                break;

            default:
                dispatch(enqueueSnackbar('An error occurred while adding the member.', {variant: 'error'}));
        }

        return;
    }

    const member : BoardMember = await result.json();

    dispatch({type: ADD_MEMBER, payload: {boardId, member}});
    dispatch(hideProcessingOverlay());
    dispatch(enqueueSnackbar('Member has been added.', {variant: 'success'}));

    if (member.user.id === getState().user.user!.id) {
        await dispatch(loadCurrentBoard(boardId, true));
    }
};

export const updateBoardMember = (
    boardId : string,
    userId : string,
    role : AssignableRole
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(showProcessingOverlay());
    const result = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}/members/${userId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({role}),
    });

    if (!result.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while updating the member.', {variant: 'error'}));
        return;
    }

    dispatch({type: UPDATE_MEMBER, payload: {boardId, userId, role}});
    dispatch(hideProcessingOverlay());
    dispatch(enqueueSnackbar('Member has been updated.', {variant: 'success'}));

    if (userId === getState().user.user!.id) {
        await dispatch(loadCurrentBoard(boardId, true));
    }
};

export const removeBoardMember = (
    boardId : string,
    userId : string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
    dispatch(showProcessingOverlay());
    const result = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}/members/${userId}`).href, {
        method: 'DELETE',
    });

    if (!result.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while removing the member.', {variant: 'error'}));
        return;
    }

    dispatch({type: REMOVE_MEMBER, payload: {boardId, userId}});
    dispatch(hideProcessingOverlay());
    dispatch(enqueueSnackbar('Member has been removed.', {variant: 'success'}));

    if (userId === getState().user.user!.id) {
        dispatch(unsetCurrentBoard());
        dispatch(push('/'));
    }
};

export const requestConsultant = (
    boardId : string
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const result = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({
            consultantRequested: true,
        }),
    });

    if (!result.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while requesting an art consultant.', {variant: 'error'}));
        return;
    }

    dispatch({type: REQUEST_CONSULTANT, payload: {boardId}});
    dispatch(hideProcessingOverlay());
    dispatch(enqueueSnackbar('Art consultant has been requested.', {variant: 'success'}));
};

export const removeConsultantRequest = (
    boardId : string
) : ThunkAction<Promise<boolean>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const result = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({
            consultantRequested: false,
        }),
    });

    if (!result.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while removing the request.', {variant: 'error'}));
        return false;
    }

    dispatch({type: REMOVE_CONSULTANT_REQUEST, payload: {boardId}});
    dispatch(hideProcessingOverlay());
    dispatch(enqueueSnackbar('Art consultant request has been removed.', {variant: 'success'}));
    return true;
};

export const changeImagePosition = (
    boardId : string,
    imageId : string,
    targetId : string,
    position : 'before' | 'after'
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    if (imageId === targetId) {
        return;
    }

    dispatch(showProcessingOverlay());

    const result = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}/images/resort`).href, {
        method: 'PATCH',
        body: JSON.stringify({
            imageId,
            targetId,
            position,
        }),
    });

    if (!result.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while resorting.', {variant: 'error'}));
        return;
    }

    dispatch({
        type: CHANGE_IMAGE_POSITION,
        payload: {
            boardId,
            imageId,
            targetId,
            position,
        },
    });
    dispatch(hideProcessingOverlay());
};

export const switchReadyForSpecs = (
    boardId : string,
    readyForSpecs : boolean
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch(showProcessingOverlay());
    const boardUpdateResult = await apiFetch(new URL(`${apiEndpoint}/boards/${boardId}`).href, {
        method: 'PATCH',
        body: JSON.stringify({readyForSpecs}),
    });

    if (!boardUpdateResult.ok) {
        dispatch(hideProcessingOverlay());
        dispatch(enqueueSnackbar('An error occurred while updating the board.', {variant: 'error'}));
        return;
    }

    dispatch({type: SWITCH_READY_FOR_SPECS, payload: {boardId, readyForSpecs}});
    dispatch(hideProcessingOverlay());

    if (readyForSpecs) {
        dispatch(enqueueSnackbar('Board is ready for specs.', {variant: 'success'}));
    } else {
        dispatch(enqueueSnackbar('Board is no longer ready for specs.', {variant: 'success'}));
    }
};

export const loadSpecOptions = () : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const response = await apiFetch(`${apiEndpoint}/boards/spec-options`);

    if (!response.ok) {
        dispatch(enqueueSnackbar('An error occurred while loading spec options.', {variant: 'error'}));
        return;
    }

    const data = await response.json();

    dispatch({
        type: SET_SPEC_OPTIONS,
        payload: {
            specOptions: data,
        },
    });
};

export const updateSpecs = (
    boardId : string,
    imageId : string,
    specs : Specs
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    dispatch({
        type: UPDATE_SPECS,
        payload: {
            boardId,
            imageId,
            specs,
        },
    });

    const response = await apiFetch(`${apiEndpoint}/boards/${boardId}/images/${imageId}/specs`, {
        method: 'PUT',
        body: JSON.stringify(specs),
    });

    if (!response.ok) {
        dispatch(enqueueSnackbar('An error occurred while updating the specs.', {variant: 'error'}));
        return;
    }
};
