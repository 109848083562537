import {Chip, MenuItem, Paper, TextField, Typography} from '@material-ui/core';
import {createStyles, StyleRules, Theme, WithStyles, withStyles} from '@material-ui/core/styles';
import {emphasize} from '@material-ui/core/styles/colorManipulator';
import {BaseTextFieldProps} from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import classNames from 'classnames';
import React, {Component, HTMLAttributes, ReactNode} from 'react';
import Select from 'react-select';
import {ValueContainerProps} from 'react-select/lib/components/containers';
import {ControlProps} from 'react-select/lib/components/Control';
import {MenuProps, NoticeProps} from 'react-select/lib/components/Menu';
import {MultiValueProps} from 'react-select/lib/components/MultiValue';
import {OptionProps} from 'react-select/lib/components/Option';
import {PlaceholderProps} from 'react-select/lib/components/Placeholder';
import {SingleValueProps} from 'react-select/lib/components/SingleValue';
import {OptionsType, ValueType} from 'react-select/lib/types';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        flexGrow: 1,
        height: 250,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'auto',
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
        padding: '14.5px 14px',
    },
    chip: {
        margin: `${theme.spacing(.5)}px ${theme.spacing(.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            .08
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(1) * 2}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 14,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 10000,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

interface OptionType
{
    label : string;
    value : string;
}

const NoOptionsMessage = (props : NoticeProps<OptionType>) => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.noOptionsMessage}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
);

type InputComponentProps = Pick<BaseTextFieldProps, 'inputRef'> & HTMLAttributes<HTMLDivElement>;

const inputComponent = ({inputRef, ...props} : InputComponentProps) => (
    <div ref={inputRef} {...props}/>
);

const Control = (props : ControlProps<OptionType>) => {
    const {children, innerProps, innerRef, selectProps : {classes, TextFieldProps}} = props;

    return (
        <TextField
            fullWidth
            variant="outlined"
            InputProps={{
                inputComponent,
                inputProps: {
                    className: classes.input,
                    ref: innerRef,
                    children,
                    ...innerProps,
                },
            }}
            {...TextFieldProps}
        />
    );
};

const Option = (props : OptionProps<OptionType>) => (
    <MenuItem
        buttonRef={props.innerRef}
        selected={props.isFocused}
        component="div"
        style={{
            fontWeight: props.isSelected ? 500 : 400,
        }}
        {...props.innerProps}
    >
        {props.children}
    </MenuItem>
);

const Placeholder = (props : PlaceholderProps<OptionType>) => (
    <Typography
        color="textSecondary"
        className={props.selectProps.classes.placeholder}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
);

const SingleValue = (props : SingleValueProps<OptionType>) => (
    <Typography
        className={props.selectProps.classes.singleValue}
        {...props.innerProps}
    >
        {props.children}
    </Typography>
);

const ValueContainer = (props : ValueContainerProps<OptionType>) => (
    <div className={props.selectProps.classes.valueContainer}>{props.children}</div>
);

const MultiValue = (props : MultiValueProps<OptionType>) => (
    <Chip
        tabIndex={-1}
        label={props.children}
        className={classNames(props.selectProps.classes.chip, {
            [props.selectProps.classes.chipFocused]: props.isFocused,
        })}
        onDelete={props.removeProps.onClick}
        deleteIcon={<CancelIcon {...props.removeProps}/>}
    />
);

const Menu = (props : MenuProps<OptionType>) => (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
        {props.children}
    </Paper>
);

const components = {
    Control,
    Menu,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
};

interface OwnProps extends WithStyles<typeof styles, true>
{
    options : OptionsType<OptionType>;
    onChange : (value : string | null) => void;
    value : string;
    label : string;
}

type Props = OwnProps;

class MuiReactSelect extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, theme, options, value, label, onChange, ...rest} = this.props;

        const selectStyles = {
            input: (base : any) => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };

        const reactSelectValue = options.find((option : OptionType) => option.value === value);

        return (
            <Select
                classes={classes}
                styles={selectStyles}
                options={options}
                components={components}
                TextFieldProps={{
                    label,
                    InputLabelProps: {
                        shrink: true,
                    },
                }}
                value={reactSelectValue}
                onChange={(value : ValueType<OptionType>) => onChange(value ? (value as OptionType).value : null)}
                isClearable
                {...rest}
            />
        );
    }
}

export default withStyles(styles, {withTheme: true})(
    MuiReactSelect
);
