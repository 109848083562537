import {createStyles, DialogTitle, Theme, Typography, WithStyles} from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, {Component, ReactNode} from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        margin: 0,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    open : boolean;
    onClose : () => void;
    title : string;
    fullWidth? : boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
}

type Props = OwnProps;

class Dialog extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, open, onClose, title, fullWidth, maxWidth} = this.props;

        return (
            <MuiDialog open={open} onClose={onClose} fullWidth={fullWidth} maxWidth={maxWidth}>
                <DialogTitle disableTypography className={classes.root}>
                    <Typography variant="h3">
                        {title}
                    </Typography>
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                {this.props.children}
            </MuiDialog>
        );
    }
}

export default withStyles(styles)(Dialog);
