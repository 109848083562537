import {createStyles, Fab, Theme, WithStyles} from '@material-ui/core';
import withStyles, {StyleRules} from '@material-ui/core/styles/withStyles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React, {Component, ReactNode} from 'react';

const styles = (theme : Theme) : StyleRules => createStyles({
    fabs: {
        display: 'flex',
        flexDirection: 'column',
        position: 'fixed',
        width: '40px',
        direction: 'rtl',
        right: theme.spacing(2),
        bottom: theme.spacing(2),
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },
    top: {
        border: 'none',
        boxShadow: 'none',
        '& > .MuiFab-label': {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                lineHeight: 1,
            },
        },
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

type Props = OwnProps;

interface State
{
    showScrollToTop : boolean;
}

class FabContainer extends Component<Props, State>
{
    public readonly state = {
        showScrollToTop: false,
    };

    public render() : ReactNode
    {
        const {classes, children} = this.props;
        const {showScrollToTop} = this.state;

        return (
            <div className={classes.fabs}>
                {children}

                {showScrollToTop && (
                    <Fab size="medium" onClick={this.handleScrollToTop} className={classes.top}>
                        <div>Top</div>
                        <KeyboardArrowUpIcon/>
                    </Fab>
                )}
            </div>
        );
    }

    public componentDidMount() : void
    {
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    }

    public componentWillUnmount() : void
    {
        window.removeEventListener('scroll', this.handleScroll);
    }

    private handleScroll = () : void => {
        const showScrollToTop = window.scrollY > 0;

        if (showScrollToTop !== this.state.showScrollToTop) {
            this.setState({showScrollToTop});
        }
    };

    private handleScrollToTop = () : void => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };
}

export default withStyles(styles)(FabContainer);
