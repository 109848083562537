import {createStyles, Theme, WithStyles} from '@material-ui/core';
import withStyles, {StyleRules} from '@material-ui/core/styles/withStyles';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../redux';
import {Board, BoardImage} from '../redux/boards/types';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {push as pushLocation} from 'connected-react-router';
import {Image} from '../redux/image-search/types';
import {apiEndpoint, apiFetch} from '../utils/api';
import InlineLoadingIndicator from './InlineLoadingIndicator';
import CroppedTile from './recommendedBoard/CroppedTile';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        position: 'relative',
    },
    title: {
        fontFamily: 'AvenirNextLTW01-Bold, sans-serif',
        fontSize: 20,
        position: 'absolute',
        left: theme.spacing(2),
        bottom: theme.spacing(4),
        textShadow: '0 0 5px #fff',
    },
    container: {
        position: 'relative',
        height: '45vw',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
}

interface DispatchProps
{
    pushLocation : (path : string) => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    images : Image[] | null;
}

class RecommendedBoard extends Component<Props, State>
{
    public readonly state : State = {
        images: null,
    };

    public render() : ReactNode
    {
        const {classes} = this.props;
        const {images} = this.state;

        return (
            <div className={classes.root} onClick={this.handleClick}>
                <div className={classes.container}>
                    {!images ? <InlineLoadingIndicator/> : (images.length < 7 ? null : RecommendedBoard.getRandomLayout(images))}
                </div>
                <div className={classes.title}>Art We Love Right Now +</div>
            </div>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        await this.loadImages();
    }

    public async componentDidUpdate(prevProps : Readonly<Props>) : Promise<void>
    {
        if (prevProps.board.id !== this.props.board.id) {
            this.setState({images: null});
            await this.loadImages();
        }
    }

    private static getRandomLayout(images : Image[]) : ReactNode
    {
        const index = Math.floor(Math.random() * 4);

        switch (index) {
            case 1:
                return (
                    <React.Fragment>
                        <CroppedTile image={images[0]} width={21} height={36.4} x={18.3} y={25.2} rotation={-30}/>
                        <CroppedTile image={images[1]} width={33.5} height={21.8} x={43.9} y={9.8} rotation={-15}/>
                        <CroppedTile image={images[2]} width={23.4} height={27.9} x={86} y={1.5} rotation={-10}/>
                        <CroppedTile image={images[3]} width={28.3} height={28.3} x={46} y={47.1} round/>
                        <CroppedTile image={images[4]} width={25.7} height={28.2} x={74.9} y={31.3} rotation={9}/>
                        <CroppedTile image={images[5]} width={30.5} height={21.8} x={17.5} y={65} rotation={15}/>
                        <CroppedTile image={images[6]} width={32.5} height={35.8} x={79} y={68.3} rotation={-15}/>
                    </React.Fragment>
                );

            case 2:
                return (
                    <React.Fragment>
                        <CroppedTile image={images[0]} width={31.3} height={31.3} x={15.7} y={3.6} round/>
                        <CroppedTile image={images[1]} width={25.7} height={28.2} x={67.5} y={3} rotation={-37}/>
                        <CroppedTile image={images[2]} width={32.5} height={35.8} x={43.1} y={32} rotation={6}/>
                        <CroppedTile image={images[3]} width={23.4} height={23.5} x={86.1} y={26.6} rotation={-10}/>
                        <CroppedTile image={images[4]} width={33.5} height={21.8} x={18.9} y={63.9} rotation={-15}/>
                        <CroppedTile image={images[5]} width={21} height={36.4} x={67.2} y={56.4} rotation={32}/>
                        <CroppedTile image={images[6]} width={29.9} height={29.9} x={85.5} y={75.1} round/>
                    </React.Fragment>
                );

            case 3:
                return (
                    <React.Fragment>
                        <CroppedTile image={images[0]} width={31.2} height={31.2} x={15.7} y={18.3} round/>
                        <CroppedTile image={images[1]} width={32.5} height={35.8} x={49.8} y={8.3} rotation={6}/>
                        <CroppedTile image={images[2]} width={27.8} height={21.8} x={82.9} y={20.1} rotation={24}/>
                        <CroppedTile image={images[3]} width={25.6} height={35.5} x={20.4} y={56.7} rotation={32}/>
                        <CroppedTile image={images[4]} width={23.3} height={23.5} x={58.6} y={43.8} rotation={-33}/>
                        <CroppedTile image={images[5]} width={25.7} height={28.3} x={87.6} y={51.8}/>
                        <CroppedTile image={images[6]} width={29.9} height={29.9} x={61.3} y={73.7} round/>
                    </React.Fragment>
                );

            default:
                return (
                    <React.Fragment>
                        <CroppedTile image={images[0]} width={25.7} height={28.2} x={15} y={23} rotation={9}/>
                        <CroppedTile image={images[1]} width={33.5} height={21.8} x={47.5} y={11} rotation={-15}/>
                        <CroppedTile image={images[2]} width={29.9} height={29.9} x={84.5} y={19.5} round/>
                        <CroppedTile image={images[3]} width={32.5} height={35.8} x={25} y={59.5} rotation={-15}/>
                        <CroppedTile image={images[4]} width={21} height={36.4} x={55.5} y={42} rotation={10}/>
                        <CroppedTile image={images[5]} width={23.4} height={27.9} x={83} y={49.4} rotation={-10}/>
                        <CroppedTile image={images[6]} width={30.5} height={21.8} x={65.5} y={75.5} rotation={15}/>
                    </React.Fragment>
                );
        }
    }

    private async loadImages()
    {
        const url = new URL(`${apiEndpoint}/boards/${this.props.board.id}/images`);
        const response = await apiFetch(url.href);

        if (!response.ok) {
            return;
        }

        const data = await response.json();
        const images = (data.items as BoardImage[]).map(boardImage => boardImage.image);
        let assignedImages : Image[] = [];

        if (images.length === 0) {
            this.setState({images: []});
            return;
        }

        while (assignedImages.length < 7) {
            assignedImages = assignedImages.concat(images.sort(() => Math.random() - 0.5));
        }

        this.setState({images: assignedImages.slice(0, 7)});
    }

    private handleClick = () => {
        this.props.pushLocation(`/boards/${this.props.board.id}`);
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        RecommendedBoard
    )
);
