import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {MinimumUser, User} from '../../redux/user/types';
import Avatar from '@material-ui/core/Avatar';

const styles = (theme : Theme) : StyleRules => createStyles({
    default: {
        width: 48,
        height: 48,
    },
    medium: {
        width: 40,
        height: 40,
        fontSize: '1rem',
    },
    small: {
        width: 24,
        height: 24,
        fontSize: '.5rem',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    user : User | MinimumUser;
    size? : 'small' | 'medium' | 'large';
}

type Props = OwnProps;

class UserAvatar extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, user, size} = this.props;

        let rootClass = classes.default;

        if (size === 'medium') {
            rootClass = classes.medium;
        } else if (size === 'small') {
            rootClass = classes.small;
        }

        return (
            <Avatar className={rootClass}>
                {
                    user.profile.firstName.substring(0, 1).toLocaleUpperCase()
                }{
                    user.profile.lastName.substring(0, 1).toLocaleUpperCase()
                }
            </Avatar>
        );
    }
}

export default withStyles(styles)(UserAvatar);
