import {Board, BoardMember} from '../redux/boards/types';

const sortMap : {[key : string] : number} = {
    'owner': 0,
    'collaborator' : 1,
    'viewer': 2,
};

const compareBoardMembers = (a : BoardMember, b : BoardMember) : number => {
    const base = sortMap[a.role] - sortMap[b.role];

    if (base !== 0) {
        return base;
    }

    return `${a.user.profile.firstName} ${a.user.profile.lastName}`.localeCompare(
        `${b.user.profile.firstName} ${b.user.profile.lastName}`
    );
};

export const sortBoardMembers = (boardMembers : BoardMember[]) : BoardMember[] => {
    return boardMembers.slice().sort(compareBoardMembers);
};

export const hasConsultant = (board : Board) : boolean => {
    return board._members.filter(member => member.user.emailAddress.endsWith('@kalisher.com')).length > 0;
};
