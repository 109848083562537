export interface ImageSelectionState
{
    selectedImageIds : string[];
    quickAddImageId : string | null;
}

export const SELECT_IMAGE = 'IMAGE_SELECTION_SELECT_IMAGE';
export const DESELECT_IMAGE = 'IMAGE_SELECTION_DESELECT_IMAGE';
export const QUICK_ADD_IMAGE = 'IMAGE_SELECTION_QUICK_ADD_IMAGE';
export const RESET_QUICK_ADD_IMAGE = 'IMAGE_SELECTION_RESET_QUICK_ADD_IMAGE';

interface SelectImageAction
{
    type : typeof SELECT_IMAGE;
    payload : {
        imageId : string;
    };
}

interface DeselectImageAction
{
    type : typeof DESELECT_IMAGE;
    payload : {
        imageId : string;
    };
}

interface QuickAddImageAction
{
    type : typeof QUICK_ADD_IMAGE;
    payload : {
        imageId : string;
    };
}

interface ResetQuickAddImageAction
{
    type : typeof RESET_QUICK_ADD_IMAGE;
}

export type ImageSelectionActionTypes =
    SelectImageAction
    | DeselectImageAction
    | QuickAddImageAction
    | ResetQuickAddImageAction;
