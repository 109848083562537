import React, {Component, ReactNode} from 'react';
import Tooltip from '@material-ui/core/Tooltip';

interface OwnProps
{
    title : string;
    open : boolean;
    children: React.ReactElement;
}

type Props = OwnProps;

type State = {
    visible : boolean;
};

class FlashingTooltip extends Component<Props, State>
{
    public state : State = {
        visible: false,
    };
    private timeout : number | null = null;

    public render() : ReactNode
    {
        const {title, children} = this.props;
        const {visible} = this.state;

        return (
            <Tooltip title={title} open={visible} children={children} placement="top" className="shine"/>
        );
    }

    public componentDidMount() : void
    {
        if (this.props.open) {
            this.setState({visible: true});
            this.scheduleFadeOut();
        }
    }

    public componentDidUpdate(prevProps : Readonly<Props>) : void
    {
        if (this.props.open && !prevProps.open && this.timeout === null) {
            this.setState({visible: true});
            this.scheduleFadeOut();
            return;
        }

        if (!this.props.open && prevProps.open && this.timeout !== null) {
            window.clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

    private scheduleFadeOut() : void
    {
        this.timeout = window.setTimeout(() => {
            this.setState({visible: false});
            this.scheduleFadeIn();
        }, 5000);
    };

    private scheduleFadeIn() : void
    {
        this.timeout = window.setTimeout(() => {
            this.setState({visible: true});
            this.scheduleFadeOut();
        }, 500);
    };
}

export default FlashingTooltip;
