import {TextField} from '@material-ui/core';
import React, {FC} from 'react';
import {FieldRenderProps} from 'react-final-form';
import Checkbox from './Checkbox';
import MuiReactSelect from './MuiReactSelect';
import Radio from '@material-ui/core/Radio';

export const FinalFormTextField : FC<FieldRenderProps<any, HTMLElement>> = ({
    input: {checked, name, onChange, value, ...restInput},
    meta,
    ...rest
}) => {
    const showError = ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched;

    return (
        <TextField
            fullWidth
            variant="outlined"
            {...rest}
            name={name}
            helperText={showError ? meta.error || meta.submitError : undefined}
            error={showError}
            inputProps={restInput}
            onChange={onChange}
            value={value}
        />
    );
};

export const FinalFormCheckbox : FC<FieldRenderProps<string, HTMLElement>> = ({
    input: {checked, name, onChange, ...restInput},
    meta,
    ...rest
}) => (
    <Checkbox
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        checked={checked}
    />
);

export const FinalFormRadio : FC<FieldRenderProps<string, HTMLElement>> = ({
    input: {checked, value, name, onChange, ...restInput},
    meta,
    ...rest
}) => (
    <Radio
        {...rest}
        name={name}
        inputProps={restInput}
        onChange={onChange}
        checked={checked}
        value={value}
    />
);

export const FinalFormReactSelect : FC<FieldRenderProps<string, HTMLElement>> = ({
    input: {checked, value, onChange, ...restInput},
    meta,
    ...rest
}) => {
    return (
        <MuiReactSelect
            options={[]}
            label=""
            {...restInput}
            {...rest}
            onChange={onChange}
            value={value}
        />
    );
};
