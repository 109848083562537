import {createStyles, Theme, Typography, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {ChangeEvent, Component, FocusEvent, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import {updateBoardDescription} from '../../redux/boards/actions';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../redux';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import classNames from 'classnames';
import UnderlinedButton from '../UnderlinedButton';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    description: {
        whiteSpace: 'pre-wrap',
    },
    empty: {
        opacity: .5,
    },
    withEdit: {
        marginRight: theme.spacing(2),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
    className? : string;
}

interface DispatchProps
{
    updateBoardDescription : (boardId : string, description : string) => Promise<void>;
}

type Props = OwnProps & DispatchProps;

interface State
{
    editing : boolean;
    description : string;
    error : boolean;
}

class EditableBoardDescription extends Component<Props, State>
{
    public readonly state = {
        editing : false,
        description : '',
        error: false,
    };

    public render() : ReactNode
    {
        const {classes, className, board} = this.props;
        const {editing, description, error} = this.state;

        const canEdit = board._user.permissions.updateBoard;

        if (!canEdit && !description) {
            return null;
        }

        return (
            <div className={classNames(classes.root, className)}>
                {!editing && (
                    (!canEdit ? (
                        <Typography variant="body1" className={classes.description}>{board.description}</Typography>
                    ) : (
                        (board.description ? (
                            <Typography
                                variant="body1"
                                className={classNames(classes.description, classes.withEdit)}
                            >
                                {board.description}
                            </Typography>
                        ) : (
                            <Typography
                                variant="body1"
                                className={classNames(classes.description, classes.empty, classes.withEdit)}
                            >
                                No description
                            </Typography>
                        ))
                    ))
                )}

                {editing && (
                    <TextField
                        variant="standard"
                        onBlur={this.handleFieldBlur}
                        onChange={this.handleChange}
                        autoFocus
                        fullWidth
                        multiline
                        value={description}
                        error={error}
                    />
                )}

                {(!editing && canEdit) && (
                    <UnderlinedButton onClick={this.handleEditClick}>
                        Edit
                    </UnderlinedButton>
                )}
            </div>
        );
    }

    private handleEditClick = () : void => {
        this.setState({editing: true, description: this.props.board.description});
    };

    private handleChange = (event : ChangeEvent<HTMLTextAreaElement>) : void => {
        this.setState({
            description: event.target.value,
            error: !event.target.value.trim(),
        });
    };

    private handleFieldBlur = async (event : FocusEvent<HTMLInputElement>) : Promise<void> => {
        const description = event.target.value.trim();

        if (!description) {
            event.preventDefault();
            return;
        }

        await this.props.updateBoardDescription(this.props.board.id, description);
        this.setState({editing: false});
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    updateBoardDescription: (boardId : string, description : string) => dispatch(
        updateBoardDescription(boardId, description)
    ),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        EditableBoardDescription
    )
);
