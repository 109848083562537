import {OptionsObject} from 'notistack';
import * as React from 'react';
import {CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, Key, NotifierActionTypes, OVERLAY_PROCESSING, REMOVE_SNACKBAR} from './types';

export const enqueueSnackbar = (
    message : string | React.ReactNode,
    options? : OptionsObject
) : NotifierActionTypes => {
    const key = options && options.key;

    return {
        type: ENQUEUE_SNACKBAR,
        payload: {
            notification: {
                message,
                options,
                key: key || new Date().getTime() + Math.random(),
            },
        },
    };
};

export const closeSnackbar = (key? : Key) : NotifierActionTypes => ({
    type: CLOSE_SNACKBAR,
    payload: {
        dismissAll: !key,
        key,
    },
});

export const removeSnackbar = (key : Key) : NotifierActionTypes => ({
    type: REMOVE_SNACKBAR,
    payload: {
        key,
    },
});

export const showProcessingOverlay = () : NotifierActionTypes => ({
    type: OVERLAY_PROCESSING,
    payload: {
        show: true,
    },
});

export const hideProcessingOverlay = () : NotifierActionTypes => ({
    type: OVERLAY_PROCESSING,
    payload: {
        show: false,
    },
});
