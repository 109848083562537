import {
    DESELECT_IMAGE,
    ImageSelectionActionTypes,
    ImageSelectionState,
    QUICK_ADD_IMAGE,
    RESET_QUICK_ADD_IMAGE,
    SELECT_IMAGE,
} from './types';

const initialState : ImageSelectionState = {
    selectedImageIds: [],
    quickAddImageId: null,
};

export const imageSelectionReducer = (
    state = initialState,
    action : ImageSelectionActionTypes
) : ImageSelectionState => {
    switch (action.type) {
        case SELECT_IMAGE:
            if (state.selectedImageIds.indexOf(action.payload.imageId) !== -1) {
                return state;
            }

            return {
                ...state,
                selectedImageIds: [
                    ...state.selectedImageIds,
                    action.payload.imageId,
                ],
            };

        case DESELECT_IMAGE:
            return {
                ...state,
                selectedImageIds: state.selectedImageIds.filter(imageId => imageId !== action.payload.imageId),
            };

        case QUICK_ADD_IMAGE:
            return {
                ...state,
                quickAddImageId: action.payload.imageId,
            };

        case RESET_QUICK_ADD_IMAGE:
            return {
                ...state,
                selectedImageIds: [],
                quickAddImageId: null,
            };

        default:
            return state;
    }
};
