import {push} from 'connected-react-router';
import {Action} from 'redux';
import {ThunkAction} from 'redux-thunk';
import {getAuth0Client} from '../../utils/auth0';
import {AppState} from '../index';
import {Auth0ActionTypes, Auth0User, SET_USER, UNSET_USER} from './types';

const setUser = (user : Auth0User) : Auth0ActionTypes => {
    return {
        type: SET_USER,
        payload: {user},
    };
};

const unsetUser = () : Auth0ActionTypes => {
    return {
        type: UNSET_USER,
    };
};

export const signIn = () : ThunkAction<void, AppState, null, Action<string>> => (dispatch, getState) => {
    const {location} = getState().router;
    const returnTo = `${location.pathname}${location.search}${location.hash}`;

    dispatch(unsetUser());
    dispatch(push(`/sign-in?${new URLSearchParams([['returnTo', returnTo]]).toString()}`));
};

export const handleSignInCallback = (
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const auth0Client = await getAuth0Client();
    let result : RedirectLoginResult;

    try {
        result = await auth0Client.handleRedirectCallback();
    } catch (e) {
        console.debug(`Redirect callback could not be handled, reason: ${e.message}`);
        dispatch(signIn());
        return;
    }

    const user = await auth0Client.getUser();
    dispatch(setUser(createUserFromAuth0(user)));

    if (result.appState && result.appState.returnTo) {
        dispatch(push(result.appState.returnTo));
        return;
    }

    dispatch(push('/'));
};

export const checkAuthentication = (
) : ThunkAction<Promise<void>, AppState, null, Action<string>> => async dispatch => {
    const auth0Client = await getAuth0Client();

    if (!await auth0Client.isAuthenticated()) {
        dispatch(signIn());
        return;
    }

    const user = await auth0Client.getUser();
    dispatch(setUser(createUserFromAuth0(user)));
};

const createUserFromAuth0 = (user : any) : Auth0User => {
    return {
        id: user.sub,
        emailAddress: user.email,
        role: user['https://kalisher.com/user'].role,
    };
};
