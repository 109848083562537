export interface UserProfile
{
    firstName : string;
    lastName : string;
    title : string;
    company : string;
    addressLineOne : string;
    addressLineTwo : string;
    city : string;
    state : string;
    postalCode : string;
    countryCode : string;
    phoneNumber : string;
}

export interface User
{
    id : string;
    createdAt : string;
    auth0Id : string;
    emailAddress : string;
    profile : UserProfile;
}

export interface MinimumUser
{
    id : string;
    emailAddress : string;
    profile : {
        firstName : string;
        lastName : string;
        company : string;
        phoneNumber : string;
    };
}

export interface UserState
{
    user : User | null;
}

export const SET_USER = 'USER_SET_USER';
export const UNSET_USER = 'USER_UNSET_USER';
export const UPDATE_PROFILE = 'USER_UPDATE_PROFILE';

interface SetUserAction
{
    type : typeof SET_USER;
    payload : {
        user : User;
    };
}

interface UnsetUserAction
{
    type : typeof UNSET_USER;
}

interface UpdateProfileAction
{
    type : typeof UPDATE_PROFILE;
    payload : {
        profile : UserProfile;
    };
}

export type UserActionTypes = SetUserAction | UnsetUserAction | UpdateProfileAction;
