import {createStyles, Paper, Theme, Typography, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import {push as pushLocation} from 'connected-react-router';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import UserProfileForm from '../components/forms/UserProfileForm';
import FullPageLoadingIndicator from '../components/FullPageLoadingIndicator';
import {AppState} from '../redux';
import {checkAuthentication} from '../redux/auth0/actions';
import {updateProfile} from '../redux/user/actions';
import {User, UserProfile} from '../redux/user/types';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        margin: theme.spacing(5),
        padding: theme.spacing(3),
    },
    headline: {
        marginBottom: theme.spacing(3),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface DispatchProps
{
    checkAuthentication : () => void;
    updateProfile : (profile : UserProfile) => Promise<void>;
    pushLocation : (path : string) => void;
}

interface StateProps
{
    authenticated : boolean;
    user : User | null;
}

type Props = OwnProps & DispatchProps & StateProps;

class CompleteRegistration extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, authenticated, user} = this.props;

        if (!authenticated) {
            return <FullPageLoadingIndicator/>;
        }

        if (user) {
            return <Redirect to="/"/>;
        }

        return (
            <Paper className={classes.root}>
                <Typography variant="h2" className={classes.headline}>Complete your registration</Typography>
                <UserProfileForm handleSubmit={this.handleSubmit}/>
            </Paper>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        const {authenticated, checkAuthentication} = this.props;

        if (authenticated) {
            return;
        }

        checkAuthentication();
    }

    private handleSubmit = async (profile : UserProfile) => {
        await this.props.updateProfile(profile);
        this.props.pushLocation('/');
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    authenticated: state.auth0.authenticated,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    checkAuthentication: () => dispatch(checkAuthentication()),
    updateProfile: (profile : UserProfile) => dispatch(updateProfile(profile)),
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        CompleteRegistration
    )
);
