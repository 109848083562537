import {createStyles, Theme, Typography, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import {push as pushLocation} from 'connected-react-router';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import UserProfileForm from '../../components/forms/UserProfileForm';
import {AppState} from '../../redux';
import {updateProfile} from '../../redux/user/actions';
import {User, UserProfile} from '../../redux/user/types';

const styles = (theme : Theme) : StyleRules => createStyles({
    headline: {
        marginBottom: theme.spacing(3),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface DispatchProps
{
    updateProfile : (profile : UserProfile) => Promise<void>;
    pushLocation : (path : string) => void;
}

interface StateProps
{
    user : User;
}

type Props = OwnProps & DispatchProps & StateProps;

class EditProfile extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, user} = this.props;

        return (
            <React.Fragment>
                <Typography variant="h2" className={classes.headline}>Edit profile</Typography>
                <UserProfileForm handleSubmit={this.handleSubmit} userProfile={user.profile}/>
            </React.Fragment>
        );
    }

    private handleSubmit = async (profile : UserProfile) => {
        await this.props.updateProfile(profile);
        this.props.pushLocation('/');
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    user: state.user.user!,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    updateProfile: (profile : UserProfile) => dispatch(updateProfile(profile)),
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        EditProfile
    )
);
