import {createStyles, Theme, Typography, WithStyles, withStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import AddToBoard from '../../components/AddToBoard';
import GuideList from '../../components/GuideList';
import ImageGrid from '../../components/imageGrid/ImageGrid';
import {AppState} from '../../redux';
import {addGuide, loadNextResults, performSearch} from '../../redux/image-search/actions';
import {ImageSearchImages, ImageSearchMode, ImageSearchOrder, ImageSearchType} from '../../redux/image-search/types';
import {unsetCurrentBoard} from '../../redux/boards/actions';
import InlineLoadingIndicator from '../../components/InlineLoadingIndicator';
import WindowInfiniteScroll from '../../components/WindowInfiniteScroll';
import FabContainer from '../../components/FabContainer';

const styles = (theme : Theme) : StyleRules => createStyles({
    guideList: {
        marginBottom: theme.spacing(4),
    },
    headline: {
        marginBottom: 20,
    },
    secondaryResults: {
        marginTop: theme.spacing(4),
        borderTop: '1px solid #000',
        paddingTop: theme.spacing(4),
    },
    tertiaryResults: {
        marginTop: theme.spacing(4),
        borderTop: '1px solid #000',
        paddingTop: theme.spacing(4),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface StateProps
{
    query : string;
    type : ImageSearchType;
    order : ImageSearchOrder;
    mode : ImageSearchMode;
    images : ImageSearchImages;
    guides : string[];
    loading : boolean;
    hasNextResults : boolean;
    search : string;
}

interface DispatchProps
{
    unsetCurrentBoard : () => void;
    loadNextResults : () => void;
    addGuide : (guide : string) => void;
    performSearch : (query : string, type : ImageSearchType, order : ImageSearchOrder) => void;
}

type Props = StateProps & DispatchProps & OwnProps;

interface State
{
    imageGridLoading : boolean;
}

class ImageSearch extends Component<Props, State>
{
    public readonly state : State = {
        imageGridLoading: false,
    };

    public render() : ReactNode
    {
        const {classes, images, guides, mode, loading, hasNextResults, loadNextResults} = this.props;
        const {imageGridLoading} = this.state;

        return (
            <React.Fragment>
                {images[ImageSearchMode.Primary].length > 0 && (
                    <div>
                        <GuideList
                            guides={guides}
                            className={classes.guideList}
                            onGuideSelect={this.handleGuideSelect}
                        />
                        <ImageGrid
                            images={images[ImageSearchMode.Primary]}
                            includeLastPartialRow={!hasNextResults || mode !== ImageSearchMode.Primary}
                            onLoadStart={this.handleImageGridLoadStart}
                            onLoadEnd={this.handleImageGridLoadEnd}
                            allowSelection={true}
                            allowDetails={true}
                        />
                    </div>
                )}

                {images[ImageSearchMode.Primary].length === 0 && (
                    !loading
                    || images[ImageSearchMode.Secondary].length > 0
                    || images[ImageSearchMode.Tertiary].length > 0
                ) && (
                    <div>
                        <Typography variant="h2">Your search did not yield any results</Typography>
                    </div>
                )}

                {images[ImageSearchMode.Secondary].length > 0 && (
                    <div className={classes.secondaryResults}>
                        <Typography variant="h2" className={classes.headline}>More like this</Typography>
                        <ImageGrid
                            images={images[ImageSearchMode.Secondary]}
                            includeLastPartialRow={!hasNextResults || mode !== ImageSearchMode.Secondary}
                            onLoadStart={this.handleImageGridLoadStart}
                            onLoadEnd={this.handleImageGridLoadEnd}
                            allowSelection={true}
                            allowDetails={true}
                        />
                    </div>
                )}

                {images[ImageSearchMode.Tertiary].length > 0 && (
                    <div className={classes.tertiaryResults}>
                        <Typography variant="h2" className={classes.headline}>You might also like</Typography>
                        <ImageGrid
                            images={images[ImageSearchMode.Tertiary]}
                            includeLastPartialRow={!hasNextResults}
                            onLoadStart={this.handleImageGridLoadStart}
                            onLoadEnd={this.handleImageGridLoadEnd}
                            allowSelection={true}
                            allowDetails={true}
                        />
                    </div>
                )}

                {loading && <InlineLoadingIndicator/>}

                <FabContainer>
                    <AddToBoard/>
                </FabContainer>

                <WindowInfiniteScroll
                    loading={loading || imageGridLoading}
                    hasNextResults={hasNextResults}
                    loadNextResults={loadNextResults}
                />
            </React.Fragment>
        );
    }

    public componentDidMount() : void
    {
        this.props.unsetCurrentBoard();
        this.checkLocation();
    }

    public componentDidUpdate() : void
    {
        this.checkLocation();
    }

    private checkLocation = () : void => {
        const searchParams = new URLSearchParams(this.props.search);
        const locationQuery = searchParams.get('query');
        const locationType = (searchParams.get('type') || 'all') as ImageSearchType;
        const locationOrder = (searchParams.get('order') || 'random') as ImageSearchOrder;

        if (locationQuery === null || locationType === null || locationOrder === null) {
            return;
        }

        if (this.props.query !== locationQuery
            || this.props.type !== locationType
            || this.props.order !== locationOrder
        ) {
            this.props.performSearch(locationQuery, locationType, locationOrder);
        }
    };

    private handleImageGridLoadStart = () : void => {
        this.setState({imageGridLoading: true});
    };

    private handleImageGridLoadEnd = () : void => {
        this.setState({imageGridLoading: false});
    };

    private handleGuideSelect = (guide : string) : void => {
        this.props.addGuide(guide);
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    query: state.imageSearch.processedQuery,
    type: state.imageSearch.processedType,
    order: state.imageSearch.processedOrder,
    mode: state.imageSearch.mode,
    images: state.imageSearch.images,
    guides: state.imageSearch.guides,
    loading: state.imageSearch.loading,
    hasNextResults: state.imageSearch.nextResultsUrl !== null,
    search: state.router.location.search,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    unsetCurrentBoard: () => dispatch(unsetCurrentBoard()),
    loadNextResults: () => dispatch(loadNextResults()),
    addGuide: (guide : string) => dispatch(addGuide(guide)),
    performSearch: (
        query : string,
        type : ImageSearchType,
        order : ImageSearchOrder
    ) => dispatch(performSearch(query, type, order)),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        ImageSearch
    )
);
