import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {ChangeEvent, Component, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import {changeArchiveStatus} from '../../redux/boards/actions';
import {ThunkDispatch} from 'redux-thunk';
import {AppState} from '../../redux';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '../Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '../inputs/Checkbox';
import UnderlinedButton from '../UnderlinedButton';

const styles = (theme : Theme) : StyleRules => createStyles({
    dialogActionsDivider: {
        flexGrow: 1,
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
}

interface DispatchProps
{
    changeArchiveStatus : (boardId : string, archived : boolean, unshare? : boolean) => Promise<void>;
}

type Props = OwnProps & DispatchProps;

interface State
{
    showArchiveConfirmation : boolean;
    unshare : boolean;
}

class ArchiveBoard extends Component<Props, State>
{
    public readonly state = {
        showArchiveConfirmation: false,
        unshare: false,
    };

    public render() : ReactNode
    {
        const {classes, board} = this.props;
        const {showArchiveConfirmation, unshare} = this.state;

        const canArchive = board._user.permissions.archiveBoard;

        return (
            <React.Fragment>
                {canArchive && (
                    <UnderlinedButton onClick={this.handleArchiveClick}>
                        {board._user.archived ? 'Unarchive' : 'Archive'}
                    </UnderlinedButton>
                )}

                <Dialog
                    open={showArchiveConfirmation}
                    title="Confirm archiving"
                    onClose={this.handleArchiveConfirmationClose}
                >
                    <DialogContent>
                        <DialogContentText>
                            By archiving this board, it will no longer show up in your board lists. You can revert this
                            later by restoring it from the archive again.
                        </DialogContentText>
                        <DialogActions>
                            {board._user.role === 'owner' && board._members.length > 1 && (
                                <React.Fragment>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={unshare}
                                                onChange={this.handleUnshareChange}
                                            />
                                        }
                                        label="Unshare"
                                    />
                                    <div className={classes.dialogActionsDivider}/>
                                </React.Fragment>
                            )}
                            <Button onClick={this.handleArchiveConfirmationClose} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={this.handleArchiveClick} color="primary" autoFocus>
                                Confirm
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    private handleArchiveClick = async () : Promise<void> => {
        if (this.props.board._user.archived) {
            return this.props.changeArchiveStatus(this.props.board.id, false);
        }

        if (this.state.showArchiveConfirmation) {
            this.setState({showArchiveConfirmation: false});
            return this.props.changeArchiveStatus(this.props.board.id, true, this.state.unshare);
        }

        this.setState({
            showArchiveConfirmation: true,
            unshare: this.props.board._user.role === 'owner',
        });
    };

    private handleUnshareChange = (event : ChangeEvent<HTMLInputElement>, checked : boolean) : void => {
        this.setState({unshare: checked});
    };

    private handleArchiveConfirmationClose = () : void => {
        this.setState({showArchiveConfirmation: false});
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    changeArchiveStatus: (boardId : string, archived : boolean, unshare? : boolean) => dispatch(
        changeArchiveStatus(boardId, archived, unshare)
    ),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        ArchiveBoard
    )
);
