import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {Route, Switch} from 'react-router';
import {AnyAction} from 'redux';
import {ThunkDispatch} from 'redux-thunk';
import FullPageLoadingIndicator from '../components/FullPageLoadingIndicator';
import {AppState} from '../redux';
import {checkAuthentication} from '../redux/auth0/actions';
import {loadUser} from '../redux/user/actions';
import {User} from '../redux/user/types';
import BoardOverview from './Portal/BoardOverview';
import EditProfile from './Portal/EditProfile';
import ImageSearch from './Portal/ImageSearch';
import NotFound from './Portal/NotFound';
import Dashboard from './Portal/Dashboard/Dashboard';
import GlobalAppBar from '../components/GlobalAppBar';
import Home from './Portal/Home';

const styles = (theme : Theme) : StyleRules => createStyles({
    appBarSpacer: {
        marginLeft: 100,
    },
    appBar: {
        position: 'relative',
        zIndex: 1000,
        backgroundColor: 'white',
    },
    logo: {
        height: 60,
        '&:hover': {
            opacity: .5,
            transition: 'opacity .5s ease',
        },
        cursor: 'pointer',
    },
    searchBar: {
        marginLeft: 50,
        width: 750,
    },
    spacer: {
        flexGrow: 1,
    },
    content: {
        padding: theme.spacing(4, 8),
    },
    header: {
        paddingBottom: theme.spacing(4),
        marginBottom: theme.spacing(3),
        borderBottom: '1px solid #000',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
}

interface StateProps
{
    authenticated : boolean;
    user : User | null;
}

interface DispatchProps
{
    checkAuthentication : () => void;
    loadUser : () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class Portal extends Component<Props>
{
    public render() : ReactNode
    {
        const {classes, authenticated, user} = this.props;

        if (!authenticated || !user) {
            return <FullPageLoadingIndicator/>;
        }

        return (
            <React.Fragment>
                <GlobalAppBar user={user}/>

                <main className={classes.content}>
                    <Switch>
                        <Route path="/" exact component={Home}/>
                        <Route path="/dashboard" component={Dashboard}/>
                        <Route path="/search" exact component={ImageSearch}/>
                        <Route path="/edit-profile" exact component={EditProfile}/>
                        <Route path="/boards/:boardId" exact component={BoardOverview}/>
                        <Route path="*" component={NotFound}/>
                    </Switch>
                </main>
            </React.Fragment>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        const {authenticated, checkAuthentication, user} = this.props;

        if (authenticated) {
            if (!user) {
                this.props.loadUser();
            }

            return;
        }

        checkAuthentication();
    }

    public componentDidUpdate() : void
    {
        if (!this.props.user) {
            this.props.loadUser();
        }
    }
}

const mapStateToProps = (state : AppState) : StateProps => ({
    authenticated: state.auth0.authenticated,
    user: state.user.user,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    checkAuthentication: () => dispatch(checkAuthentication()),
    loadUser: () => dispatch(loadUser()),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        Portal
    )
);
