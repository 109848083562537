import {createStyles, Theme, WithStyles, Button} from '@material-ui/core';
import {StyleRules, withStyles} from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import InternalIcon from '@material-ui/icons/Security';
import classNames from 'classnames';
import React, {Component, DragEvent, ReactNode, SyntheticEvent} from 'react';
import LazyLoad from 'react-lazyload';
import ThreeDotLoadingIndicator from '../ThreeDotLoadingIndicator';
import {LayoutImage} from './utils/computeLayout';
import {AppState} from '../../redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {deselectImage, quickAddImage, selectImage} from '../../redux/image-selection/actions';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AddIcon from '@material-ui/icons/Add';
import Avatar from '@material-ui/core/Avatar';
import {thumbnailUri} from '../../utils/image';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        position: 'absolute',
        left: 0,
        top: 0,
        willChange: 'transform, width, height',
        transition: 'transform .5s ease',
        '&:hover:not($selected) $selectHint': {
            display: 'block',
        },
        '&:hover $internal': {
            display: 'block',
        },
        '&:hover $bottomButtonBar': {
            display: 'flex',
        },
        '&:hover $image': {
            opacity: .75,
        },
    },
    selected: {
        boxShadow: '0 0 0 4px #000, 5px 5px 10px 4px rgba(0, 0, 0, .5)',
        '& $image': {
            opacity: .5,
        },
    },
    selectHint: {
        display: 'none',
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        width: 80,
        height: 80,
        pointerEvents: 'none',
        color: '#fff',
        filter: 'drop-shadow(0 0 3px #000)',
        opacity: .5,
    },
    image: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
    },
    loadingContainer: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        backgroundColor: '#eee',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bottomButtonBar: {
        position: 'absolute',
        padding: '.5em',
        bottom: 5,
        left: '50%',
        flexDirection: 'row',
        transform: 'translate(-50%, 0%);',
        display: 'none',
        opacity: .80,
    },
    details: {
        display: 'block',
        backgroundColor: '#f8f8f8',
        border: 'solid 1px #000',
        borderRadius: 0,
        flexGrow: 1,

        '&:hover': {
            backgroundColor: '#ddd',
        },
        '&:not(:first-child)': {
            borderLeft: '0px',
        },
        '&:first-child': {
            marginLeft: '148px',
        },
    },
    quickAdd: {
        borderRadius: 0,
        border: 'solid 1px #000',
        borderColor: '#000000',
        backgroundColor: '#f8f8f8',
        width: '150px',
        '&:hover': {
            backgroundColor: '#ddd',
        },
        '&:not(:first-child)': {
            borderLeft: '0px',
        },
    },
    internal: {
        top: 0,
        left: 0,
        display: 'none',
        position: 'absolute',
        backgroundColor: '#f8f8f8',
        width: 48,
        height: 48,
        padding: 12,
        color: 'rgba(0, 0, 0, .54)',
        borderRadius: 0,
    },
    active: {
        '&:after': {
            position: 'absolute',
            width: '100%',
            content: '""',
            height: 1,
            bottom: 'calc((var(--margin) / -2) - 1px)',
            overflow: 'hidden',
            backgroundColor: '#000',
        },
        '&:hover $details': {
            display: 'block',
        },
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    layoutImage : LayoutImage;
    onImageClick? : (layoutImage : LayoutImage) => void;
    onDetailClick? : (layoutImage : LayoutImage) => void;
    isActive : boolean;
    allowSelection : boolean;
    allowDrag : boolean;
}

interface StateProps
{
    selected : boolean;
    anySelected : boolean;
}

interface DispatchProps
{
    selectImage : (imageId : string) => void;
    deselectImage : (imageId : string) => void;
    quickAddImage : (imageId : string) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State
{
    imageLoaded : boolean;
}

class ImageTile extends Component<Props, State>
{
    public readonly state = {
        imageLoaded: false,
    };
    private readonly image = new Image();

    public render() : ReactNode
    {
        const {
            classes,
            layoutImage,
            onImageClick,
            onDetailClick,
            allowSelection,
            allowDrag,
            selected,
            isActive,
            anySelected,
        } = this.props;
        const {imageLoaded} = this.state;

        const rootClasses = [classes.root];

        if (selected) {
            rootClasses.push(classes.selected);
        }

        if (isActive) {
            rootClasses.push(classes.active);
        }

        if (allowSelection && onImageClick) {
            throw new Error('It\'s not possible to set both onImageClick and allowSelection');
        }

        let imageClickHandler : (() => void) | undefined;

        if (onImageClick) {
            imageClickHandler = () => onImageClick(layoutImage);
        } else if (allowSelection) {
            imageClickHandler = () => this.handleSelect(!selected);
        }

        let imageDragHandler : ((event : DragEvent<HTMLDivElement>) => void) | undefined;

        if (allowDrag) {
            imageDragHandler = this.handleDrag;
        }

        return (
            <div className={classNames(rootClasses)} style={{
                width: layoutImage.width,
                height: layoutImage.height,
                transform: `translate3d(${layoutImage.x}px, ${layoutImage.y}px, 0)`,
            }}>
                <LazyLoad height={layoutImage.height} once resize>
                    <img
                        src={thumbnailUri(layoutImage.image.imageUri, 'h400')}
                        className={classes.image}
                        onClick={imageClickHandler}
                        onLoad={this.handleImageLoaded}
                        alt={layoutImage.image.imageKey}
                        draggable={allowDrag}
                        onDragStart={imageDragHandler}
                        style={{cursor: allowDrag ? 'move' : 'pointer'}}
                    />
                </LazyLoad>
                {!imageLoaded ? (
                    <div className={classes.loadingContainer}>
                        <ThreeDotLoadingIndicator/>
                    </div>
                ) : (
                    <React.Fragment>
                        { (allowSelection || onDetailClick) && (
                            <React.Fragment>
                                <div className={classes.bottomButtonBar}>
                                    {!anySelected && allowSelection && (
                                        <Button className={classes.quickAdd} onClick={this.handleQuickAdd}>
                                            Select Board <KeyboardArrowDown/>
                                        </Button>
                                    )}
                                    {onDetailClick && (
                                        <IconButton className={classes.details}
                                                    onClick={() => onDetailClick(layoutImage)}>
                                            <InfoIcon/>
                                        </IconButton>
                                    )}
                                </div>
                            </React.Fragment>
                        )}
                        {allowSelection && (
                            <AddIcon className={classes.selectHint}/>
                        )}
                        {layoutImage.image.internal && (
                            <Avatar className={classes.internal}>
                                <InternalIcon/>
                            </Avatar>
                        )}
                    </React.Fragment>
                )}
            </div>
        );
    }

    public componentWillUnmount() : void
    {
        if (this.props.allowSelection && this.props.selected) {
            this.props.deselectImage(this.props.layoutImage.image.id);
        }

        this.image.src = 'about:blank';
    }

    private handleImageLoaded = (e : SyntheticEvent<HTMLImageElement>) : void => {
        this.setState({
            imageLoaded: true,
        });
        this.image.src = e.currentTarget.src;
    };

    private handleSelect = (selected : boolean) : void => {
        if (selected) {
            this.props.selectImage(this.props.layoutImage.image.id);
        } else {
            this.props.deselectImage(this.props.layoutImage.image.id);
        }
    };

    private handleQuickAdd = () : void => {
        this.props.quickAddImage(this.props.layoutImage.image.id);
    };

    private handleDrag = (event : DragEvent<HTMLDivElement>) : void => {
        event.dataTransfer.setData('imageId', this.props.layoutImage.image.id);
        event.dataTransfer.setDragImage(this.image, 0, 0);
    };
}

const mapStateToProps = (state : AppState, props : OwnProps) : StateProps => ({
    selected: (state.imageSelection.selectedImageIds.indexOf(props.layoutImage.image.id) !== -1),
    anySelected: state.imageSelection.selectedImageIds.length > 0,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    selectImage: (imageId : string) => dispatch(selectImage(imageId)),
    deselectImage: (imageId : string) => dispatch(deselectImage(imageId)),
    quickAddImage: (imageId : string) => dispatch(quickAddImage(imageId)),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        ImageTile
    )
);
