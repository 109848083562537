import {withSnackbar, WithSnackbarProps} from 'notistack';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import {AppState} from '../redux';
import {removeSnackbar} from '../redux/notifier/actions';
import {Key, Notification} from '../redux/notifier/types';
import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import withStyles from '@material-ui/core/styles/withStyles';
import ThreeDotLoadingIndicator from './ThreeDotLoadingIndicator';

const styles = (theme : Theme) : StyleRules => createStyles({
    processing: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'fixed',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, .75)',
        zIndex: 10000,
    },
});

interface OwnProps extends WithStyles<typeof styles>, WithSnackbarProps
{
}

interface StateProps
{
    notifications : Notification[];
    processing : boolean;
}

interface DispatchProps
{
    removeSnackbar : (key : Key) => void;
}

type Props = OwnProps & StateProps & DispatchProps;

class Notifier extends Component<Props> {
    private displayed : Key[] = [];

    public render() : ReactNode
    {
        const {classes, processing} = this.props;

        if (!processing) {
            return null;
        }

        return (
            <div className={classes.processing}>
                <ThreeDotLoadingIndicator/>
            </div>
        );
    }

    public shouldComponentUpdate(nextProps : Readonly<Props>) : boolean
    {
        const processingChanged = nextProps.processing !== this.props.processing;
        const newSnacks = nextProps.notifications;

        if (newSnacks.length === 0) {
            this.displayed = [];
            return processingChanged;
        }

        const currentSnacks = this.props.notifications;
        let notExists = false;

        for (const newSnack of newSnacks) {
            if (newSnack.dismissed) {
                this.props.closeSnackbar(newSnack.key);
                this.props.removeSnackbar(newSnack.key);
            }

            if (notExists) {
                continue;
            }

            notExists = notExists || !currentSnacks.filter(({key}) => newSnack.key === key).length;
        }

        return notExists || processingChanged;
    }

    public componentDidUpdate() {
        const {notifications} = this.props;

        notifications.forEach(({key, message, options = {}}) => {
            if (this.displayed.includes(key)) {
                return;
            }

            this.props.enqueueSnackbar(message, {
                ...options,
                onClose: (event, reason) => {
                    if (options.onClose) {
                        options.onClose(event, reason);
                    }

                    this.props.removeSnackbar(key);
                },
            });

            this.storeDisplayed(key);
        });
    }

    private storeDisplayed = (key : Key) => {
        this.displayed = [...this.displayed, key];
    };
}

const mapStateToProps = (state : AppState) : StateProps => ({
    notifications: state.notifier.notifications,
    processing: state.notifier.processing,
});

const mapDispatchToProps : DispatchProps = {
    removeSnackbar,
};

export default withSnackbar(
    withStyles(styles)(
        connect<StateProps, DispatchProps, OwnProps, AppState>(
            mapStateToProps,
            mapDispatchToProps
        )(
            Notifier
        )
    )
);
