import {createStyles, Fab, InputAdornment, Theme, WithStyles, ButtonGroup} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import withStyles, {StyleRules} from '@material-ui/core/styles/withStyles';
import TextField from '@material-ui/core/TextField';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import RemoveIcon from '@material-ui/icons/Remove';
import {ValidationErrors} from 'final-form';
import React, {ChangeEvent, Component, ReactNode} from 'react';
import {Field, Form} from 'react-final-form';
import {connect} from 'react-redux';
import {AppState} from '../redux';
import {addImagesToBoard, createBoard, loadAccessibleBoards, removeImagesFromBoard} from '../redux/boards/actions';
import {Board} from '../redux/boards/types';
import Dialog from './Dialog';
import {FinalFormTextField} from './inputs/final-form-wrappers';
import ThreeDotLoadingIndicator from './ThreeDotLoadingIndicator';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {createSelector} from 'reselect';
import {keepEmpty, trim} from '../utils/form';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import {resetQuickAddImage} from '../redux/image-selection/actions';
import FlashingTooltip from './FlashingTooltip';

const CREATE = 'create';
const CREATE_VIEW = 'createAndView';

const styles = (theme : Theme) : StyleRules => createStyles({
    dialogContent: {
        minWidth: 400,
        paddingBottom: theme.spacing(2),
    },
    filter: {
        marginBottom: theme.spacing(1),
    },
    list: {
        height: 300,
        border: '1px solid black',
        overflow: 'auto',
        marginBottom: theme.spacing(2),
        '& .MuiListItem-root': {
            paddingRight: 90,
        },
        '& .MuiListItem-container:nth-child(even)': {
            backgroundColor: '#f9f9f9',
        },
        '& .MuiListItemText-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        '& .MuiListItemSecondaryAction-root': {
            display: 'none',
        },
        '& .MuiListItem-container:hover .MuiListItemSecondaryAction-root': {
            display: 'block',
        },
    },
    loading: {
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    create: {
        marginTop: theme.spacing(2),
    },
    removeFromBoardFloating: {
        borderRadius: '24px',
        transition: 'width 0.5s',
        overflow: 'hidden',
        '&:hover': {
            width: '200px',
        },
        '&:hover $hoverButtonText': {
            display: 'inline-block',
        },
    },
    addToBoardFloating: {
        borderRadius: '24px',
        transition: 'width 0.5s',
        overflow: 'hidden',
        '&:hover': {
            width: '150px',
        },
        '&:hover $hoverButtonText': {
            display: 'inline-block',
        },
    },
    hoverButtonText: {
        display: 'none',
    },
    addViewBtn: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    newCreateViewBtn: {
        marginLeft: '2px',
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    currentBoard? : Board;
}

interface StateProps
{
    selectedImageIds : string[];
    quickAddImageId : string | null;
    boards : Board[] | null;
}

interface DispatchProps
{
    createBoard : (name : string, imageIds : string[], isQuickAdd : boolean) => Promise<void>;
    addImagesToBoard : (board : Board, imageIds : string[], isQuickAdd : boolean) => Promise<void>;
    removeImagesFromBoard : (boardId : string, imageIds : string[]) => void;
    loadAccessibleBoards : () => void;
    resetQuickAddImage : () => void;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State
{
    dialogOpen : boolean;
    showBoardCreate : boolean;
    filter : string;
    processing : boolean;
    tooltipVisible : boolean;
}

const validate = (values : any) => {
    const errors : ValidationErrors = {};

    if (!values.name || !values.name.trim()) {
        errors.name = 'Required';
    }

    return errors;
};

class AddToBoard extends Component<Props, State>
{
    public readonly state = {
        selectedImageIds: [] as string[],
        dialogOpen: false,
        showBoardCreate: false,
        filter: '',
        processing: false,
        tooltipVisible: false,
    };
    private skipDialogClose = false;

    public render() : ReactNode
    {
        const {classes, boards, currentBoard, selectedImageIds} = this.props;
        const {dialogOpen, showBoardCreate, filter, processing, tooltipVisible} = this.state;

        let filteredBoards = boards;

        if (filteredBoards !== null && currentBoard) {
            filteredBoards = filteredBoards.filter(board => board.id !== currentBoard.id);
        }

        if (filteredBoards !== null && filter) {
            const normalizedFilter = filter.toLocaleLowerCase();
            filteredBoards = filteredBoards.filter(
                board => board.name.toLocaleLowerCase().indexOf(normalizedFilter) !== -1
            );
        }

        const dialogTitle = 'Add Images to Board';

        let dialogContent : ReactNode;

        if (processing) {
            dialogContent = (
                <div className={classes.loading}><ThreeDotLoadingIndicator/></div>
            );
        } else {
            dialogContent = (
                <React.Fragment>
                    <TextField
                        className={classes.filter}
                        fullWidth
                        variant="outlined"
                        placeholder="Filter boards"
                        onChange={this.handleFilterChange}
                        value={filter}
                        InputProps={{
                            endAdornment: filter ? (
                                <InputAdornment position="end">
                                    <IconButton edge="end" onClick={this.handleFilterClear}>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ) : undefined,
                        }}
                    />

                    <List className={classes.list}>
                        {filteredBoards === null ? (
                            <div className={classes.loading}><ThreeDotLoadingIndicator/></div>
                        ) : (
                            filteredBoards.length === 0 ? (
                                <ListItem>
                                    <ListItemText>No boards found</ListItemText>
                                </ListItem>
                            ) : (
                                filteredBoards.slice()
                                    .sort((a, b) => a.name.localeCompare(b.name))
                                    .map(board => (
                                        <ListItem
                                            button
                                            key={board.id}
                                            onClick={this.createBoardClickHandler(board, true)}
                                        >
                                            <ListItemText>{board.name}</ListItemText>
                                            <ListItemSecondaryAction>
                                                <ButtonGroup variant="text" aria-label="text button group">
                                                    <Button
                                                        variant="text"
                                                        onClick={this.createBoardClickHandler(board, true)}
                                                    >
                                                        Add
                                                    </Button>
                                                    <Button
                                                        variant="text"
                                                        className={classes.addViewBtn}
                                                        onClick={this.createBoardClickHandler(board, false)}
                                                    >
                                                        Add and<br/>View Board
                                                    </Button>
                                                </ButtonGroup>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                            )
                        )}
                    </List>

                    {!showBoardCreate && (
                        <Button variant="contained" onClick={this.showBoardCreate}>
                            New Board
                        </Button>
                    )}

                    {showBoardCreate && (
                        <Form
                            onSubmit={this.handleBoardCreate}
                            initialValues={{name: ''}}
                            validate={validate}
                            render={({handleSubmit, submitting, pristine, invalid, form}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <Field
                                            autoFocus
                                            name="name"
                                            label="Name"
                                            required
                                            component={FinalFormTextField}
                                            format={trim}
                                            formatOnBlur
                                            parse={keepEmpty}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={pristine || invalid || submitting}
                                                            onClick={() => {
                                                                form.change('button', CREATE);
                                                            }}
                                                        >
                                                            Create
                                                        </Button>
                                                        <Button
                                                            className={classes.newCreateViewBtn}
                                                            variant="contained"
                                                            type="submit"
                                                            disabled={pristine || invalid || submitting}
                                                            onClick={() => {
                                                                form.change('button', CREATE_VIEW);
                                                            }}
                                                        >
                                                            Create&nbsp;&amp;&nbsp;View<br/>Board
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                    </form>
                                );
                            }}
                        />
                    )}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                {selectedImageIds.length > 0 && (
                    <React.Fragment>
                        {currentBoard && currentBoard._user.permissions.removeImages ? (
                            <React.Fragment>
                                <Fab size="medium"
                                     onClick={this.removeFromBoard}
                                     className={[classes.removeFromBoardFloating, 'shine'].join(' ')}>
                                    <RemoveIcon/>
                                    <span className={classes.hoverButtonText}>Remove&nbsp;From&nbsp;Board</span>
                                </Fab>
                                <Fab size="medium" onClick={this.openDialog} className={[classes.addToBoardFloating, 'shine'].join(' ')}>
                                    <AddIcon/>
                                    <span className={classes.hoverButtonText}>Select&nbsp;Board</span>
                                </Fab>
                            </React.Fragment>
                        ) : (
                            <FlashingTooltip title="Add To Board" open={tooltipVisible}>
                                <Fab size="medium" onClick={this.openDialog} className={classes.addToBoardFloating}>
                                    <AddIcon/>
                                    <span className={classes.hoverButtonText}>Select&nbsp;Board</span>
                                </Fab>
                            </FlashingTooltip>
                        )}
                    </React.Fragment>
                )}

                <Dialog onClose={this.closeDialog} open={dialogOpen} title={dialogTitle}>
                    <DialogContent className={classes.dialogContent}>
                        {dialogContent}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        );
    }

    public componentWillUnmount() : void
    {
        this.skipDialogClose = true;
    }

    public componentDidUpdate(prevProps : Readonly<Props>) : void
    {
        if (prevProps.selectedImageIds.length === 0 && this.props.selectedImageIds.length === 1) {
            this.setState({tooltipVisible : true});
        }

        if (!prevProps.quickAddImageId && this.props.quickAddImageId) {
            this.openDialog();
        }
    }

    private openDialog = () : void => {
        this.props.loadAccessibleBoards();
        this.setState({dialogOpen: true, showBoardCreate: false, filter: '', processing: false});
    };

    private closeDialog = () : void => {
        this.setState({dialogOpen: false});
        this.props.resetQuickAddImage();
    };

    private showBoardCreate = () : void => {
        this.setState({showBoardCreate: true});
    };

    private handleFilterChange = (event : ChangeEvent<HTMLInputElement>) : void => {
        this.setState({filter: event.target.value});
    };

    private handleFilterClear = () : void => {
        this.setState({filter: ''});
    };

    private handleBoardCreate = async (values : any) : Promise<void> => {
        const {selectedImageIds, quickAddImageId} = this.props;
        const imageIds = quickAddImageId ? [quickAddImageId] : selectedImageIds;
        const stayOnCurrentPage = values.button === CREATE;
        this.setState({processing: true});
        await this.props.createBoard(values.name, imageIds, stayOnCurrentPage);

        if (!this.skipDialogClose) {
            this.setState({dialogOpen: false});
        }
    };

    private createBoardClickHandler = (board : Board, stayOnCurrentPage : boolean) => async () : Promise<void> => {
        const {selectedImageIds, quickAddImageId} = this.props;
        const imageIds = quickAddImageId ? [quickAddImageId] : selectedImageIds;

        this.setState({processing: true});
        await this.props.addImagesToBoard(board, imageIds, stayOnCurrentPage);

        if (!this.skipDialogClose) {
            this.setState({dialogOpen: false});
        }
    };

    private removeFromBoard = () : void => {
        const {currentBoard} = this.props;

        if (!currentBoard) {
            return;
        }

        this.props.removeImagesFromBoard(currentBoard.id, this.props.selectedImageIds);
    };
}

const getBoardsWithAddPermission = createSelector<AppState, Board[] | null, Board[] | null>(
    state => state.boards.accessibleBoards,
    boards => boards === null ? null : boards.filter(board => board._user.permissions.addImages)
);

const mapStateToProps = (state : AppState) : StateProps => ({
    boards: getBoardsWithAddPermission(state),
    selectedImageIds: state.imageSelection.selectedImageIds,
    quickAddImageId : state.imageSelection.quickAddImageId,
});

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    createBoard: (name : string, imageIds : string[], isQuickAdd : boolean) => dispatch(
        createBoard(name, imageIds, isQuickAdd)
    ),
    addImagesToBoard: (board : Board, imageIds : string[], isQuickAdd : boolean) => dispatch(
        addImagesToBoard(board, imageIds, isQuickAdd)
    ),
    removeImagesFromBoard: (boardId : string, imageIds : string[]) => dispatch(
        removeImagesFromBoard(boardId, imageIds)
    ),
    loadAccessibleBoards: () => dispatch(loadAccessibleBoards()),
    resetQuickAddImage: () => dispatch(resetQuickAddImage()),
});

export default withStyles(styles)(
    connect<StateProps, DispatchProps, OwnProps, AppState>(
        mapStateToProps,
        mapDispatchToProps
    )(
        AddToBoard
    )
);
