import {createStyles, Theme, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import React, {Component, ReactNode} from 'react';
import {Board} from '../../redux/boards/types';
import withStyles from '@material-ui/core/styles/withStyles';
import {apiEndpoint, apiFetch} from '../../utils/api';
import {Image} from '../../redux/image-search/types';
import BoardTileThumbnail, {maxBoardTileImages} from './BoardTileThumbnail';
import {AppState} from '../../redux';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {connect} from 'react-redux';
import {push as pushLocation} from 'connected-react-router';
import Typography from '@material-ui/core/Typography';
import BoardMemberList from '../avatar/BoardMemberList';
import {setCurrentBoard} from '../../redux/boards/actions';

const styles = (theme : Theme) : StyleRules => createStyles({
    root : {
        position: 'relative',
        width: 250,
        marginLeft: 30,
        marginTop: 30,
    },
    thumbnail: {
        display: 'block',
        cursor: 'pointer',
        marginBottom: theme.spacing(1),
    },
    info: {
        display: 'flex',
        alignItems: 'center',
    },
    name: {
        flexGrow: 1,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
    },
    memberList: {
        marginLeft: theme.spacing(1),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    board : Board;
    renderAdditionalInfo? : (board : Board) => void;
}

interface DispatchProps
{
    setCurrentBoard : (board : Board) => void;
    pushLocation : (path : string) => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    recentImages : Image[];
}

class BoardTile extends Component<Props, State>
{
    public readonly state : State = {
        recentImages: [],
    };

    public render() : ReactNode
    {
        const {classes, board, renderAdditionalInfo} = this.props;
        const {recentImages} = this.state;

        return (
            <div className={classes.root}>
                <BoardTileThumbnail
                    className={classes.thumbnail}
                    images={recentImages}
                    width={250} height={150}
                    onClick={this.handleBoardClick}
                />
                <div className={classes.info}>
                    <Typography
                        className={classes.name}
                        variant="body1"
                        onClick={this.handleBoardClick}
                    >{board.name}</Typography>
                    {board.type === 'regular' && (
                        <BoardMemberList className={classes.memberList} members={board._members} maximum={3}/>
                    )}
                </div>
                {renderAdditionalInfo && renderAdditionalInfo(board)}
            </div>
        );
    }

    public async componentDidMount() : Promise<void>
    {
        const {board} = this.props;

        if (board._recentImages) {
            this.setState({recentImages: board._recentImages});
            return;
        }

        const imagesUrl = new URL(`${apiEndpoint}/boards/${board.id}/images`);
        imagesUrl.searchParams.set('limit', maxBoardTileImages.toString());

        const imagesResult = await apiFetch(imagesUrl.href);

        if (!imagesResult.ok) {
            return;
        }

        const images = (await imagesResult.json()).items;
        this.setState({recentImages : images});
    }

    private handleBoardClick = () : void => {
        this.props.setCurrentBoard(this.props.board);
        this.props.pushLocation(`/boards/${this.props.board.id}`);
    };
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    setCurrentBoard: (board : Board) => dispatch(setCurrentBoard(board)),
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        BoardTile
    )
);
