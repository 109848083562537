import React, {Component, ReactNode} from 'react';
import FullPageLoadingIndicator from '../components/FullPageLoadingIndicator';
import {getAuth0Client} from '../utils/auth0';
import {absoluteUrl} from '../utils/uri';

class SignOut extends Component
{
    public render() : ReactNode
    {
        return <FullPageLoadingIndicator/>;
    }

    public async componentDidMount() : Promise<void>
    {
        const auth0Client = await getAuth0Client();
        auth0Client.logout({
            returnTo: absoluteUrl('/sign-out/callback').href,
        });
    }
}

export default SignOut;
