import {
    ADD_MEMBER,
    BoardsActionTypes,
    BoardsState,
    CHANGE_IMAGE_POSITION,
    FETCH_IMAGES_BEGIN,
    FETCH_IMAGES_END,
    REMOVE_CONSULTANT_REQUEST,
    REMOVE_IMAGES,
    REMOVE_MEMBER,
    REQUEST_CONSULTANT,
    SET_ACCESSIBLE_BOARDS,
    SET_CURRENT_BOARD,
    SET_SPEC_OPTIONS,
    SWITCH_BOARD_TYPE,
    SWITCH_READY_FOR_SPECS,
    UNSET_ACCESSIBLE_BOARDS,
    UNSET_CURRENT_BOARD,
    UPDATE_BOARD_ARCHIVE_STATUS,
    UPDATE_BOARD_DESCRIPTION,
    UPDATE_BOARD_NAME,
    UPDATE_MEMBER,
    UPDATE_SPECS,
} from './types';

const initialState : BoardsState = {
    currentBoard: {
        board: null,
        boardImages: [],
        loading: true,
    },
    accessibleBoards: null,
    specOptions: null,
};

export const boardsReducer = (
    state = initialState,
    action : BoardsActionTypes
) : BoardsState => {
    switch (action.type) {
        case SET_CURRENT_BOARD:
            return {
                ...state,
                currentBoard: {
                    board: action.payload.board,
                    boardImages: [],
                    loading: true,
                },
            };

        case UNSET_CURRENT_BOARD:
            return {
                ...state,
                currentBoard: initialState.currentBoard,
            };

        case SET_ACCESSIBLE_BOARDS:
            return {
                ...state,
                accessibleBoards: action.payload.boards,
            };

        case UNSET_ACCESSIBLE_BOARDS:
            return {
                ...state,
                accessibleBoards: null,
            };

        case FETCH_IMAGES_BEGIN:
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    loading: true,
                },
            };

        case FETCH_IMAGES_END:
            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    boardImages: state.currentBoard.boardImages.concat(action.payload.boardImages),
                    loading: false,
                },
            };

        case REMOVE_IMAGES:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    boardImages: state.currentBoard.boardImages.filter(
                        boardImage => action.payload.imageIds.indexOf(boardImage.image.id) === -1
                    ),
                },
            };

        case UPDATE_BOARD_NAME:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        name: action.payload.name,
                    },
                },
            };

        case UPDATE_BOARD_DESCRIPTION:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        description: action.payload.description,
                    },
                },
            };

        case UPDATE_BOARD_ARCHIVE_STATUS:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        _user: {
                            ...state.currentBoard.board._user,
                            archived: action.payload.archived,
                        },
                        _members: action.payload.unshare
                            ? state.currentBoard.board._members.filter(member => member.role === 'owner')
                            : state.currentBoard.board._members,
                    },
                },
            };

        case SWITCH_BOARD_TYPE:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        type: action.payload.type,
                        _members: action.payload.type === 'social'
                            ? state.currentBoard.board._members.filter(member => member.role === 'owner')
                            : state.currentBoard.board._members,
                    },
                },
            };

        case ADD_MEMBER:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        _members: state.currentBoard.board._members.concat([action.payload.member]),
                    },
                },
            };

        case UPDATE_MEMBER:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        _members: state.currentBoard.board._members.map(member => {
                            if (member.user.id === action.payload.userId) {
                                member.role = action.payload.role;
                            }

                            return member;
                        }),
                    },
                },
            };

        case REMOVE_MEMBER:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        _members: state.currentBoard.board._members.filter(
                            member => member.user.id !== action.payload.userId
                        ),
                    },
                },
            };

        case REQUEST_CONSULTANT:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        consultantRequested: true,
                    },
                },
            };

        case REMOVE_CONSULTANT_REQUEST:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        consultantRequested: false,
                    },
                },
            };

        case CHANGE_IMAGE_POSITION: {
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            const imageIndex = state.currentBoard.boardImages.findIndex(
                boardImage => boardImage.image.id === action.payload.imageId
            );
            let targetIndex = state.currentBoard.boardImages.findIndex(
                boardImage => boardImage.image.id === action.payload.targetId
            );

            if (imageIndex === targetIndex) {
                return state;
            }

            if (imageIndex < 0 || targetIndex < 0) {
                return state;
            }

            if (targetIndex > imageIndex && action.payload.position === 'before') {
                targetIndex -= 1;
            } else if (targetIndex < imageIndex && action.payload.position === 'after') {
                targetIndex += 1;
            }

            const boardImages = state.currentBoard.boardImages.slice();
            boardImages.splice(targetIndex, 0, boardImages.splice(imageIndex, 1)[0]);

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    boardImages,
                },
            };
        }

        case SWITCH_READY_FOR_SPECS:
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        readyForSpecs: action.payload.readyForSpecs,
                    },
                },
            };

        case SET_SPEC_OPTIONS:
            return {
                ...state,
                specOptions: action.payload.specOptions,
            };

        case UPDATE_SPECS: {
            if (state.currentBoard.board === null || action.payload.boardId !== state.currentBoard.board.id) {
                return state;
            }

            const imageIndex = state.currentBoard.boardImages.findIndex(
                boardImage => boardImage.image.id === action.payload.imageId
            );

            if (imageIndex < 0) {
                return state;
            }

            const boardImages = [...state.currentBoard.boardImages];
            boardImages[imageIndex] = {...boardImages[imageIndex], specs: action.payload.specs};

            return {
                ...state,
                currentBoard: {
                    ...state.currentBoard,
                    board: {
                        ...state.currentBoard.board,
                        specsComplete: boardImages.filter(boardImage => !boardImage.specs.complete).length === 0,
                    },
                    boardImages,
                },
            };
        }

        default:
            return state;
    }
};
