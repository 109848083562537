import {createStyles, Grid, Theme, Typography, withStyles, WithStyles} from '@material-ui/core';
import {StyleRules} from '@material-ui/core/styles';
import classNames from 'classnames';
import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import {AppState} from '../../redux';
import {performSearch} from '../../redux/image-search/actions';
import {Image} from '../../redux/image-search/types';
import ImageGrid from './ImageGrid';
import {LayoutImage} from './utils/computeLayout';
import {ThunkDispatch} from 'redux-thunk';
import {AnyAction} from 'redux';
import {apiEndpoint, apiFetch} from '../../utils/api';
import {Board} from '../../redux/boards/types';
import ThreeDotLoadingIndicator from '../ThreeDotLoadingIndicator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {push as pushLocation} from 'connected-react-router';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import BoardMemberList from '../avatar/BoardMemberList';
import ClearIcon from '@material-ui/icons/Clear';
import IconButton from '@material-ui/core/IconButton';
import {thumbnailUri} from '../../utils/image';

const styles = (theme : Theme) : StyleRules => createStyles({
    root: {
        position: 'absolute',
        opacity: 0,
        visibility: 'hidden',
        backgroundColor: '#f8f8f8',
        willChange: 'width',
        transition: 'opacity .5s ease, width .5s ease',
        paddingBottom: 20,
        userSelect: 'text',
    },
    rootVisible: {
        opacity: 1,
        visibility: 'visible',
    },
    imageContainer: {
        position: 'relative',
        overflow: 'hidden',
        lineHeight: 0,
    },
    thumbnail: {
        position: 'relative',
        width: '100%',
        filter: 'blur(5px)',
    },
    thumbnailLoaded: {
        filter: 'none',
    },
    fullSize: {
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        opacity: 0,
        transition: 'opacity .15s linear',
    },
    fullSizeLoaded: {
        opacity: 1,
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        padding: '20px 20px 0 20px',
    },
    tags: {
        marginTop: theme.spacing(2),
    },
    tag: {
        display: 'inline-block',
        marginRight: 20,
        textTransform: 'uppercase',
        cursor: 'pointer',
    },
    boards: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        minHeight: 150,
        padding: theme.spacing(2),
        backgroundColor: '#fff',
        '& .MuiListItem-root': {
            paddingRight: (28 * 4) + 8,
        },
    },
    moreLikeThis: {
        marginTop: 20,
        padding: '0 20px',
    },
    hidden: {
        marginTop: 0,
        height: 0,
        overflow: 'hidden',
    },
    close: {
        backgroundColor: '#f8f8f8',
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        '&:hover': {
            backgroundColor: '#ddd',
        },
    },
    detailsHeading: {
        paddingRight: theme.spacing(4),
    },
});

interface OwnProps extends WithStyles<typeof styles>
{
    visible : boolean;
    width : number;
    image : Image;
    yOffset : number;
    handleHeightChange : (height : number) => void;
    onClose : () => void;
}

interface DispatchProps
{
    performSearch : (query : string) => void;
    pushLocation : (path : string) => void;
}

type Props = OwnProps & DispatchProps;

interface State
{
    fullSizeLoaded : boolean;
    imageGridLoaded : boolean;
    relatedImages? : Image[];
    associatedBoards? : Board[];
}

class ImageDetail extends Component<Props, State>
{
    public readonly state : State = {
        fullSizeLoaded: false,
        imageGridLoaded: false,
    };
    private readonly container = React.createRef<HTMLDivElement>();
    private lastHeight? : number;

    public render() : ReactNode
    {
        const {classes, width, image, visible, yOffset, onClose} = this.props;
        const {fullSizeLoaded, imageGridLoaded, relatedImages, associatedBoards} = this.state;

        const rootClassNames = [classes.root];
        const thumbnailClassNames = [classes.thumbnail];
        const fullSizeClassNames = [classes.fullSize];

        if (visible) {
            rootClassNames.push(classes.rootVisible);
        }

        if (fullSizeLoaded) {
            thumbnailClassNames.push(classes.thumbnailLoaded);
            fullSizeClassNames.push(classes.fullSizeLoaded);
        }

        const collapseView = image.metadata.aspectRatio >= 2;

        const moreLikeThisClasses = [classes.moreLikeThis];

        if (!imageGridLoaded) {
            moreLikeThisClasses.push(classes.hidden);
        }

        return (
            <ReactResizeDetector
                handleHeight
                refreshMode="debounce"
                refreshRate={400}
                onResize={this.handleResize}
            >
                <div className={classNames(...rootClassNames)} ref={this.container} style={{
                    'width': `${width}px`,
                    'transform': `translate3d(0, ${yOffset}px, 0)`,
                }}>
                    <Grid container>
                        <Grid item lg={collapseView ? 12 : 4} md={collapseView ? 12 : 6} sm={12}>
                            <div className={classes.imageContainer}>
                                <img
                                    src={thumbnailUri(image.imageUri, 'h400')}
                                    alt={image.imageKey}
                                    className={classNames(...thumbnailClassNames)}
                                />
                                <img
                                    src={image.imageUri}
                                    alt={image.imageKey}
                                    className={classNames(...fullSizeClassNames)}
                                    onLoad={this.handleFullSizeLoaded}
                                />
                            </div>
                            <IconButton size="small" className={classes.close} onClick={onClose}>
                                <ClearIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item md={true} sm={12} className={classes.details}>
                            <Typography variant="h2" className={classes.detailsHeading}>
                                {image.imageKey}
                                {image.internal ? ' (internal)' : ''}
                            </Typography>

                            <div className={classes.tags}>
                                {image.tags.map(tag => (
                                    <Typography
                                        key={tag}
                                        variant="body1"
                                        className={classes.tag}
                                        onClick={this.createTagClickHandler(tag)}
                                    >{tag}</Typography>
                                ))}
                            </div>

                            <div className={classes.boards}>
                                <Typography variant="h3">Boards</Typography>

                                {!associatedBoards ? (
                                    <ThreeDotLoadingIndicator/>
                                ) : (associatedBoards.length === 0 ? (
                                    <Typography variant="body1">Not assigned to any boards.</Typography>
                                ) : (
                                    <List>
                                        {associatedBoards.map(board => (
                                            <ListItem
                                                key={board.id}
                                                button
                                                onClick={this.createBoardClickHandler(board.id)}
                                            >
                                                <ListItemText>{board.name}</ListItemText>
                                                <ListItemSecondaryAction>
                                                    <BoardMemberList members={board._members} maximum={3}/>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        ))}
                                    </List>
                                ))}
                            </div>
                        </Grid>
                        {relatedImages && relatedImages.length > 0 && (
                            <Grid item sm={12} className={classNames(moreLikeThisClasses)}>
                                <Typography variant="h4" gutterBottom>More like this</Typography>

                                <ImageGrid
                                    images={relatedImages}
                                    maxRowHeight={125}
                                    margin={20}
                                    includeLastPartialRow={true}
                                    stopAfterFirstRow={true}
                                    onImageClick={this.handleRelatedImageClick}
                                    onLoadEnd={this.handleImageGridLoadEnd}
                                    allowSelection={false}
                                    allowDetails={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                </div>
            </ReactResizeDetector>
        );
    }

    public componentDidMount() : void
    {
        this.fetchDetails(this.props.image);
        this.setState({
            fullSizeLoaded: false,
        });
    }

    public componentDidUpdate(prevProps : Readonly<Props>) : void
    {
        if (prevProps.image !== this.props.image) {
            this.fetchDetails(this.props.image);
            this.setState({
                fullSizeLoaded: false,
                imageGridLoaded: false,
            });
            return;
        }
    }

    private handleImageGridLoadEnd = () : void => {
        this.setState({
            imageGridLoaded: true,
        });
        this.handleResize();
    };

    private handleResize = () : void => {
        if (this.container.current && this.container.current.offsetHeight !== this.lastHeight) {
            this.props.handleHeightChange(this.lastHeight = this.container.current.offsetHeight);
        }
    };

    private handleFullSizeLoaded = () : void => {
        this.setState({
            fullSizeLoaded: true,
        });
    };

    private handleRelatedImageClick = (layoutImage : LayoutImage) : void => {
        this.props.performSearch(layoutImage.image.imageKey);
    };

    private createTagClickHandler = (tag : string) => () : void => {
        this.props.performSearch(tag);
    };

    private createBoardClickHandler = (boardId : string) => () : void => {
        this.props.pushLocation(`/boards/${boardId}`);
    };

    private fetchDetails(image : Image) : void
    {
        this.setState({
            relatedImages: undefined,
            associatedBoards: undefined,
        });

        const promises = [];

        const associatedBoardsUrl = new URL(`${apiEndpoint}/boards`);
        associatedBoardsUrl.searchParams.set('associatedImageId', image.id);
        associatedBoardsUrl.searchParams.set('archived', 'false');
        promises.push(apiFetch(associatedBoardsUrl.href));

        if (image.tags.length > 0) {
            const randomTag = image.tags[Math.floor(Math.random() * image.tags.length)];
            const relatedImagesUrl = new URL(`${apiEndpoint}/images`);
            relatedImagesUrl.searchParams.set('query', randomTag);
            relatedImagesUrl.searchParams.set('excludeImageId', image.id);
            relatedImagesUrl.searchParams.set('limit', '50');

            promises.push(apiFetch(relatedImagesUrl.href));
        } else {
            promises.push(new Response('[]', {status: 200}));
        }

        const newState = {
            relatedImages: [],
            associatedBoards: [],
        };

        Promise.all(promises).then(async results => {
            if (results[0].ok) {
                newState.associatedBoards = (await results[0].json()).items;
            }

            if (results[1].ok) {
                newState.relatedImages = (await results[1].json()).items;
            }

            this.setState(newState);
        }).catch(() => this.setState(newState));
    }
}

const mapDispatchToProps = (dispatch : ThunkDispatch<AppState, any, AnyAction>) : DispatchProps => ({
    performSearch: (query : string) => dispatch(performSearch(query)),
    pushLocation: (path : string) => dispatch(pushLocation(path)),
});

export default withStyles(styles)(
    connect<null, DispatchProps, OwnProps, AppState>(
        null,
        mapDispatchToProps
    )(
        ImageDetail
    )
);
